.empty-decks-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 20%;
}

.empty-decks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 20rem;
    text-align: center;
}

.empty-decks>* {
    margin-bottom: 1rem;
}

.empty-decks>img {
    max-width: 10rem;
}


.deck-row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background: #FFFFFF;
    border: 1px solid #F0F0F5;
    box-sizing: border-box;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 1.5rem;
    margin-bottom: 1rem;
}

.archive-deck-row-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background: #FFFFFF;
    border: 1px solid #F0F0F5;
    box-sizing: border-box;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.deck-row-wrapper:hover {
    background: #FAFAFD;
    cursor: pointer;
}

.deck-row-thumbnail-spacer {
    width: 14rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.deck-row-thumbnail-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #FFFFFF;
    box-sizing: border-box;

}

.deck-row-thumbnail-wrapper>img {
    /* max-width: 14rem; */
    max-height: 7.5rem;
    /* display: block; */
    /* width: auto; */
    /* height: auto; */
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    border: 1px solid #F0F0F5;
}

.deck-row-infos {
    flex-grow: 1;
    margin-left: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.deck-row-infos>h4 {
    margin-bottom: 0.5rem;
}

.deck-label-pro {
    background: #93BCD2;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FFFFFF;
    box-sizing: border-box;
    width: 3.5rem;
    height: 2.1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.deck-label-free {
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    border: 1px solid #8B8B9C;
    box-sizing: border-box;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #8B8B9C;
    width: 3.5rem;
    height: 2.1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.deck-row-contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
}

.deck-row-contacts>img {
    margin-right: 0.5rem;
}


/* Modal styles */

.add-deck-popup-wrapper {
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(17, 15, 53, 0.324);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.add-deck-popup-container {
    width: 809px;
    height: 760px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    opacity: 1;
    z-index: 2;
    padding-left: 2rem;
    padding-right: 2rem;
}

.add-deck-popup-scrollable-form-wrapper {
    overflow: scroll;
    height: 555px;
}

.add-deck-form-label {
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    text-transform: uppercase;
}

.form-content-info {
    display: flex;
    flex-direction: column;
}

.form-content-info>label {
    margin-bottom: 0.5rem;
}

.form-content-info>input[type=text] {
    width: 21.65rem;
    margin-bottom: 1.2rem;
}

.type-choices-container {
    display: flex;
    flex-direction: row;
}

.type-choice-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #DADAE3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1rem;
    padding-right: 2rem;
}

.type-choice-wrapper:first-child { 
    margin-right: 1.5rem;
  }

.type-choice-wrapper>hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #F0F0F5;
}

.feature-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0rem;
    align-items: flex-start;
}

.feature-icon {
    margin-right: 0.6rem;
    margin-top: 0.15rem;
}

.type-choices-container > label:focus-within {
    border: 1px solid #EF5B09;
}

/* Change Status Modal */

.archive-popup-container {
    width: 600px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    opacity: 1;
    z-index: 2;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1.25rem;
}

.popup-notification-wrapper {
    background: #F0F0F5;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .popup-notification-wrapper > img {
    margin-top: 0.3rem;  
  }
  
  .popup-notification-text {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #212173;
    margin: 0rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .popup-reactivate-credits-display{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #DADAE3;
    border-radius: 4px;
    width: 20rem;
    padding: 0.75rem;
  }

  .credits-display-unit{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .credits-display-unit > * {
      margin-right: 0.4rem;
  }

  .popup-reactivate-credits-pro-features{
    margin-top: 0.5rem;
    background: #FFFFFF;
    border: 1px solid #DADAE3;
    border-radius: 4px;
    padding: 0.75rem;
  }

  .reactivate-headline{
      margin-left: 1rem;
  }

  .add-deck-spinner {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}