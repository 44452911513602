* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

body {
  font-family: sofia-pro, sans-serif;
  color: #212173;
  font-size: 16px;
  line-height: 20px;
  background-color: #FCFCFC;
}

a {
  text-decoration: none;
}

/* Styles for main layout */

.main-wrapper {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.workspace-spacer {
  margin-left: 15rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  padding-left: 2rem;
  padding-right: 2rem;
}

.workspace-wrapper {
  max-width: 900px;
  min-height: 100vh;
  flex-grow: 1;
}

.workspace-title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: #F0F0F5 1px solid;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.workspace-main-content {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Styles for the login and signup screens */

.window-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.window {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  width: 400px;
  background: #FFFFFF;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.window>* {
  margin-bottom: 2rem;
}

/* COLOURS

Primary colours
#EF5B09
#212173
#93BCD2

Grays
#110F35
#8B8B9C
#D2D2DA
#DCDCEA

Status 
#51D37E
#FAB14E
#FC4343
#8ae2f0

*/

/* Combo classes
These can be combined to quickly color a text */

.grey {
  color: #8B8B9C;
}

.orange {
  color: #EF5B09;
}

.white {
  color: white;
}

.success-color {
  color: #51D37E;
}

.s {
  font-style: normal;
  font-weight: normal;
  font-size: 15px !important;
  line-height: 18px;
  letter-spacing: -0.2px;
}

.no-margins {
  margin: 0px;
}

._600 {
  font-weight: 600 !important;
}


/* Text styles */

.display1 {
  font-style: normal;
  font-weight: bold;
  font-size: 68px;
  line-height: 72px;
  letter-spacing: -2px;
}

.display2 {
  font-style: normal;
  font-weight: 500;
  font-size: 58px;
  line-height: 72px;
  /* identical to box height, or 124% */
  letter-spacing: -2px;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: -1.5px;
  color: #212173;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 38px;
  letter-spacing: -1px;
  color: #212173;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #212173;
}

h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: #212173;
}

h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: #212173;
}

h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.25px;
  color: #212173;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.55px;
  color: #212173;
  margin-bottom: 1rem;
}

.text-link {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.55px;
  text-decoration: none;
  color: #EF5B09;
}

.text-link-underlined {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.55px;
  text-decoration-line: underline;
}

.text-s {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.2px;

}

.text-bt {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.text-s-muted {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.1px;
}

.text-tiny {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.1px;
}

.text-s-caps {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.text-caps-muted {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}


/* Button style  */

.bt-s {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 14px 24px;
  background: #EF5B09;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  cursor: pointer;
}

.bt-s:hover {
  background: #FF7C34;
}

.bt-s:active {
  background: rgb(240, 182, 151);
}

.bt-standard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 28px;
  background: #EF5B09;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  cursor: pointer;
}

.bt-standard:disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 28px;
  background: #DADAE3;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  cursor: default;
}

.bt-standard>img {
  margin-right: 0.7rem;
  position: relative;
  top: 1px;
}

.bt-standard:hover:enabled {
  background: #FF7C34;
}

.bt-standard:active:enabled {
  background: #D64C00;
}

.bt-standard:disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 28px;
  background: #DADAE3;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  cursor: default;
}


.bt-disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 28px;
  background: #DADAE3;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  cursor: pointer;
}

.bt-standard-ghost {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 18px 28px;
  background: #FFFFFF;
  border-radius: 4px;
  color: #EF5B09;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  cursor: pointer;
}

.bt-standard-ghost:hover {
  background: rgba(239, 91, 9, 0.1);
}

.bt-standard-ghost:active {
  background: rgba(239, 91, 9, 0.2);
}

.bt-large {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 28px 55px;
  background: #EF5B09;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  cursor: pointer;
}

.bt-large:hover {
  background: #FF7C34;
}

.bt-large:active {
  background: #D64C00;
}

.bt-outline {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 28px;
  background: #FFFFFF;
  border: 2px solid #212173;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: #212173;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  cursor: pointer;
}

.bt-outline:hover {
  background: #212173;
  color: #FFFFFF;
}

.bt-outline:active {
  background: #110F35;
  color: #FFFFFF;
}


.bt-icon>img {
  margin-right: 1rem;
}


/* Input style */

input[type=text] {
  background: #FFFFFF;
  border: 1px solid #8B8B9C;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0.8rem;
  color: #212173;
}

input[type=password] {
  background: #FFFFFF;
  border: 1px solid #8B8B9C;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0.8rem;
  color: #212173;
}

input[type=text]:focus {
  border: 1px solid #212173;
}

input[type=password]:focus {
  border: 1px solid #212173;
}

input[type=text]:hover {
  border: 1px solid #212173;
}

input[type=password]:hover {
  border: 1px solid #212173;
}

::placeholder {
  color: #212173;
  opacity: 0.3;
  font-size: 15px;
}

label {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #212173;
}

/* This is for the main form layouts */

.title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: #F0F0F5 1px solid;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}

/* Form row combo class with horizontal line on top */

.top-line {
  border-top: 1px solid #F0F0F5;
  padding-top: 1.5rem;
}

.form-content>input[type=text] {
  width: 21.65rem;
}

.form-info {
  width: 250px;
  margin-right: 3rem;
}

.info-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: 161px;
  height: 1.75rem;
  background: #F0F0F5;
  border-radius: 4px;
}


.info-panel-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info-panel-row>p {
  margin-left: 0.5rem;
  line-height: 0rem;
  margin-bottom: 0rem;
}

.info-panel-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #212173;
  margin-left: 0.3rem;
}

.form-info>h5 {
  margin-bottom: 0.5rem;
}

.final-row {
  border-top: #F0F0F5 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2rem;
  margin-top: 1rem;
}

.final-row>div {
  margin-right: 2rem;
}

.form-vertical-radios {
  display: flex;
  flex-direction: column;
}

.form-radios-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-radios-row>input {
  margin-right: 0.5rem;
}

.form-vertical-radios>* {
  margin-bottom: 0.5rem;
}

/* Tooltip for features */

.feature-tooltip {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.2px;
  text-decoration-line: underline;
  text-underline-position: under;
  color: #212173;

}

.feature-tooltip .feature-tooltiptext {
  visibility: hidden;
  width: 160px;
  background: #110F35;
  border-radius: 4px;
  text-align: center;
  padding: 0.5rem;
  padding-bottom: 0.75rem;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -80px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #FFFFFF;
}

.feature-tooltip .feature-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #110F35 transparent transparent transparent;
}

.feature-tooltip:hover .feature-tooltiptext {
  visibility: visible;
}

/* Credit widget */

.credits-widget-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.credits-widget-container>img {
  margin-right: 0.5rem;
}

.credits-widget-text-wrapper>* {
  margin: 0px;
  margin-bottom: 0.25rem;
}


/* Toastify */

/** Classes for the displayed toast **/
/* 
.Toastify__toast-container {
  background-color: blueviolet;
}
.Toastify__toast {
  background-color: blueviolet;
}
.Toastify__toast--rtl {
  background-color: blueviolet;
}
.Toastify__toast--dark {
  background-color: blueviolet;
}
.Toastify__toast--default {
  background-color: blueviolet;
}
.Toastify__toast--info {
  background-color: blueviolet;
}
.Toastify__toast--success {
  background-color: blueviolet;
}
.Toastify__toast--warning {
  background-color: blueviolet;
}
.Toastify__toast--error {
  background-color: blueviolet;
}
.Toastify__toast-body {
  background-color: blueviolet;
} */