.faq-container {
    display: flex;
    flex-direction: row;
}

.faq-questions-container{
    flex-grow: 1;
    margin-right: 5rem;
}

.faq-question-wrapper > p{
margin-top: 1rem;
margin-bottom: 2rem;
}

.faq-contact-us-wrapper {
    width: 200px;
    height: 180px;
    background: #F0F0F5;
    border-radius: 4px;
    padding: 2rem;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 10rem;
}

.faq-contact-us-wrapper > p{
    margin-top: 0.75rem;
}

.faq-contact-us-wrapper > img{
    max-height: 6.5rem;
}