table {
    width: 35rem;
}

th {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #8B8B9C;
    text-align: left;
}

thead {
    margin-bottom: 1rem;
}

th {
    padding: 1rem;

}

td {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #212173;
    border-bottom: 1px solid #F0F0F5;
    padding: 1rem;

}

tr {
    height: 3rem;
}

tr:hover {
    background-color: #F0F0F5;
}