.credits-pricing-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
}

.credits-pricing-tier-wrapper {
    flex-grow: 1;
    width: 33%;
}

.credits-pricing-tier-wrapper:nth-child(2) {
    margin-left: 1rem;
    margin-right: 1rem;
}

.tier-content {
    background: #FFFFFF;
    border: 1px solid #F0F0F5;
    box-sizing: border-box;
    border-radius: 4px;
}

/* .tier-content:hover {
    background: #FAFAFD;
} */

.popular-outline {
    border: 1px solid #51D37E;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.fixwidth {
    max-width: 12rem;
}

.tier-highlight-bar {
    height: 2.5rem;
}

.popular {
    background: #51D37E;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.tier-top-section {
    padding: 1rem;
    height: 14rem;
}

.tier-top-section>* {
    margin-bottom: 0.75rem;
}

.price {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 38px;
    letter-spacing: -1px;
    color: #EF5B09;
}

.price-per-deck {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.55px;
    color: #EF5B09;
}

.no-of-decks {
    height: 2.5rem;
    margin-bottom: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #212173;
}

.no-of-decks>img {
    margin-right: 0.5rem;
    margin-left:1rem;
}

.tier-features {
    padding-top: 1rem;
    border-top: 1px solid #F0F0F5;
    margin-bottom: 1rem;
    margin-top: 1.25rem;
}

.tier-features > *{
    margin-left:1rem;
}

.tier-cta{
    height: 3rem;
    margin: 1rem;
}