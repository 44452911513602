.nav-item {
    display: flex;
    flex-direction: row;
    color: #212173;
    opacity: 0.7;
}

/* a.nav-item:hover {
    color: #212173;
    opacity: 1;
} */

.active-navlink {
    color: #EF5B09;
    opacity: 1;
}

.nav-item>img {
    margin-right: 0.5rem;
}

nav {
    background-color: #F0F0F5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 13rem;
    min-height: 100vh;
    padding-left: 1rem;
    padding-right: 1rem;
    position: fixed;
}

.nav-top {
    display: flex;
    flex-direction: column;
}

.nav-main {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
}

.nav-main>* {
    margin-bottom: 1rem;
}

.nav-bottom {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.nav-bottom>* {
    margin-bottom: 1.5rem;
}

.nav-bottom> hr {
width: 100%
}

.logo-sm {
    width: 150px;
    margin-top: 2.6rem;
}

.nav-item {
    display: flex;
    flex-direction: row;
}

.credits-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1rem 0.5rem 1rem;
    background: #212173;
    border-radius: 4px;
}

.credits-wrapper > img {
    margin-bottom: 1rem;
}