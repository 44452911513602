.style-wrapper > * {
    margin-left: 3rem;
    margin-bottom: 1rem;
    max-width: 50rem;
}

.style-label {
    color: #8B8B9C;
    margin-top: 0.5rem;
    margin-bottom: 5rem;
}

hr {
    color: #D2D2DA;
    border: 1px solid;
}