.account-input{
  width: 400px;
  height: 49px;
}

#logo-section {
  margin-top: 2rem;
}

.profile-image-update-form > input[type=submit]{
  margin-top: 1rem;
}

.bt-small {
  width: 100px;  
  margin: 10px 0px;
  height: 49px;
}

.border-bottom {
  border-bottom: #F0F0F5 1px solid;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.profile-img-container{
  position: relative;
  height: 85px;
  width: 85px;
  margin-right: 1rem; 
}
.profile-holder {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;  
}
.profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile-delete-img{
  position: relative;
  left: 40px;
  right: 0%;
  top: -85px;
  bottom: 0%;  
}

.logo-delete-img{
  position: relative;
  left: 0px;
  right: 0%;
  top: 0px;
  bottom: 0%;  
}

.profile-logo-img{
  position: relative;
  left: 40px;
  right: 0%;
  top: -35px;
  bottom: 0%;  
}

.flex-row {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}

.logo-container {
  width: 268px;
  height: 85px;
  background: rgba(239, 91, 9, 0.1);
  border-radius: 4px;
  margin: 0px 1rem 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container > img {
  max-width: 268px;
  max-height: 85px;
}

.logo-container > p {
margin-bottom: 0rem;
}