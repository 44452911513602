.download-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding: 1.5rem;
    margin: auto;
}

.download-logo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: #F0F0F5 1px solid;
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
}

.download-logo-wrapper>* {
    max-height: 3rem;
}

.download-deck-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
}

.download-deck-hero>* {
    margin-bottom: 0.75rem;
}

.download-deck-hero>h3 {
    margin-bottom: 1.5rem;
}

.download-thumbnail-spacer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.download-thumbnail-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #F0F0F5;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
}

.download-thumbnail-wrapper>img {
    max-height: 11rem;
}

.download-form>input {
    width: 100%;
    margin-bottom: 0.8rem;
}

.download-note {
    background: #F0F0F5;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    padding: 1.5rem 1rem 1.5rem 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.download-note>img {
    margin-right: 1rem;
}

.download-note>p {
    margin-bottom: 0rem;
    line-height: 24px;
}

.download-form>button {
    width: 100%;
    margin-top: 2rem;
}

.download-form-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.download-form-row>input {
    margin-right: 0.9rem;
}

.download-form-row>label {
    width: 90%;
    line-height: 22px;
}

.download-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: #F0F0F5 1px solid;
    padding-top: 1.5rem;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
}

.download-footer>img {
    width: 12rem;
}

.download-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.download-message>* {
    margin-bottom: 1rem;
}

.download-message>p {
    text-align: center;
}

.download-message>h4 {
    text-align: center;
}