.home-wrapper {
    background-color: #212173;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-temp-menu {
    display: flex;
    flex-direction: column;
    width: 18rem;
    margin: auto;
}

.home-temp-menu > * {
    margin-bottom: 3rem;
}


.w-checkbox {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px;
  }
  
  .w-checkbox::before {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
  
  .w-checkbox::after {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    clear: both;
  }
  
  .w-checkbox-input {
    float: left;
    margin-bottom: 0px;
    margin-left: -20px;
    margin-right: 0px;
    margin-top: 4px;
    line-height: normal;
  }
  
  .w-checkbox-input--inputType-custom {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-color: #ccc;
    border-bottom-color: #ccc;
    border-left-color: #ccc;
    border-right-color: #ccc;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    width: 12px;
    height: 12px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  
  .w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    border-top-color: #3898ec;
    border-bottom-color: #3898ec;
    border-left-color: #3898ec;
    border-right-color: #3898ec;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0px 0px 3px 1px #3898ec;
  }
  
  .w-form-formradioinput--inputType-custom {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-color: #ccc;
    border-bottom-color: #ccc;
    border-left-color: #ccc;
    border-right-color: #ccc;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    width: 12px;
    height: 12px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }
  
  .w-form-formradioinput--inputType-custom.w--redirected-focus {
    box-shadow: 0px 0px 3px 1px #3898ec;
  }
  
  .w-form-formradioinput--inputType-custom.w--redirected-checked {
    border-top-width: 4px;
    border-bottom-width: 4px;
    border-left-width: 4px;
    border-right-width: 4px;
    border-top-color: #3898ec;
    border-bottom-color: #3898ec;
    border-left-color: #3898ec;
    border-right-color: #3898ec;
  }
  
  /* body {
    background-color: #110f35;
    font-family: sofia-pro, sans-serif;
    color: #212173;
    font-size: 16px;
    line-height: 20px;
  }
  
  h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 65px;
    line-height: 63px;
    font-weight: 500;
    letter-spacing: -3.34px;
  }
  
  h2 {
    margin: 0px;
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    letter-spacing: -0.2px;
  }
  
  h3 {
    margin: 0px 0px 1px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }
  
  h4 {
    margin: 0px;
    color: #110f35;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
  
  h5 {
    margin: 0px;
    color: #8b8b9c;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
  
  h6 {
    margin: 10px 0px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
  }
  
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.2px;
  }
  
  a {
    color: #ef5b09;
    text-decoration: none;
  }
  
  ul {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 40px;
  }
  
  li {
    margin-bottom: 8px;
    font-size: 16px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #8b8b9c;
    font-weight: 700;
  }
   */
  .pk-section {
    background-color: #fff;
  }
  
  .pk-section.prisma {
    overflow: hidden;
    background-color: #f7f6fb;
  }
  
  .center {
    text-align: center;
  }
  
  .label {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    float: none;
    clear: none;
    color: #8b8b9c;
    font-size: 14px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
  
  .label._10marginbottom {
    margin-bottom: 10px;
  }
  
  .label.package {
    margin-bottom: 10px;
    color: rgba(17, 15, 53, 0.5);
  }
  
  .label.package.white {
    color: hsla(0, 0%, 100%, 0.5);
  }
  
  .body {
    padding-right: 0px;
    padding-left: 0px;
    color: #110f35;
  }
  
  .body.prisma-about {
    position: relative;
    z-index: 5;
    display: block;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
  }
  
  .body.highlight {
    color: #110f35;
    font-size: 18px;
    line-height: 25px;
  }
  
  .body.highlight.getstarted-text {
    margin-top: 10px;
    color: #8b8b9c;
  }
  
  .body.highlight.section_subtitle {
    margin-top: 15px;
    color: #8b8b9c;
  }
  
  .body.highlight.section_subtitle.bottom {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  
  .body.highlight.section_subtitle.whitetext {
    color: #fff;
  }
  
  .body.highlight.blog-lead {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .body.highlight.pricing {
    margin-bottom: 25px;
  }
  
  .body.large {
    font-size: 22px;
    line-height: 31px;
  }
  
  .body.large.margin-top {
    margin-top: 15px;
  }
  
  .body.large.price {
    margin-top: 20px;
    font-size: 36px;
  }
  
  .body.large.price.workshop {
    margin-top: 8px;
    font-size: 28px;
  }
  
  .body.large.price.euro {
    margin-top: 0px;
    margin-right: 5px;
    color: #8b8b9c;
    font-size: 19px;
    line-height: 23px;
  }
  
  .body.large.price.package-tag {
    margin-top: 0px;
  }
  
  .body.large.support-number {
    margin-right: 12px;
    color: #212173;
    font-size: 30px;
  }
  
  .body.feature-subtext {
    padding-right: 0px;
    padding-left: 0px;
    color: #8b8b9c;
    line-height: 23px;
  }
  
  .body.feature-subtext.left-align {
    padding-right: 0px;
    padding-left: 0px;
    text-align: left;
  }
  
  .body.feature-subtext.structure {
    margin-top: 10px;
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .body.protip-text {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  
  .body.protip-text.getstarted {
    margin-top: 0px;
  }
  
  .body.progresstime {
    color: #8b8b9c;
  }
  
  .body.footer-copyright {
    margin-right: 0px;
    color: #47478b;
  }
  
  .body.faq_block-text {
    overflow: hidden;
    padding-bottom: 20px;
  }
  
  .body.blog_small-lead {
    color: #8b8b9c;
  }
  
  .body.small {
    color: #8b8b9c;
    font-size: 14px;
  }
  
  .body.small.price {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .body.skill {
    line-height: 17px;
  }
  
  .body.bold {
    font-weight: 700;
  }
  
  .body.pricing-info {
    margin-bottom: 25px;
    text-align: left;
  }
  
  .wrapper {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    display: block;
    overflow: visible;
    height: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    background-color: rgba(0, 0, 0, 0.73);
    text-align: center;
  }
  
  ._3d_column1 {
    display: inline-block;
    width: 25%;
    padding: 10px;
    float: none;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  
  ._3d_column2 {
    display: inline-block;
    width: 25%;
    padding: 10px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  
  ._3d_column3 {
    display: inline-block;
    width: 25%;
    padding: 10px;
  }
  
  ._3d_column4 {
    display: inline-block;
    width: 25%;
    padding: 10px;
  }
  
  .prisma_div {
    position: relative;
    left: -67%;
    top: -190px;
    right: 0px;
    z-index: 0;
    width: 100%;
    height: 800px;
    min-width: 2600px;
    margin-bottom: -260px;
    background-image: url('/images/landing/prisma.jpg');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .section-portfolio {
    position: relative;
    display: block;
    overflow: hidden;
    height: auto;
    max-height: 1300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #111b35;
  }
  
  .section-portfolio.presentation {
    height: 900px;
    max-height: 900px;
    background-image: none;
    background-size: auto;
    background-repeat: repeat;
  }
  
  .section-portfolio.presentation {
    height: 900px;
    max-height: 900px;
    background-color: #111b35;
    background-image: none;
    background-size: auto;
    background-repeat: repeat;
  }
  
  .section-clients {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #fff;
  }
  
  .section-contactform {
    padding-top: 0px;
    padding-bottom: 0px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
    background-image: linear-gradient(180deg, #fff, #fff);
  }
  
  .section-footer {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #111b35;
  }
  
  .sv-h2 {
    margin-bottom: 25px;
    color: #212173;
    font-size: 40px;
    text-align: center;
    letter-spacing: -0.89px;
  }
  
  .sv-copy {
    display: block;
    width: 65%;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    font-family: sofia-pro, sans-serif;
    color: #9aa8cf;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
  }
  
  .sv-copy.contact {
    margin-right: 0px;
    margin-left: 0px;
    text-align: left;
  }
  
  .utility-page-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .utility-page-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 260px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  
  .utility-page-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  
  .portfolio-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 38%;
    height: 650px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .portfolio-wrapper.presentation {
    position: relative;
    z-index: 2;
    width: 40%;
  }
  
  .portfolio-wrapper.secondary {
    position: relative;
    z-index: 5;
    width: 50%;
    height: 720px;
  }
  
  .portfolio-wrapper.secondary.portfolio {
    width: 40%;
  }
  
  .clients-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .clients-item {
    width: 16.66666667%;
    margin-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .contact-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  
  .contact-container.contact2 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .contactform-wrapper {
    width: 50%;
    padding-right: 50px;
  }
  
  .contactform-illustration {
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-image: url('/images/landing/contact-img.png');
    background-position: 0px 0px;
    background-size: cover;
  }
  
  .contact-title {
    margin-bottom: 15px;
    color: #212173;
    font-size: 34px;
    line-height: 34px;
    font-weight: 700;
  }
  
  .contact-title.small {
    margin-bottom: 20px;
    font-size: 28px;
  }
  
  .form-box {
    height: 60px;
    margin-top: 20px;
    padding-bottom: 12px;
    padding-left: 0px;
    border-style: none none solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(234, 194, 184, 0.53);
    border-radius: 0px;
    background-color: transparent;
    -webkit-transition: border-color 200ms ease, color 200ms ease;
    transition: border-color 200ms ease, color 200ms ease;
    color: #616e8e;
    font-size: 16px;
  }
  
  .form-box:active {
    border-bottom-color: hsla(0, 0%, 100%, 0.67);
    color: #fff;
  }
  
  .form-box:focus {
    border-bottom-color: hsla(0, 0%, 100%, 0.53);
    color: #fff;
  }
  
  .form-box::-webkit-input-placeholder {
    color: #eac2b8;
    font-size: 16px;
  }
  
  .form-box:-ms-input-placeholder {
    color: #eac2b8;
    font-size: 16px;
  }
  
  .form-box::-ms-input-placeholder {
    color: #eac2b8;
    font-size: 16px;
  }
  
  .form-box::placeholder {
    color: #eac2b8;
    font-size: 16px;
  }
  
  .form-box.message {
    height: 150px;
    margin-bottom: 0px;
    padding-bottom: 80px;
  }
  
  .form-sendbutton {
    height: 55px;
    margin-top: 30px;
    padding: 10px 40px 13px;
    background-color: #131387;
    -webkit-transition: background-color 300ms ease, color 300ms ease;
    transition: background-color 300ms ease, color 300ms ease;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .section-about {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: #111b35;
  }
  
  .about-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .footer-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .footer-links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .footer-copyright {
    margin-right: 25px;
    color: #616e8e;
  }
  
  .footer-links-item {
    margin-right: 25px;
    color: #6572ff;
    letter-spacing: 1px;
    text-decoration: none;
  }
  
  .footer-links-item:hover {
    font-weight: 400;
    text-decoration: underline;
  }
  
  .about-description {
    width: 65%;
  }
  
  .about-followpanel {
    padding-left: 50px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .sv-label {
    margin-bottom: 20px;
    color: #616e8e;
    font-size: 14px;
  }
  
  .about-copy {
    width: 80%;
    font-family: sofia-pro, sans-serif;
    color: #fff;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 700;
  }
  
  .follow-icon.facebook {
    display: none;
  }
  
  .image {
    margin-right: 15px;
  }
  
  .sv-navbar {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: transparent;
  }
  
  .container {
    position: relative;
    display: block;
    width: 100%;
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding: 80px 30px 90px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .container.sv-portfolio-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 1200px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .container.sv-portfolio-container.presentation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    height: 900px;
  }
  
  .container.features-container {
    padding-top: 80px;
    padding-bottom: 90px;
  }
  
  .container.contact {
    max-width: 1100px;
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .navbar-container {
    display: block;
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .sv-logo {
    margin-top: 10px;
  }
  
  .sv-navlink {
    margin-right: 10px;
    margin-left: 10px;
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
  }
  
  .sv-navlink:hover {
    color: #9aa8cf;
    font-weight: 400;
    text-decoration: none;
  }
  
  .sv-navlink.w--current {
    color: #9aa8cf;
  }
  
  .sv-navlink.dropdown {
    margin-right: 0px;
    font-size: 13px;
  }
  
  .sv-navlink.dropdown.w--current {
    margin-right: 0px;
    color: #eac2b8;
  }
  
  .sv-navlink.dropdown.w--current:hover {
    color: #fff;
  }
  
  .sv-navlink.dropdown.w--current:active {
    color: #eac2b8;
  }
  
  .sv-navlink.dropdown.sub {
    padding-top: 3px;
    padding-left: 38px;
    color: hsla(0, 0%, 100%, 0.65);
    font-weight: 400;
  }
  
  .sv-navlink.dropdown.sub:hover {
    color: #9aa8cf;
  }
  
  .sv-navlink.dropdown.sub.w--current {
    color: #eac2b8;
  }
  
  .sv-navlink.black {
    color: #111b35;
  }
  
  .sv-navlink.black:hover {
    color: #616e8e;
  }
  
  .portfolio-header {
    padding-right: 20%;
    color: #fff;
    font-size: 40px;
    line-height: 38px;
    letter-spacing: -0.89px;
  }
  
  .portfolio-description {
    margin-top: 28px;
    font-family: sofia-pro, sans-serif;
    color: #9aa8cf;
    font-size: 20px;
    line-height: 1.5;
  }
  
  .sv_button {
    display: none;
    height: 55px;
    padding-top: 17px;
    padding-right: 40px;
    padding-left: 40px;
    background-color: #131387;
    -webkit-transition: background-color 300ms ease, color 300ms ease;
    transition: background-color 300ms ease, color 300ms ease;
    font-family: sofia-pro, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .sv_button:hover {
    background-color: #fff;
    color: #131387;
  }
  
  .sv_button.small {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    padding: 0px 25px 2px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: #131387;
    font-family: sofia-pro, sans-serif;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
  }
  
  .sv_button.small:hover {
    background-color: #fff;
    color: #131387;
  }
  
  .sv_button.small.portfoliobtn {
    display: none;
    width: 55%;
    margin-top: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .section-features {
    height: auto;
    background-color: #e6f1f6;
  }
  
  .sv-features_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .features-copy {
    width: 35%;
    margin-right: 160px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .features-icons_block {
    padding: 40px 70px 20px 40px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
  }
  
  .features-icons_block.presentation {
    height: auto;
    padding-right: 60px;
  }
  
  .features-icons_block.contact {
    height: auto;
    padding-top: 30px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #cee2ec;
  }
  
  .sv-h4.features {
    margin-top: 0px;
    margin-bottom: 22px;
    font-family: sofia-pro, sans-serif;
    color: #212173;
    font-size: 40px;
    line-height: 38px;
    font-weight: 700;
  }
  
  .sv-features_description {
    margin-bottom: 0px;
    font-family: sofia-pro, sans-serif;
    color: #9aa8cf;
    font-size: 20px;
    line-height: 29px;
    font-weight: 400;
  }
  
  .features-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .sv-h5 {
    margin-top: 0px;
    margin-bottom: 15px;
    color: #222471;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: -0.44px;
  }
  
  .sv-small-text {
    margin-bottom: 0px;
    font-family: sofia-pro, sans-serif;
    color: #9aa8cf;
    font-size: 16px;
    line-height: 23px;
  }
  
  .sv-small-text.contact {
    color: #616e8e;
  }
  
  .features_icon {
    width: 122px;
    margin-right: 25px;
  }
  
  .features_icon.contact {
    border-radius: 100px;
    background-color: #fff;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.05);
  }
  
  .subtitle {
    color: #d2d2da;
    font-size: 22px;
    line-height: 34px;
    font-weight: 400;
  }
  
  .subtitle.hero-subtitle {
    margin-top: 30px;
  }
  
  .subtitle.hero-subtitle.landing {
    padding-right: 60px;
  }
  
  .subtitle.hero-subtitle.buyslides {
    padding-right: 0%;
  }
  
  .subtitle.hero-subtitle.steps {
    color: rgba(17, 15, 53, 0.4);
  }
  
  .featured_image {
    width: 428px;
    height: auto;
    max-width: 80%;
    margin-top: 10px;
    margin-left: -15px;
  }
  
  .icon {
    position: absolute;
    top: 26px;
    right: 25px;
    width: 9px;
    margin-top: 3px;
    background-image: url('/images/landing/icon_dropdown.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .dropdown-list {
    background-color: transparent;
  }
  
  .dropdown-list:hover {
    background-color: #141455;
  }
  
  .dropdown-list.w--open {
    padding-bottom: 10px;
    background-color: #141455;
  }
  
  .dropdown-toggle.black.w--open:hover {
    color: #fff;
  }
  
  .portfolio_illustration_wrapper {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
    display: block;
    overflow: visible;
    width: 100%;
    height: 750px;
    margin-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .portfolio_illustration_wrapper.buyslides_hero {
    height: auto;
    max-width: 960px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .portfolio_illustration_wrapper.secondary.workshop {
    height: 100%;
  }
  
  .portfolio-illustration_img {
    position: absolute;
    width: 430px;
    height: 240px;
    border-radius: 10px;
  }
  
  .portfolio-illustration_img._1 {
    position: absolute;
    left: 81%;
    top: 13%;
    z-index: 2;
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 27, 53, 0.32)), to(rgba(17, 27, 53, 0.32))), -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), color-stop(0%, rgba(0, 0, 0, 0.3)), to(transparent)), url('/images/landing/presentation-kickstart_travellers-guide-RC1_Emergency-Zen-Protocol.png');
    background-image: linear-gradient(180deg, rgba(17, 27, 53, 0.32), rgba(17, 27, 53, 0.32)), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3) 0%, transparent), url('/images/landing/presentation-kickstart_travellers-guide-RC1_Emergency-Zen-Protocol.png');
    background-position: 0px 0px, 0px 0px, 50% 50%;
    background-size: auto, auto, cover;
    background-repeat: repeat, repeat, no-repeat;
  }
  
  .portfolio-illustration_img._1.agency {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.45)), to(rgba(0, 0, 0, 0.45))), -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), color-stop(0%, rgba(0, 0, 0, 0.3)), to(transparent)), url('/images/landing/DTG-28.png');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3) 0%, transparent), url('/images/landing/DTG-28.png');
    background-size: auto, auto, cover;
  }
  
  .portfolio-illustration_img._1.workshop-3 {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(71, 71, 139, 0.26)), to(rgba(71, 71, 139, 0.26))), -webkit-gradient(linear, left bottom, left top, from(#110f35), color-stop(0%, rgba(0, 0, 0, 0.3)), to(rgba(71, 71, 139, 0))), url('/images/landing/Bumper-copy-2.jpg');
    background-image: linear-gradient(180deg, rgba(71, 71, 139, 0.26), rgba(71, 71, 139, 0.26)), linear-gradient(0deg, #110f35, rgba(0, 0, 0, 0.3) 0%, rgba(71, 71, 139, 0)), url('/images/landing/Bumper-copy-2.jpg');
    background-size: auto, auto, cover;
  }
  
  .portfolio-illustration_img._2 {
    position: absolute;
    left: 16%;
    top: 76%;
    z-index: 3;
    width: 530px;
    height: 300px;
    border-radius: 9px;
    background-image: url('/images/landing/presentation-kickstart_travellers-guide-ebook_template-3-6_Anxiety-Intro.png');
    background-size: cover;
  }
  
  .portfolio-illustration_img._2.agency {
    background-image: url('/images/landing/smartvizual-zalando-animation.gif');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .portfolio-illustration_img._2.workshop-5 {
    background-image: url('/images/landing/quote_bg-1.jpg');
  }
  
  .portfolio-illustration_img.empty {
    position: absolute;
    left: 48%;
    top: -7%;
    z-index: 1;
    width: 430px;
    height: 240px;
    border-radius: 10px;
    background-image: linear-gradient(45deg, #403e67, #b79ea1);
    opacity: 0.16;
  }
  
  .portfolio-illustration_img.empty.empty-2 {
    left: 59%;
    top: 82%;
    z-index: 0;
    width: 430px;
    height: 240px;
    background-image: linear-gradient(45deg, #283065, #5a546a);
    opacity: 0.1;
  }
  
  .portfolio-illustration_img.empty.empty3 {
    left: 71%;
    top: 75%;
    z-index: 0;
    width: 551px;
    height: 267px;
    opacity: 0.19;
  }
  
  .portfolio-illustration_img._3 {
    position: absolute;
    left: 36%;
    top: 3%;
    z-index: 2;
    width: 460px;
    height: 260px;
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 27, 53, 0.18)), to(rgba(17, 27, 53, 0.18))), url('/images/landing/presentation-kickstart_travellers-guide-ebook_template-3-6_presentation-principle-5.png');
    background-image: linear-gradient(180deg, rgba(17, 27, 53, 0.18), rgba(17, 27, 53, 0.18)), url('/images/landing/presentation-kickstart_travellers-guide-ebook_template-3-6_presentation-principle-5.png');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    box-shadow: 0 14px 26px 0 rgba(0, 0, 0, 0.08);
  }
  
  .portfolio-illustration_img._3.agency {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('/images/landing/smartvizual-animated-presentation-example.gif');
    background-image: linear-gradient(180deg, transparent, transparent), url('/images/landing/smartvizual-animated-presentation-example.gif');
    background-size: auto, cover;
  }
  
  .portfolio-illustration_img._3.workshop-2 {
    overflow: hidden;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(31, 22, 90, 0.08)), to(rgba(31, 22, 90, 0.08)));
    background-image: linear-gradient(180deg, rgba(31, 22, 90, 0.08), rgba(31, 22, 90, 0.08));
    box-shadow: 0 14px 26px 0 rgba(0, 0, 0, 0.04);
  }
  
  .portfolio-illustration_img._4 {
    left: 71%;
    top: -14%;
    z-index: 2;
    display: none;
    width: 430px;
    height: 320px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 27, 53, 0.4)), to(transparent)), url('/images/landing/4-3.png');
    background-image: linear-gradient(180deg, rgba(17, 27, 53, 0.4), transparent), url('/images/landing/4-3.png');
    background-position: 0px 0px, 0px 0px;
    background-size: auto, cover;
    box-shadow: 0 23px 64px 0 rgba(0, 0, 0, 0.1);
  }
  
  .portfolio-illustration_img._5 {
    left: 55%;
    top: 32%;
    z-index: 3;
    display: block;
    background-image: url('/images/landing/smartvizual-animated-presentation.gif');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.14);
  }
  
  .portfolio-illustration_img._5._2 {
    top: 30%;
    width: 530px;
    height: 300px;
    background-image: url('/images/landing/presentation-kickstart_travellers-guide-RC1_body-language.png');
    background-size: cover;
  }
  
  .portfolio-illustration_img._5._2.agency {
    background-image: url('/images/landing/ezgif-5-1ac79ac4a2.gif');
    background-size: cover;
  }
  
  .portfolio-illustration_img._5.workshop-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 530px;
    height: 300px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #1f165a;
    background-image: none;
  }
  
  .portfolio-illustration_img._6 {
    left: 77%;
    top: 90%;
    z-index: 1;
    width: 481px;
    height: 267px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 27, 53, 0.16)), to(rgba(17, 27, 53, 0.16))), url('/images/landing/presentation-kickstart_travellers-guide-ebook_template-3-6_presenter-display.png');
    background-image: linear-gradient(180deg, rgba(17, 27, 53, 0.16), rgba(17, 27, 53, 0.16)), url('/images/landing/presentation-kickstart_travellers-guide-ebook_template-3-6_presenter-display.png');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }
  
  .portfolio-illustration_img._6.agency {
    left: 60%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 15, 53, 0.47)), to(rgba(17, 15, 53, 0.47))), url('/images/landing/4-3.png');
    background-image: linear-gradient(180deg, rgba(17, 15, 53, 0.47), rgba(17, 15, 53, 0.47)), url('/images/landing/4-3.png');
    background-size: auto, cover;
  }
  
  .portfolio-illustration_img._6.workshop-6 {
    left: 72%;
    top: 82%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('/images/landing/cover.jpg');
    background-image: linear-gradient(180deg, transparent, transparent), url('/images/landing/cover.jpg');
    background-size: auto, cover;
  }
  
  .portfolio-illustration_img._7 {
    left: 41%;
    top: 54%;
    z-index: 1;
    width: 430px;
    height: 240px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 27, 53, 0.45)), to(rgba(17, 27, 53, 0.45))), url('/images/landing/presentation-kickstart_travellers-guide-RC1_voice.png');
    background-image: linear-gradient(180deg, rgba(17, 27, 53, 0.45), rgba(17, 27, 53, 0.45)), url('/images/landing/presentation-kickstart_travellers-guide-RC1_voice.png');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }
  
  .portfolio-illustration_img._7.agency {
    left: 29%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(34, 59, 124, 0.3)), to(rgba(34, 59, 124, 0.3)));
    background-image: linear-gradient(180deg, rgba(34, 59, 124, 0.3), rgba(34, 59, 124, 0.3));
    background-position: 0px 0px;
  }
  
  .portfolio-illustration_img._7.workshop-4 {
    top: 56%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(71, 71, 139, 0.41)), to(rgba(71, 71, 139, 0.41))), url('/images/landing/toolbox.jpg');
    background-image: linear-gradient(180deg, rgba(71, 71, 139, 0.41), rgba(71, 71, 139, 0.41)), url('/images/landing/toolbox.jpg');
    background-size: auto, cover;
  }
  
  .portfolio-illustration_img._4 {
    left: 71%;
    top: -14%;
    z-index: 2;
    width: 430px;
    height: 320px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 27, 53, 0.4)), to(transparent)), url('/images/landing/4-3.png');
    background-image: linear-gradient(180deg, rgba(17, 27, 53, 0.4), transparent), url('/images/landing/4-3.png');
    background-position: 0px 0px, 0px 0px;
    background-size: auto, cover;
    box-shadow: 0 23px 64px 0 rgba(0, 0, 0, 0.1);
  }
  
  .sv_successmessage {
    margin-top: 30px;
    border-radius: 8px;
    background-color: #e6f1f6;
    color: #222471;
    font-weight: 700;
  }
  
  .link {
    color: #ef5b09;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
  }
  
  .link:hover {
    color: #f77933;
  }
  
  .link.phone {
    color: #212173;
    font-weight: 400;
  }
  
  .link.phone:hover {
    color: #ef5b09;
  }
  
  .image-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 50px 110px 50px 40px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    background-color: #e8f4fa;
  }
  
  .image-block.quote-block {
    padding: 50px 59px 60px;
  }
  
  .article-block_img {
    width: auto;
    height: auto;
    max-width: 240px;
    margin-right: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .image-block_text {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .paragraph {
    margin-top: 15px;
    font-family: sofia-pro, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  }
  
  .paragraph.note {
    margin-top: 5px;
    margin-bottom: 0px;
    color: #110f35;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.3px;
  }
  
  .checklist {
    width: 30px;
    height: 30px;
    margin-right: 13px;
  }
  
  .description {
    font-family: sofia-pro, sans-serif;
    font-size: 15px;
    line-height: 19px;
  }
  
  .description.team {
    margin-top: 15px;
    margin-bottom: 20px;
    color: #8b8b9c;
  }
  
  .description.note {
    margin-top: 5px;
  }
  
  .section_title-wrapper.prisma {
    position: relative;
    z-index: 5;
  }
  
  .prisma_wrapper {
    position: relative;
  }
  
  .highlight {
    color: #212173;
  }
  
  .form-block {
    margin-bottom: 0px;
  }
  
  .pk-hero-right {
    position: relative;
    left: -10px;
    top: 29px;
    right: 0px;
    width: 600px;
    height: 350px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-color: #ef5b09;
  }
  
  .pk-navbar {
    z-index: 90;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 90px;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #110f35;
  }
  
  .pk_logo-img.slidesfox {
    width: 160px;
  }
/*   
  .nav-link {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    color: #8b8b9c;
    font-size: 16px;
    font-weight: 400;
  }
  
  .nav-link:hover {
    color: #fff;
  }
  
  .nav-link:focus {
    color: #ef5b09;
  }
  
  .nav-link.w--current {
    color: #ef5b09;
  }
  
  .nav-link.white {
    font-weight: 400;
  }
  
  .nav-link.white:hover {
    color: #131387;
  }
  
  .nav-link.white:focus {
    color: #ef5b09;
  }
  
  .nav-link.white.small-gap {
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .nav-link.rightside {
    margin-right: 0px;
    margin-left: auto;
  }
  
  .nav-link.dropdown {
    padding: 15px;
  }
  
  .nav-link.dropdown:hover {
    color: #1f165a;
  } */
  
  .button-small {
    position: static;
    display: inline-block;
    height: 44px;
    margin-left: 15px;
    padding-top: 9px;
    border: 2px solid #ef5b09;
    border-radius: 3px;
    background-color: transparent;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    color: #ef5b09;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
  }
  
  .button-small:hover {
    background-color: #ef5b09;
    color: #fbfbfb;
    font-weight: 700;
  }
  
  .box_wrapper {
    position: relative;
    z-index: 200;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    padding: 35px 30px 45px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid #dcdcea;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.04);
    text-align: center;
  }
  
  .box_wrapper.middle {
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .box_wrapper.bootcamp-feature {
    padding: 0px 0px 35px;
  }
  
  .box_wrapper.dark {
    border-style: none;
    background-color: #1f165a;
  }
  
  .box_wrapper.dark.congrat_progress-bar {
    margin-top: 30px;
    padding: 30px 30px 40px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }
  
  .box_wrapper.dark.tight {
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .box_wrapper.list {
    margin-right: 0px;
    margin-bottom: 15px;
    padding: 25px 60px 28px 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .box_wrapper.list.end {
    margin-bottom: 0px;
  }
  
  .box_wrapper.list.nobox {
    margin-bottom: 25px;
    padding: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-style: none;
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none;
  }
  
  .box_wrapper.end {
    margin-right: 0px;
  }
  
  .box_wrapper.faq_block {
    margin-bottom: 20px;
    padding-top: 25px;
    padding-bottom: 10px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
    cursor: pointer;
  }
  
  .box_wrapper.orange {
    top: -80px;
    margin-top: 40px;
    margin-right: 0px;
    padding: 40px 39px 45px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: none;
    background-color: #ef5b09;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    text-align: left;
  }
  
  .box_wrapper.step {
    margin-right: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-style: none;
    background-color: transparent;
    box-shadow: none;
  }
  
  .box_wrapper.step.end {
    margin-right: 0px;
  }
  
  .box_wrapper.step.congrat-step {
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 0px;
  }
  
  .box_wrapper.step.col5 {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .box_wrapper.congrat {
    height: 100%;
    margin-right: 0px;
    padding-bottom: 30px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }
  
  .box_wrapper.book-col {
    margin-right: 50px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: none;
    background-color: transparent;
    box-shadow: none;
  }
  
  .box_wrapper.congrat_progress-bar {
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 20px;
  }
  
  .box_wrapper.steps_columns {
    margin-right: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .box_wrapper.address {
    margin-right: 50px;
    padding: 30px 30px 40px 35px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }
  
  .box_wrapper.skills {
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .box_wrapper.highlighted {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    background-color: #cde1eb;
  }
  
  .box_wrapper.highlighted.article {
    min-width: 270px;
    margin-right: 0px;
  }
  
  .box_wrapper.article_lowprior {
    height: 100%;
    min-width: 250px;
    margin-right: 0px;
    padding: 20px 15px 35px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  .box_wrapper.article-block_emergency {
    margin-right: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #cfe8de;
    text-align: left;
  }
  
  .box_wrapper.noframe {
    border-style: none;
    background-color: transparent;
    box-shadow: none;
  }
  
  .box_wrapper.pricing {
    padding: 45px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  .box_wrapper.pricing.highlighted-pricing {
    height: auto;
    max-height: none;
    min-height: 580px;
    border-style: none;
    background-color: #cde1eb;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.06);
  }
  
  .box_wrapper.title-block {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border-style: none;
    background-color: transparent;
    box-shadow: none;
    text-align: left;
  }
  
  .box_wrapper.bootcamp-package {
    margin-right: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .box_wrapper.bootcamp-package.margins {
    padding-top: 35px;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .box_wrapper.bootcamp-package.margins.package {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-style: none;
    background-color: #dcdcea;
  }
  
  .box_wrapper.no-box-text {
    margin-right: 0px;
    padding: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: none;
    border-radius: 0px;
    box-shadow: none;
    text-align: left;
  }
  
  .box_text {
    margin-top: 0px;
    padding-left: 0px;
  }
  
  .box_text.left-align {
    text-align: left;
  }
  
  .box_text.left-align.list {
    margin-left: 15px;
  }
  
  .box_text.title-box {
    width: 36%;
  }
  
  .features_wrapper {
    position: relative;
    display: -ms-grid;
    display: grid;
    width: 100%;
    margin-top: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  
  .features_wrapper.list {
    width: 100%;
    min-width: 330px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }
  
  .features_wrapper.list.tags {
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  
  .features_wrapper._20bottommargin {
    margin-bottom: 20px;
  }
  
  .features_wrapper.hide {
    display: none;
  }
  
  .features_wrapper.steps_grid {
    z-index: 1;
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  
  .features_wrapper.steps_grid._2col-layout {
    grid-column-gap: 20px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  
  .features_wrapper.features_grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  
  .features_wrapper.pricing {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  
  .features_wrapper.bootcamp-package-row {
    margin-top: 30px;
    margin-bottom: 60px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
  .features_wrapper.bootcamp-package-row.support {
    margin-top: 80px;
    margin-bottom: 60px;
    -ms-grid-columns: 2fr 1fr 1fr;
    grid-template-columns: 2fr 1fr 1fr;
  }
  
  .features_wrapper.bootcamp-package-row.packages {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .features_wrapper.bootcamp-package-row.packages.end {
    margin-bottom: 0px;
  }
  
  .features_wrapper.timeline {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  
  .features_wrapper.timeline._4col {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
  .features_wrapper.travelguide {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  
  .features_wrapper._2col {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  
  .features_wrapper.slidesfox {
    display: block;
  }
  
  ._2col-block_text {
    width: auto;
    margin-right: 0px;
    padding-top: 68px;
    padding-right: 92px;
    padding-bottom: 61px;
    float: none;
    clear: none;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .orange-box_wrapper {
    position: relative;
    z-index: 0;
    display: block;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 5px 50px 50px;
    border-radius: 5px;
    background-color: #ef5b09;
    color: #fff;
  }
  
  .orange-box_wrapper.article {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 30px;
    padding-right: 50px;
    padding-bottom: 30px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(232, 244, 250, 0.49);
  }
  
  .orange-box_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: transparent;
  }
  
  .pk-s4-orange-icon1 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 250px;
    height: 95px;
    margin-right: 10px;
    padding: 20px 26px 20px 28px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 3px;
    background-color: #e05000;
    color: #fff;
  }
  
  .orangebox-headphone_image {
    position: absolute;
    left: 0px;
    top: -152px;
    right: 34px;
    bottom: 50px;
    z-index: -4;
    display: block;
  }
  
  .orangebox-headphone_image.slidefox {
    left: auto;
    top: -20%;
    right: 5%;
    bottom: auto;
    width: 370px;
  }
  
  .protip-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: auto;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    padding: 25px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: hsla(0, 0%, 100%, 0);
    -webkit-transition: background-color 200ms ease, box-shadow 200ms ease, border-color 200ms ease;
    transition: background-color 200ms ease, box-shadow 200ms ease, border-color 200ms ease;
  }
  
  .protip-wrapper:hover {
    border: 1px solid #e6f1f6;
    background-color: #fff;
    box-shadow: 0 7px 14px -2px rgba(0, 0, 0, 0.02);
  }
  
  .protip-wrapper.getstarted {
    width: 100%;
    height: 100%;
    margin-left: 0px;
    padding: 15px 0px 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-style: none;
  }
  
  .protip-wrapper.getstarted:hover {
    border-style: none;
    background-color: transparent;
    box-shadow: none;
  }
  
  .pro-tip_textwrapper {
    max-width: 420px;
    margin-top: 0px;
    padding-left: 25px;
  }
  
  .pro-tip_textwrapper.getstarted {
    max-width: 100%;
    padding-left: 25px;
  }
  
  .playlist-thumbnail-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 150px;
    height: auto;
  }
  
  .playbutton-large {
    position: absolute;
    left: 41px;
    top: 41px;
  }
  
  .playbutton-large.getstarted {
    left: 47px;
    top: 47px;
    width: 55px;
    height: 55px;
  }
  
  .footer {
    display: block;
    padding-top: 60px;
    padding-bottom: 120px;
    background-color: #110f35;
  }
  
  .footer_wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .footer-link {
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    color: #8b8b9c;
    font-size: 16px;
  }
  
  .footer-link:hover {
    color: #e6f1f6;
  }
  
  .pk-hero-left-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60%;
    max-width: 100%;
    min-width: 385px;
    margin-top: 45px;
    padding: 18px 16px 16px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    background-color: #1d1b4a;
    text-decoration: none;
  }
  
  .image-17 {
    position: absolute;
    left: 16px;
    top: 16px;
    right: 1px;
    bottom: 0px;
    z-index: 0;
    max-width: none;
  }
  
  .pk-hero-play-image-block {
    position: relative;
    margin-right: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  
  .pk-hero-left-text-block {
    margin-top: -12px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .image-18 {
    position: relative;
    display: block;
    width: 105px;
    height: 105px;
    max-width: none;
    margin-top: 0px;
    padding-top: 0px;
  }
  
  .faq {
    font-family: sofia-pro, sans-serif;
  }
  
  .pkp-p4 {
    padding-left: 0px;
    color: #110f35;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    letter-spacing: -0.3px;
  }
  
  .pkp-p4.hero-video {
    padding-right: 0px;
    color: #fff;
    font-weight: 600;
    letter-spacing: -0.1px;
  }
  
  .pkp-p4.hero-video {
    margin-bottom: -4px;
    color: #fff;
    font-weight: 700;
  }
  
  .pkp-video {
    color: #a5a5d1;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    letter-spacing: 0.1px;
  }
  
  .pkp-video.hero {
    margin-left: 10px;
  }
  
  .link-text {
    margin-top: 10px;
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
  }
  
  .link-text:hover {
    color: rgba(239, 91, 9, 0.7);
  }
  
  .link-text.hero {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .feature-subtext {
    margin-top: 15px;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .navbar_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 90px;
    max-width: 1120px;
    margin-right: auto;
    margin-left: auto;
    padding: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .pk_logo {
    width: 230px;
    margin-right: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .button-link {
    display: inline-block;
    width: auto;
    color: #ef5b09;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    text-decoration: none;
  }
  
  .section-image {
    display: block;
    width: auto;
    height: auto;
    max-width: 440px;
    margin-right: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .section-image.aboutus {
    margin-top: 20px;
    margin-right: 0px;
  }
  
  .section-image.buyslides {
    max-width: 460px;
    margin-top: 40px;
    margin-right: 0px;
  }
  
  .section-image.travelguide {
    width: auto;
    max-width: 470px;
    margin-right: 0px;
  }
  
  .section-image.travelguide.price {
    max-width: 270px;
  }
  
  .section-image.conference {
    margin-top: 30px;
    margin-right: 0px;
  }
  
  .contact_text {
    width: 100%;
    height: 100%;
    padding-top: 0px;
  }
  
  .contact_text.form {
    margin-top: 100px;
  }
  
  .contact_text.form.modal {
    margin-top: 20px;
  }
  
  .wrapper_hero-text {
    width: 50%;
    padding-right: 0%;
    float: left;
    color: #fff;
  }
  
  .wrapper_hero-text.buyslides {
    position: relative;
    z-index: 10;
    width: 69%;
    max-width: 100%;
    padding-right: 0%;
  }
  
  .wrapper_hero-text.blog {
    width: auto;
    max-width: 100%;
    padding-right: 26%;
  }
  
  .wrapper_hero-text.bootcamp {
    z-index: 20;
    width: 100%;
    padding-right: 6%;
  }
  
  .wrapper_hero-text._80 {
    width: 80%;
    padding-right: 0%;
  }
  
  .wrapper_hero-text.congrat {
    width: 100%;
    min-width: 550px;
    padding-right: 0%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .wrapper_hero-text.steps {
    max-width: 50%;
    padding-right: 1%;
  }
  
  .wrapper_hero-text._100 {
    width: 100%;
    max-width: 510px;
    margin-right: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  
  .wrapper_hero-text.audience {
    width: 100%;
    padding-right: 4%;
  }
  
  .wrapper_hero-text.faq {
    width: 100%;
  }
  
  .pkf-s1-image {
    width: 50%;
    height: 100%;
    float: left;
  }
  
  .image-39 {
    position: relative;
    left: 20px;
    right: 0px;
    display: block;
    width: 540px;
    max-width: none;
    margin-top: 6px;
    float: none;
    clear: none;
  }
  
  .pkf-s2-text-container {
    width: 48%;
    margin-bottom: 40px;
    padding-right: 0px;
  }
  
  .pkf-s2-text-container.steps {
    width: 80%;
  }
  
  .about_features {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0px;
    margin-bottom: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .h4 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }
  
  .h4.darktext {
    color: #131387;
  }
  
  .h4.darktext.leftalign {
    text-align: left;
  }
  
  .arrow {
    position: absolute;
    left: auto;
    top: 47%;
    right: -17%;
    bottom: 0%;
    z-index: 99999;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  
  .arrow._2 {
    left: -64px;
  }
  
  .arrow.steps-small {
    position: absolute;
    left: 90%;
    top: 31%;
    right: auto;
    bottom: 0%;
    width: 50px;
  }
  
  .arrow.steps-small.congrat {
    top: 16%;
    width: 40px;
  }
  
  .arrow.steps-small.blog {
    left: 81%;
    top: 2%;
  }
  
  .arrow.long {
    left: 15%;
    top: 50%;
    right: auto;
    bottom: auto;
    width: 740px;
  }
  
  .arrow.long.col4 {
    left: 11%;
    top: 230px;
    bottom: 50%;
    width: 78%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .arrow.long.col5 {
    left: 8%;
    top: 230px;
    display: block;
    width: 84%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .pkf-s4-wrapper-textblock {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: -4px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .pkf-s4-wrapper-textblock.bootcamp {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }
  
  .pkf-s4-wrapper-textblock.conference {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }
  
  .pkbs-form-block {
    position: static;
    display: inline-block;
    margin-top: 2px;
    margin-bottom: 0px;
    padding-top: 6px;
  }
  
  .text-field {
    width: 428px;
    height: 55px;
    padding-top: 5px;
    padding-left: 18px;
    border: 2px solid #ef5b09;
    border-radius: 2px 0px 0px 2px;
    background-color: transparent;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    color: #ef5b09;
    font-size: 16px;
  }
  
  .text-field:hover {
    background-color: rgba(239, 91, 9, 0.06);
  }
  
  .text-field:active {
    color: #f6f9fc;
  }
  
  .text-field::-webkit-input-placeholder {
    color: rgba(247, 121, 51, 0.52);
  }
  
  .text-field:-ms-input-placeholder {
    color: rgba(247, 121, 51, 0.52);
  }
  
  .text-field::-ms-input-placeholder {
    color: rgba(247, 121, 51, 0.52);
  }
  
  .text-field::placeholder {
    color: rgba(247, 121, 51, 0.52);
  }
  
  .pkb-submit-button {
    display: inline;
    height: 55px;
    padding-right: 30px;
    padding-left: 30px;
    border-style: solid;
    border-width: 2px 2px 2px 0px;
    border-color: #ef5b09;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: transparent;
    -webkit-transition: background-color 200ms ease, color 200ms ease;
    transition: background-color 200ms ease, color 200ms ease;
    color: #ef5b09;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .pkb-submit-button:hover {
    background-color: #ef5b09;
    color: #fff;
  }
  
  .pkb-submit-button.bkps {
    height: 44px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    color: #ef5b09;
  }
  
  .form-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .pk-navbar-white-blog {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    max-height: none;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
  }
  
  .pkbs-form2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  
  .pkbs-text-field {
    width: 200px;
    height: auto;
    margin-bottom: 0px;
    padding-top: 8px;
    border: 2px solid #ef5b09;
    border-radius: 2px 0px 0px 2px;
    background-color: transparent;
    color: transparent;
  }
  
  .pkbs-text-field.menu {
    padding-top: 7px;
    padding-bottom: 9px;
    background-color: transparent;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    color: #110f35;
    font-size: 15px;
  }
  
  .pkbs-text-field.menu:hover {
    background-color: rgba(247, 121, 51, 0.05);
    color: #110f35;
  }
  
  .pkbs-text-field.menu:focus {
    border-color: #ef5b09;
    background-color: rgba(247, 121, 51, 0.1);
    color: #110f35;
  }
  
  .pkbs-text-field.menu::-webkit-input-placeholder {
    color: #a6a6bb;
  }
  
  .pkbs-text-field.menu:-ms-input-placeholder {
    color: #a6a6bb;
  }
  
  .pkbs-text-field.menu::-ms-input-placeholder {
    color: #a6a6bb;
  }
  
  .pkbs-text-field.menu::placeholder {
    color: #a6a6bb;
  }
  
  .pkbs-nav-menz {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .pk-steps {
    background-color: #f7f7f7;
    font-family: sofia-pro, sans-serif;
  }
  
  .pk-steps._2 {
    font-size: 16px;
  }
  
  .pk-steps-s1 {
    height: 640px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: #e8f4fa;
    font-weight: 600;
  }
  
  .pk-steps-s1-container {
    display: block;
    max-width: 1100px;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    float: none;
  }
  
  .pk-steps-image {
    position: relative;
    width: 100%;
    max-width: 50%;
    margin-top: 39px;
    margin-left: -43px;
    float: left;
  }
  
  .pk-s1 {
    display: block;
    width: 500px;
    max-width: none;
  }
  
  .pk-steps-text {
    max-width: 50%;
    margin-top: 0px;
    float: left;
  }
  
  .pk-steps-text.left {
    position: relative;
    z-index: 12;
    padding-right: 30px;
  }
  
  .pk-steps-progress-bar {
    position: relative;
    width: 45px;
    height: 70px;
    margin-top: 0px;
  }
  
  .step_progress-bar {
    position: absolute;
    z-index: 5;
    color: #131387;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: -0.51px;
  }
  
  .step_progress-bar._2 {
    margin-top: 20px;
    padding-left: 35px;
    color: #8b8b9c;
  }
  
  .text-block-22 {
    position: relative;
    top: 21px;
    right: 3px;
    padding-left: 34px;
    color: rgba(29, 27, 74, 0.4);
    font-size: 20px;
    line-height: 40px;
    letter-spacing: -0.51px;
  }
  
  .step_progress-bar_div {
    position: absolute;
    left: -8px;
    top: 30px;
    width: 54px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(29, 27, 74, 0.2);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .steps_description {
    color: rgba(17, 15, 53, 0.7);
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
  }
  
  .audioplayer_wrapper {
    width: auto;
    height: 65px;
    max-width: 400px;
    margin-top: 40px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .div-block-82 {
    display: block;
    height: 65px;
    margin-top: 0px;
    padding-top: 22px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .div-block-82.intro {
    padding-top: 0px;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
  }
  
  .div-block-82.intro:hover {
    opacity: 0.85;
  }
  
  .div-block-83 {
    display: inline-block;
    height: auto;
    margin-right: 20px;
    margin-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: right;
  }
  
  .div-block-83._2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .text-block-25 {
    margin-left: 0px;
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: -0.19px;
  }
  
  .text-block-25.intro {
    text-align: left;
  }
  
  .pk-progress-time {
    color: #8b8b9c;
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: -0.19px;
  }
  
  .pk-steps-s2-copy {
    margin-top: 45px;
    margin-bottom: 35px;
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .pk-steps-s2-container {
    display: block;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .title_wrapper-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  
  .pk-steps-s2-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 35px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .pk-steps-s2-left {
    display: block;
    width: 45%;
  }
  
  .pk-steps-right-blue {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 391px;
    height: auto;
    padding: 19px 19px 19px 25px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 3px;
    background-color: #dcdcea;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
    color: #8b8b9c;
  }
  
  .pk-steps-right-blue.large {
    width: 100%;
    height: 100%;
    padding: 30px;
    border: 1px solid #dcdcea;
    background-color: #fafafa;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.04);
  }
  
  .note-header {
    color: #1d1b4a;
    font-size: 15px;
    line-height: 23px;
    font-weight: 700;
  }
  
  .div-block-84 {
    padding: 35px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
    color: #8a8a9e;
  }
  
  .text-block-29 {
    margin-top: 26px;
    margin-left: 15px;
    color: #212173;
    font-size: 20px;
    line-height: 19px;
    font-weight: 600;
  }
  
  .div-block-85 {
    margin-top: 15px;
  }
  
  .unordered-list {
    padding-left: 30px;
    color: #8b8b9c;
    font-size: 16px;
    line-height: 27px;
    text-align: left;
  }
  
  .unordered-list.bootcamp {
    margin-top: 20px;
  }
  
  .unordered-list.getstarted {
    margin-top: 15px;
  }
  
  .unordered-list.pricing {
    width: 100%;
    padding-left: 0px;
    list-style-type: none;
  }
  
  .pk-steps-s2-wrapper-middle {
    margin-right: 26px;
    margin-left: 26px;
    padding: 35px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
    color: #8a8a9e;
  }
  
  .steps_bottom_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 70px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .steps_bottom_row-help {
    margin-top: 50px;
  }
  
  .div-block-86 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 470px;
    height: 92px;
    margin-right: auto;
    margin-left: auto;
    padding: 16px 25px 16px 16px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(138, 138, 158, 0.3);
    border-radius: 5px;
  }
  
  .image-52 {
    margin: 0px 20px 0px 10px;
    padding-top: 0px;
  }
  
  .text-block-30 {
    display: inline-block;
    color: #131387;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
  }
  
  .div-block-87 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .text-block-31 {
    color: #131387;
    font-size: 14px;
    line-height: 16px;
  }
  
  .pk-modal-wrapper {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 99;
    display: block;
    overflow: scroll;
    height: 100%;
    padding-top: 45px;
    border-radius: 2px;
    background-color: rgba(17, 15, 53, 0.74);
    text-align: left;
  }
  
  .pk-modal-wrapper.hide {
    display: none;
  }
  
  .pk-modal-wrapper.contact {
    display: none;
    overflow: hidden;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .pk-structure-wrapper {
    display: block;
    overflow: hidden;
    width: 776px;
    height: 1396px;
    margin-right: auto;
    margin-left: auto;
    padding: 0px 25px 25px;
    border-radius: 2px;
    background-color: #f7f7f7;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
  }
  
  .pk-structure-wrapper.blog {
    display: -ms-grid;
    display: grid;
    width: 100%;
    height: auto;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    background-color: transparent;
    box-shadow: none;
  }
  
  .pk-structure-wrapper.blog._2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  
  .pk-structure-wrapper.contact {
    position: relative;
    z-index: 9001;
    width: 100%;
    height: auto;
    max-width: 700px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .text-block-32 {
    color: #110f35;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.25px;
  }
  
  .pk-modal-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
    margin-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .modal-close-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }
  
  .pk_structure {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0px;
    padding: 25px 35px 25px 23px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border: 1px solid #dcdcea;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(7, 7, 7, 0.03);
  }
  
  .pk_structures-img {
    display: inline;
  }
  
  .pk_structures-img.small {
    width: auto;
    max-width: 60px;
    margin-right: 10px;
  }
  
  .pk_structures-copy {
    display: inline-block;
    margin-left: 16px;
  }
  
  .pk_structures-title {
    margin-bottom: 5px;
    color: #110f35;
    font-size: 16px;
    font-weight: 700;
  }
  
  .pk_structures-text {
    margin-top: 10px;
    color: #616e8e;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.17px;
  }
  
  .div-block-100 {
    padding-right: 92px;
  }
  
  .div-block-101 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  
  .pk-cong-s1-orange-left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .pk-cong-s1-orange-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .article_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: color 200ms ease, color 200ms ease;
    transition: color 200ms ease, color 200ms ease;
    color: #110f35;
    text-decoration: none;
  }
  
  .article_wrapper:hover {
    color: #47478b;
  }
  
  .article_wrapper.first {
    margin-top: 10px;
  }
  
  .article_text {
    display: inline-block;
    max-width: none;
    margin-left: 23px;
  }
  
  .article-label {
    margin-bottom: 5px;
  }
  
  .button {
    position: relative;
    z-index: 10;
    display: inline-block;
    height: 55px;
    padding-right: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    background-color: #ef5b09;
    box-shadow: 0 6px 7px 0 rgba(34, 16, 16, 0.08);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-size: 16px;
    line-height: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
  }
  
  .button:hover {
    background-color: #f77933;
    box-shadow: 0 8px 10px 0 rgba(34, 16, 16, 0.09);
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
  }
  
  .button.intro {
    padding-top: 10px;
  }
  
  .button.white {
    width: 280px;
    padding-bottom: 11px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #fff;
    box-shadow: 2px 0 7px 0 rgba(34, 16, 16, 0.08);
    color: #212173;
    text-align: center;
  }
  
  .button.white:hover {
    box-shadow: 0 8px 14px 0 rgba(34, 16, 16, 0.09);
  }
  
  .button.step {
    width: 240px;
    text-align: center;
  }
  
  .button.sticky {
    margin-left: 20px;
  }
  
  .button.margintop30 {
    margin-top: 30px;
  }
  
  .button.top40 {
    display: inline-block;
    margin-top: 40px;
  }
  
  .button.outline-circle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    height: 50px;
    padding: 0px;
    border: 2px solid #ef5b09;
    border-radius: 50%;
    background-color: transparent;
  }
  
  .button.outline-circle:hover {
    background-color: #ef5b09;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  
  .button.form-button {
    margin-top: 30px;
  }
  
  .button.hero_secondary {
    margin-right: 15px;
  }
  
  .button.btn-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    min-height: 55px;
    padding-top: 8px;
    padding-right: 25px;
    padding-left: 25px;
    line-height: 21px;
  }
  
  .button.btn-icon.secondary {
    border: 2px solid #ef5b09;
    background-color: transparent;
    color: #ef5b09;
  }
  
  .button.btn-icon.secondary:hover {
    background-color: #ef5b09;
    color: #fff;
  }
  
  .button.navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 45px;
    min-width: 200px;
    margin-left: 20px;
    padding: 0px 15px 1px;
    font-size: 14px;
  }
  
  .button.outline {
    border: 1px solid #ef5b09;
    background-color: transparent;
    color: #ef5b09;
  }
  
  .button.outline.navbar:hover {
    background-color: #ef5b09;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
  }
  
  .button.outline.navbar.w--current {
    color: #fff;
  }
  
  .playlist_thumbnail {
    width: 150px;
    height: 150px;
    border-radius: 5px;
  }
  
  .button-2 {
    padding: 0px;
    border-bottom: 2px solid #ef5b09;
    background-color: transparent;
    color: #ef5b09;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
  }
  
  .input_form {
    height: 60px;
    margin-top: 0px;
    padding-bottom: 12px;
    padding-left: 0px;
    border-style: none none solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(138, 138, 158, 0.85);
    border-radius: 0px;
    background-color: transparent;
    -webkit-transition: border-color 200ms ease, color 200ms ease;
    transition: border-color 200ms ease, color 200ms ease;
    color: #a6a6bb;
    font-size: 16px;
  }
  
  .input_form:active {
    border-bottom-color: #110f35;
    color: #110f35;
  }
  
  .input_form:focus {
    border-bottom-color: #111b35;
    color: #110f35;
  }
  
  .input_form::-webkit-input-placeholder {
    color: #aaaabe;
    font-size: 16px;
    line-height: 23px;
  }
  
  .input_form:-ms-input-placeholder {
    color: #aaaabe;
    font-size: 16px;
    line-height: 23px;
  }
  
  .input_form::-ms-input-placeholder {
    color: #aaaabe;
    font-size: 16px;
    line-height: 23px;
  }
  
  .input_form::placeholder {
    color: #aaaabe;
    font-size: 16px;
    line-height: 23px;
  }
  
  .input_form.message {
    height: 150px;
    margin-bottom: 22px;
    padding-bottom: 80px;
  }
  
  .input_form.message.blog {
    height: 140px;
    margin-bottom: 0px;
  }
  
  .input_form.message.contactmodal {
    height: 110px;
    margin-bottom: 0px;
  }
  
  .input_form.contact {
    margin-top: 0px;
  }
  
  .input_form.contact.message-form {
    height: 100%;
  }
  
  .input_form.contact.message-form.slideshop {
    height: 130px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  
  .input_form._1row {
    min-width: 350px;
    margin-bottom: 0px;
  }
  
  .input_form._1row.ondark:active {
    border-bottom-color: #fff;
    color: #fff;
  }
  
  .input_form._1row.ondark:focus {
    border-bottom-color: #fff;
    color: #fff;
  }
  
  .input_form._1row.ondark::-webkit-input-placeholder {
    color: #8b8b9c;
  }
  
  .input_form._1row.ondark:-ms-input-placeholder {
    color: #8b8b9c;
  }
  
  .input_form._1row.ondark::-ms-input-placeholder {
    color: #8b8b9c;
  }
  
  .input_form._1row.ondark::placeholder {
    color: #8b8b9c;
  }
  
  .input_form._1row.ondark.tips {
    margin-bottom: 0px;
  }
  
  .input_form._1row.home {
    margin-top: 20px;
  }
  
  .input_form.textarea {
    padding-top: 0px;
    padding-left: 0px;
  }
  
  .getstarted_content-right {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .getstarted_content-left {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .title_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 45px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .title_wrapper._50 {
    width: 50%;
  }
  
  .title_wrapper._50._10marginbottom {
    width: auto;
    max-width: 60%;
  }
  
  .title_wrapper._50._10marginbottom.slidesfox {
    margin-bottom: 30px;
  }
  
  .title_wrapper._70 {
    width: 70%;
  }
  
  .title_wrapper._70.congrat {
    position: relative;
    z-index: 5;
    width: 100%;
    padding-right: 80px;
  }
  
  .title_wrapper.numbered {
    margin-bottom: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .title_wrapper.numbered.steps {
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
  }
  
  .title_wrapper._45 {
    width: 45%;
  }
  
  .title_wrapper._45.ebooks {
    position: relative;
    z-index: 25;
  }
  
  .title_wrapper.label {
    margin-bottom: 20px;
  }
  
  .title_wrapper.center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  
  .title_wrapper.faq {
    margin-bottom: 15px;
    background-color: transparent;
  }
  
  .title_wrapper._0marginbottom {
    margin-bottom: 0px;
  }
  
  .title_wrapper.small {
    margin-bottom: 25px;
  }
  
  .title_wrapper.blog_subpage-hero {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  
  .title_wrapper._20bottommargin {
    margin-bottom: 20px;
  }
  
  .title_wrapper.congrat {
    margin-top: 0px;
    margin-bottom: 15px;
    padding-right: 100px;
  }
  
  .title_wrapper.phase {
    max-width: 650px;
    margin-bottom: 0px;
  }
  
  .title_wrapper.phase.orangebox {
    margin-bottom: 25px;
  }
  
  .title_wrapper.phase.orangebox.article {
    color: #212173;
  }
  
  .title_wrapper.blog-form_title {
    margin-bottom: 20px;
    padding-right: 70px;
  }
  
  .title_wrapper.blog-steps {
    padding-right: 0%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    text-align: left;
  }
  
  .title_wrapper.article {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }
  
  .title_wrapper.article._20marginbottom {
    margin-bottom: 20px;
  }
  
  .title_wrapper.workshop {
    width: 59%;
  }
  
  .title_wrapper.modal {
    margin-bottom: 0px;
  }
  
  .pk-intro-s2-left-text {
    width: 80%;
    margin-left: 15px;
  }
  
  .h3 {
    margin-top: 0px;
    font-family: sofia-pro, sans-serif;
    color: #110f35;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: -0.75px;
  }
  
  .instruction-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 35px;
    margin-bottom: 0px;
    margin-left: 68px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  
  .instruction-wrapper._3 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .tip_wrapper {
    width: 540px;
    height: 100%;
    margin-top: 0px;
    margin-right: 0px;
    padding: 20px 24px 24px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(138, 138, 158, 0.28);
    border-radius: 5px;
    text-align: left;
  }
  
  .tip_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .pk-steps-audioplayer-intro {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 65px;
    margin-top: 25px;
    padding-bottom: 3px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(17, 15, 53, 0.15);
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.05);
    color: #212173;
    text-align: left;
    text-decoration: none;
  }
  
  .getstarted_animated-image {
    overflow: visible;
    width: 440px;
    max-width: none;
    margin-top: -50px;
  }
  
  .getstarted-image-3 {
    width: 330px;
    margin-right: 50px;
  }
  
  .getstarted-img_wrapper {
    overflow: hidden;
    margin-top: -38px;
  }
  
  .pk-cta-bottom {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 230;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 90px;
    margin-right: auto;
    margin-left: auto;
    padding: 0px 69px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 -3px 8px -2px rgba(0, 0, 0, 0.07);
  }
  
  .image-63 {
    position: static;
    left: 110px;
    top: 50px;
    z-index: 6;
  }
  
  .div-block-142 {
    position: absolute;
    left: 210px;
    top: 15px;
  }
  
  .div-block-143 {
    position: absolute;
    right: 200px;
  }
  
  .div-block-144 {
    position: absolute;
    top: 52px;
    right: 120px;
    z-index: 5;
  }
  
  .div-block-145 {
    position: absolute;
    left: -5px;
    bottom: 95px;
    z-index: 6;
  }
  
  .image-64 {
    position: absolute;
    left: 60px;
    bottom: 45px;
  }
  
  .image-65 {
    position: absolute;
    left: 50px;
    bottom: 65px;
  }
  
  .image-66 {
    position: absolute;
    left: 36px;
    bottom: 95px;
  }
  
  .div-block-146 {
    position: absolute;
    right: 0px;
    bottom: -50px;
  }
  
  .div-block-147 {
    position: absolute;
    left: 110px;
    top: 50px;
    z-index: 5;
  }
  
  .nav-menu-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .hero_content {
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    width: 100%;
    height: 460px;
    max-height: 100%;
    max-width: none;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .hero_content.privacy {
    height: auto;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .hero_content.bootcamp {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .hero_content.home2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .hero_content.congrat {
    height: auto;
    margin-top: 20px;
    margin-bottom: 130px;
  }
  
  .hero_content.blog {
    overflow: visible;
  }
  
  .hero_content.getstarted {
    height: 550px;
  }
  
  .hero_content.buyslides {
    max-width: none;
  }
  
  .hero_content.steps {
    height: 100%;
  }
  
  .clients_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .label-wrapper {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    float: none;
    clear: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .label-wrapper.tip {
    margin-left: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .pk-step-anim {
    position: absolute;
    left: 81px;
    top: 27px;
    width: 460px;
    border-radius: 10px;
  }
  
  .pk-step-anim.bg {
    border-radius: 10px;
    background-color: #fff;
  }
  
  .div-block-162 {
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .div-block-163 {
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .steps_instructions-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .pk-steps-icon {
    width: 74px;
    height: 75px;
    margin-bottom: 15px;
    border: 2px none #93bcd2;
    box-shadow: none;
  }
  
  .pk-steps-icon.label-dem {
    background-image: url('/images/landing/info.svg');
    background-position: 50% 50%;
    background-size: 30px;
    background-repeat: no-repeat;
  }
  
  .pk-steps-icon.label-work {
    background-image: url('/images/landing/think_1.svg');
    background-size: 30px;
  }
  
  .pk-steps-icon.label-goals {
    background-image: url('/images/landing/goal.svg');
    background-size: auto 30px;
  }
  
  .pk-steps-icon.label-think {
    background-image: url('/images/landing/think.svg');
    background-size: 30px;
  }
  
  .pk-steps-icon.label-feel {
    background-image: url('/images/landing/feel.svg');
    background-size: 30px;
  }
  
  .pk-steps-icon.label-do {
    background-image: url('/images/landing/do.svg');
    background-size: 25px;
  }
  
  .pk-steps-icon.image {
    width: 100%;
    height: auto;
    max-height: 40px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  
  .pkbs-nav-menz-steps {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .pk-nav-steps {
    padding-right: 25px;
    padding-left: 25px;
    color: #848484;
    font-size: 16px;
  }
  
  .pk-nav-steps:hover {
    color: #ef5b09;
  }
  
  .pk-nav-steps.w--current {
    color: #ef5b09;
  }
  
  .pk-steps-s2-container-5 {
    display: block;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .pk_text-block {
    margin-bottom: 30px;
  }
  
  .pk_text-block.pk_highlighted {
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .pk_text-block.pk_highlighted.end {
    margin-bottom: 0px;
    color: #110f35;
  }
  
  .pk_text-block.end {
    margin-bottom: 0px;
  }
  
  .pk_text-block._50marginbottom {
    margin-bottom: 50px;
  }
  
  .pk_quote-text {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
  
  .pk_quote-text.rightalign {
    color: #93bcd2;
    text-align: right;
  }
  
  .pk_quote-text.large {
    font-size: 34px;
    line-height: 40px;
    font-weight: 500;
  }
  
  .pk-ordered-list {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #110f35;
  }
  
  .pk-ordered-list.end {
    margin-bottom: 0px;
    padding-bottom: 0px;
    list-style-type: none;
  }
  
  .pk-ordered-list.article {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .pk-ordered-list.article._50marginbottom {
    margin-bottom: 50px;
  }
  
  .pk-list-row {
    margin-bottom: 22px;
    padding-left: 15px;
    font-size: 18px;
  }
  
  .pk-list-row.checklist {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .pk-list-row.sign-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 45px;
    padding-left: 0px;
  }
  
  .pk-list-row.sign-wrapper.end {
    margin-bottom: 0px;
  }
  
  .pk-text-block_2col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .pk-right-col {
    margin-right: 55px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .pk-left-col {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .form-contact {
    width: 100%;
    color: #8a8a9e;
  }
  
  .form-contact.agency {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .time-icon {
    margin-right: 12px;
  }
  
  .pk-intro-cta-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .pk-clients-item {
    width: 16.66666667%;
    margin-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .large-number_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 54px;
    height: 54px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 27px;
    background-color: #93bcd2;
  }
  
  .large-number_wrapper.phase {
    margin-top: 11px;
    margin-right: 25px;
    margin-bottom: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #93bcd2;
  }
  
  .large-number_wrapper.congrat {
    margin-right: 5px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  
  .text-block-51 {
    display: inline-block;
    margin-top: -4px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.54px;
  }
  
  .div-block-174 {
    height: 42px;
  }
  
  .feature_image {
    display: block;
    width: auto;
    max-width: 220px;
    margin-right: auto;
    margin-bottom: 15px;
    margin-left: auto;
  }
  
  .feature_image.large {
    width: auto;
    max-height: 320px;
    max-width: 100%;
    padding-bottom: 25px;
  }
  
  .feature_image.large.workshop {
    max-width: 270px;
    padding-bottom: 0px;
  }
  
  .feature_image.tiny {
    width: auto;
    max-width: 60px;
  }
  
  .feature_image.tiny.faq {
    width: 60px;
    height: 60px;
    margin-right: 15px;
    margin-bottom: 0px;
  }
  
  .feature_image.tiny.inactive.blog {
    width: 25px;
    height: 25px;
  }
  
  .feature_image.medium {
    width: auto;
    max-width: 170px;
  }
  
  .feature_image.medium.steps-under {
    margin-bottom: 70px;
  }
  
  .feature_image.small {
    width: 120px;
  }
  
  .feature_image.small.list {
    width: auto;
    height: auto;
    max-width: 120px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 10px;
  }
  
  .feature_image.ebook {
    width: auto;
    max-width: 460px;
    margin-right: 0px;
    margin-left: 0px;
    box-shadow: 0 17px 20px -5px rgba(0, 0, 0, 0.1);
  }
  
  .feature_image.phase {
    width: auto;
    max-height: 160px;
    max-width: 170px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .feature_image.tinyx {
    width: 35px;
    margin-top: 6px;
    margin-right: 10px;
    margin-bottom: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .feature_image.highligted {
    max-width: 200px;
  }
  
  .feature_image.workshop {
    margin-right: 0px;
    margin-left: 0px;
  }
  
  .feature_image.title_block {
    max-width: 160px;
    margin-right: 30px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .pk-video-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 105px;
    height: 105px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    background-image: url('/images/landing/video-thumbnail-copy.png'), -webkit-gradient(linear, left top, left bottom, from(#e8f4fa), to(#e8f4fa));
    background-image: url('/images/landing/video-thumbnail-copy.png'), linear-gradient(180deg, #e8f4fa, #e8f4fa);
    background-position: 50% 50%, 0px 0px;
    background-size: cover, auto;
    background-repeat: no-repeat, repeat;
    box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.5);
  }
  
  .pk-steps-s2 {
    height: 640px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: #cfe8de;
    font-weight: 600;
  }
  
  .pk-steps-s3 {
    height: 640px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: #fcdece;
    font-weight: 600;
  }
  
  .pk-steps-s4 {
    height: 640px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: #dcdcea;
    font-weight: 600;
  }
  
  .pk-steps-s5 {
    height: 640px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: #bad6e4;
    font-weight: 600;
  }
  
  .div-block-175 {
    display: block;
  }
  
  .pk_structures-grid {
    display: -ms-grid;
    display: grid;
    margin-top: 15px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto minmax(auto, 1fr) minmax(auto, 1fr);
    grid-template-rows: auto auto minmax(auto, 1fr) minmax(auto, 1fr);
  }
  
  .pk_structures_wrapper {
    padding-top: 10px;
  }
  
  .image-75 {
    position: relative;
    left: 452px;
    top: -229px;
    right: 0px;
  }
  
  .image-76 {
    position: relative;
    left: 347px;
    top: -280px;
  }
  
  .image-77 {
    position: relative;
    left: 197px;
    top: -320px;
    right: 0px;
  }
  
  .image-78 {
    position: relative;
    left: 70px;
    top: -307px;
  }
  
  .image-79 {
    position: relative;
    left: -100px;
    top: -278px;
  }
  
  .image-80 {
    position: relative;
    left: -200px;
    top: -193px;
  }
  
  .navbar-sticky {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    border-bottom: 1px none #0c0b1c;
    background-color: #110f35;
    box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.1);
  }
  
  .section_content {
    background-color: #fff;
  }
  
  .section {
    position: relative;
    padding-top: 80px;
    padding-bottom: 90px;
    background-color: #fff;
  }
  
  .section.lightgreybg {
    background-color: #fafafa;
  }
  
  .section.bordergrey {
    border-top: 1px solid #dcdcea;
    border-bottom: 1px solid #dcdcea;
  }
  
  .section.spotify_player {
    padding-top: 0px;
  }
  
  .section.footer {
    padding-top: 60px;
    background-color: #110f35;
  }
  
  .section.darkbg {
    background-color: #140e41;
  }
  
  .section.darkbg.portfolio {
    position: relative;
    overflow: hidden;
    height: 750px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .section.darkbg.portfolio.travelguide {
    height: 720px;
    background-color: #dcdcea;
  }
  
  .section.darkbg.portfolio.conference {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 80px;
    padding-bottom: 110px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url('/images/landing/conference_bg.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .section.darkbg.portfolio.workshop {
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  
  .section.darkbg.testimonial {
    display: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  
  .section.darkbg.testimonial.hide {
    display: none;
  }
  
  .section.followus.blog {
    border: 1px none #dcdcea;
    background-color: #fafafa;
  }
  
  .section._0bottommargin {
    padding-bottom: 0px;
  }
  
  .section.hide {
    display: none;
  }
  
  .section.share-block {
    position: relative;
    z-index: 10;
    height: 80px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .section.blog_image-block {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .section.bluebg {
    background-color: #cde1eb;
  }
  
  .section.bluebg._0bottommargin.bootcamp_books {
    overflow: hidden;
    background-color: #e8f4fa;
  }
  
  .section.backtolist {
    margin-bottom: 80px;
    padding-top: 20px;
    padding-bottom: 0px;
  }
  
  .section.blog-process {
    padding-bottom: 0px;
  }
  
  .section.bottom-section {
    padding-bottom: 40px;
  }
  
  .play-button-img {
    margin-right: 20px;
  }
  
  .link-underline {
    height: 2px;
    background-color: #ef5b09;
  }
  
  .faq_info-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 87%;
    margin-top: 15px;
    padding-bottom: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .faq_info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .pk-footer-social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    background-color: #1f165a;
    opacity: 0.6;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
  }
  
  .pk-footer-social:hover {
    opacity: 1;
  }
  
  .pk-footer-social.end {
    margin-right: 0px;
  }
  
  .pk-footer-social.followus {
    width: 60px;
    height: 60px;
    border: 1px solid #d2d2da;
    background-color: transparent;
  }
  
  .pk-footer-social.followus:hover {
    background-color: #fff;
  }
  
  .pk-footer-social.hide {
    display: none;
  }
  
  .pk-steps-instructions-titlerow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .pk-steps-iconmenu {
    width: 75px;
    height: 75px;
    margin-bottom: 15px;
    border: 2px solid #93bcd2;
    border-radius: 50px;
    background-color: #fff;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png');
    background-position: 50% 50%;
    background-size: 30px;
    background-repeat: no-repeat;
    box-shadow: 7px 7px 10px 0 rgba(0, 0, 0, 0.03);
  }
  
  .pk-cong-social {
    margin-left: 20px;
    padding: 5px;
    opacity: 0.8;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  
  .pk-cong-social:hover {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
  
  .getstarted_icon {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }
  
  .navbar-logo_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
  
  .checkbox {
    display: block;
    width: 25px;
    height: 25px;
    margin-top: 0px;
    margin-right: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    color: #fff;
    font-size: 16px;
    line-height: 27px;
  }
  
  .checkbox.team-assesment-card {
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .checkbox-label {
    margin-bottom: 0px;
    margin-left: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .checkbox-label.team-assessment-card {
    width: 100%;
    margin-left: 0px;
  }
  
  .checkbox_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    margin-right: 20px;
    margin-bottom: 19px;
    padding: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(166, 166, 187, 0.2);
    -webkit-transition: border-color 200ms ease;
    transition: border-color 200ms ease;
    color: #fff;
    font-size: 16px;
  }
  
  .checkbox_content:hover {
    border-color: #a6a6bb;
  }
  
  .checkbox_content.end {
    margin-right: 0px;
  }
  
  .checkbox_content.legal {
    margin-right: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    border-style: none;
  }
  
  .checkbox_content.team-checkbox {
    margin-right: 0px;
    margin-bottom: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .interests {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .interests.workshop {
    margin-top: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
  
  .package_img {
    position: relative;
    z-index: 0;
    width: 420px;
  }
  
  .package_img._1 {
    position: relative;
    left: 10px;
    z-index: 4;
    width: 420px;
    margin-right: -50px;
  }
  
  .package_img._1.workshop {
    left: 20px;
    top: 2px;
  }
  
  .package_img._3 {
    position: relative;
    right: 10px;
    z-index: 15;
    margin-left: -70px;
  }
  
  .package_img._3.workshop {
    right: 21px;
  }
  
  .package_img.order {
    width: auto;
    max-width: 390px;
  }
  
  .article-text {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
  
  .article-text:hover {
    color: #47478b;
  }
  
  .hero_buyslides-bgimg {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    display: block;
    overflow: visible;
    height: 100%;
    max-height: 750px;
  }
  
  .article_img {
    width: 140px;
    height: 130px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 3px;
  }
  
  .article_img._1 {
    background-image: url('/images/landing/pk-cong-s2-3.png');
    background-position: 50% 50%;
    background-size: cover;
  }
  
  .article_img._2 {
    background-image: url('/images/landing/pk-cong-s2.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .subscribe-form_wrapper {
    width: 100%;
    margin-top: 10px;
  }
  
  .subscribe-form_wrapper.blog {
    margin-top: 40px;
  }
  
  .cong_buyslides-promo-button {
    display: block;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    text-align: center;
  }
  
  .cong_buyslides-promo-img {
    position: relative;
    z-index: 0;
    display: block;
    width: 310px;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    min-width: auto;
    margin: 10px auto 25px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .about_feature-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 60px 50px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .about_feature-wrapper.bootcamp {
    padding: 13px 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .about_feature-wrapper.bootcamp._2 {
    margin-top: 90px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .about_feature-wrapper.conference {
    padding: 13px 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .about_feature-wrapper.conference._2 {
    margin-top: 90px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .about_feature-img {
    margin-bottom: 20px;
  }
  
  .about_feature-img.bootcamp {
    width: 64px;
    margin-right: 10px;
  }
  
  .about_feature-img.conference {
    width: 20px;
    margin-top: -10px;
    margin-right: 13px;
    margin-bottom: 0px;
  }
  
  .about_feature-img.conference._2 {
    margin-top: 0px;
  }
  
  .h2 {
    font-family: sofia-pro, sans-serif;
    color: #110f35;
    font-size: 28px;
    line-height: 34px;
    font-weight: 700;
    letter-spacing: -0.35px;
  }
  
  .h2.steps {
    font-size: 38px;
    line-height: 40px;
    letter-spacing: -0.95px;
  }
  
  .pk-h5 {
    color: #a6a6bb;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0px;
    text-transform: uppercase;
  }
  
  .pk-h5.alignleft {
    text-align: left;
  }
  
  .orange-box_blocks-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 18px;
    margin-right: 20px;
    margin-bottom: 22px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .orange-box_img {
    width: 80px;
    height: auto;
    margin-right: 15px;
  }
  
  .orange-box_img._1 {
    width: 45px;
  }
  
  .buyslides_gradient {
    position: absolute;
    top: 0px;
    z-index: 3;
    width: 100%;
    height: 100%;
    max-height: 750px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 15, 53, 0.93)), to(rgba(14, 12, 45, 0.75)));
    background-image: linear-gradient(180deg, rgba(17, 15, 53, 0.93), rgba(14, 12, 45, 0.75));
  }
  
  .note-copy_wrapper {
    padding-right: 7%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  .note-copy_wrapper.large {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  .form-header {
    padding-right: 140px;
  }
  
  .faq_col {
    padding-right: 5%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .faq_block {
    margin-bottom: 45px;
  }
  
  .blog_post {
    display: block;
    margin-bottom: 40px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-decoration: none;
  }
  
  .blog_post.highlighted {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 430px;
    margin-bottom: 50px;
    padding: 45px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-radius: 3px;
    background-image: url('/images/landing/pk_blog-articles_assets_blog-cover-01.png');
    background-position: 50% 50%;
    background-size: 1109px;
    background-repeat: no-repeat;
    box-shadow: 0 0 0 0 transparent;
    opacity: 1;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  
  .blog_post.highlighted:hover {
    box-shadow: 0 25px 30px -10px rgba(0, 0, 0, 0.05);
  }
  
  .blog-post_img {
    width: 100%;
    height: 230px;
    border-radius: 4px;
    background-color: transparent;
  }
  
  .blog-post_img.blog_thumb-1 {
    background-image: url('/images/landing/pk_blog-articles_assets_blog-cover-02.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .blog-post_img.blog_thumb-2 {
    background-image: url('/images/landing/pk_blog-articles_assets_blog-cover-10.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .blog-post_img.blog_thumb-7 {
    background-image: url('/images/landing/pk_blog-articles_assets_blog-cover-08.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .blog-post_img.blog_thumb-4 {
    background-image: url('/images/landing/pk_blog-articles_assets_blog-cover-11.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .blog-post_img.blog_thumb-3 {
    background-image: url('/images/landing/pk_blog-articles_assets_blog-cover-13.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .blog-post_img.blog_thumb-5 {
    background-image: url('/images/landing/pk_blog-articles_assets_blog-cover-05.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .blog-post_img.blog_thumb-6 {
    background-image: url('/images/landing/pk_blog-articles_assets_blog-cover-12.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .blog_title {
    margin-bottom: 10px;
    padding-right: 10%;
    color: #110f35;
  }
  
  .blog_title.highlighted {
    position: static;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    width: 70%;
    padding-right: 0%;
    color: #fff;
  }
  
  .feature_top-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 290px;
    margin-bottom: 30px;
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .feature_top-wrapper.feature1 {
    background-color: #cde1eb;
  }
  
  .feature_top-wrapper.feature2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: #fcdece;
  }
  
  .feature_top-wrapper.feature3 {
    background-color: #cfe8de;
  }
  
  .feature_top-wrapper.bootcamp-package-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 210px;
    margin-bottom: 35px;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  
  .feature_top-wrapper.bootcamp-package-top._1 {
    background-color: #cde1eb;
  }
  
  .feature_top-wrapper.bootcamp-package-top._2 {
    background-color: #fcdece;
  }
  
  .feature_top-wrapper.bootcamp-package-top._3 {
    background-color: #cfe8de;
  }
  
  .feature_top-wrapper.bootcamp-package-top._4 {
    background-color: #140e41;
    color: #fff;
  }
  
  .feature_top-wrapper.bootcamp-package-top.nopadding {
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .feature_top-wrapper.bootcamp-package-top.nopadding.bundle {
    margin-bottom: 0px;
  }
  
  .feature_top-wrapper.support {
    padding-top: 35px;
  }
  
  .bootcamp_feature-text {
    width: 80%;
  }
  
  .bootcamp_feature-text.bootcamp-package {
    width: 100%;
    padding-right: 25px;
    padding-left: 25px;
    color: #110f35;
    text-align: left;
  }
  
  .bootcamp_feature-text.support {
    width: 100%;
    margin-top: 30px;
    padding-right: 10%;
  }
  
  .list-item {
    padding-left: 7px;
  }
  
  .list-item.pricing {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #212173;
  }
  
  .list-item.pricing.highlighted {
    padding-top: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #bad6e4;
    color: #212173;
  }
  
  .list-item.pricing.highlighted.end {
    border-bottom-style: none;
  }
  
  .bootcamp-feature_img {
    width: auto;
    max-width: 240px;
    margin-top: -10px;
  }
  
  .bootcamp_callout {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 82%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .bootcamp_about-img {
    position: static;
    bottom: -50px;
    width: 300px;
    height: 300px;
    margin-top: 30px;
    border-radius: 350px;
    background-color: #e6f1f6;
  }
  
  .bootcamp_about-img._1 {
    background-image: url('/images/landing/bootcamp_about-illustration32x.png');
    background-position: 100% 0%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .bootcamp_about-img._2 {
    background-image: url('/images/landing/bootcamp_about-illustration22x.png');
    background-position: 50% 0%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .bootcamp_about-img._3 {
    background-image: url('/images/landing/bootcamp_about-illustration2x.png');
    background-position: 50% 0%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .bootcamp_about-img.conference {
    margin-top: 0px;
    margin-bottom: 30px;
    background-color: #1f165a;
  }
  
  .bootcamp_about-img.conference.margintop {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  
  .bootcamp_about-img.conference.margintop.c2 {
    background-image: url('/images/landing/icon_large_15.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .bootcamp_about-img.conference.c1 {
    background-image: url('/images/landing/icon_large_13.png');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .bootcamp_about-img.conference.c3 {
    background-image: url('/images/landing/icon_large_14.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .bootcamp_testimonial-wrapper {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .bootcamp_testimonial-author {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #8b8b9c;
  }
  
  .bootcamp_author-text {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    text-align: left;
  }
  
  .bootcamp-profile-img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    border-radius: 50px;
  }
  
  .testimonial-author-name {
    margin-top: 0px;
    margin-bottom: 5px;
    color: #fff;
  }
  
  .bootcamp_packages-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .bootcamp_image {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 20;
    height: 360px;
    margin-top: -10px;
    background-image: none;
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
  }
  
  .bootcamp_box_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(166, 166, 187, 0.2);
    font-size: 16px;
  }
  
  .bootcamp_box_wrapper.end {
    margin-right: 0px;
  }
  
  .bootcamp_box_wrapper.contact-form {
    padding: 0px;
    border-style: none;
    border-width: 0px;
  }
  
  .radiobutton_label {
    margin-left: 5px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .radiobutton_label._2row {
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .radiobutton_label.hide {
    display: none;
  }
  
  .radiobutton {
    margin-top: 0px;
    margin-right: 10px;
    margin-left: 0px;
  }
  
  .radiobutton._2row {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  
  .radiobutton.table {
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .radio-button-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 25px 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(166, 166, 187, 0.2);
    -webkit-transition: border-color 200ms ease;
    transition: border-color 200ms ease;
    font-size: 16px;
  }
  
  .radio-button-field:hover {
    border-color: #a6a6bb;
  }
  
  .radio-button-field.end {
    margin-right: 0px;
  }
  
  .radio-button-field._2row {
    margin-right: 0px;
    margin-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  
  .radio-button-field.table {
    margin-right: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    border-style: none;
    cursor: pointer;
  }
  
  .bootcamp_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .bootcamp_row.grid {
    display: -ms-grid;
    display: grid;
    margin-bottom: 35px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  
  .bootcamp_form_title {
    margin-bottom: 25px;
    padding-top: 10px;
  }
  
  .bootcamp_form_title.separator {
    margin-top: 20px;
    padding-top: 25px;
    border-top: 1px solid hsla(0, 0%, 82.4%, 0.5);
  }
  
  .bootcamp_form-contact {
    margin-bottom: 0px;
  }
  
  .bootcamp_packages-callout-wrapper {
    position: relative;
    z-index: 30;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 190px;
    margin-top: 90px;
    margin-bottom: -80px;
  }
  
  .bootcamp_callout-package {
    position: relative;
    left: 0px;
    z-index: 15;
    width: 100%;
    max-width: none;
    min-width: 300px;
    padding-right: 23px;
    padding-left: 23px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    color: #111b35;
    text-align: center;
  }
  
  .bootcamp_callout-package._2 {
    left: 0px;
    bottom: 30px;
    height: 100%;
  }
  
  .bootcamp_callout-package._3 {
    top: 21px;
    bottom: 20px;
  }
  
  .callout-line {
    margin-top: 20px;
  }
  
  .bootcamp_heroimg_1 {
    position: absolute;
    left: 130px;
    top: -40px;
    width: 330px;
  }
  
  .bootcamp_heroimg_2 {
    position: absolute;
    top: 300px;
    z-index: 10;
    width: 280px;
  }
  
  .bootcamp_heroimg_3 {
    position: absolute;
    left: 410px;
    top: 350px;
    z-index: 15;
    width: 110px;
  }
  
  .bootcamp_heroimg_4 {
    position: absolute;
    top: 180px;
    z-index: 20;
    width: 40px;
  }
  
  .bootcamp_heroimg_5 {
    position: absolute;
    left: 240px;
    top: 254px;
    z-index: 20;
    width: 30px;
  }
  
  .bootcamp_heroimg_6 {
    position: absolute;
    left: 490px;
    top: 80px;
    width: 23px;
  }
  
  .pk-navlink_dropdown {
    position: relative;
    z-index: 9999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    margin-right: 0px;
    padding-right: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
  }
  
  .pk-navlink_icon-arrow {
    width: 12px;
    margin-left: 10px;
  }
  
  .navbar_dropdown-wrapper {
    position: absolute;
    left: -130px;
    top: 60px;
    z-index: 100;
    display: none;
    width: auto;
    height: auto;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06);
  }
  
  .navbar_dropdown-wrapper.small {
    left: -52px;
    display: none;
    width: auto;
  }
  
  .pk-navbar_dropdown-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 370px;
    height: auto;
    margin: 10px 10px 0px;
    padding: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: color 200ms ease, background-color 200ms ease;
    transition: color 200ms ease, background-color 200ms ease;
    color: #8b8b9c;
    font-weight: 500;
    text-decoration: none;
  }
  
  .pk-navbar_dropdown-row:hover {
    border-radius: 3px;
    background-color: #e6f1f6;
    color: #110f35;
  }
  
  .pk-navbar_dropdown-row.w--current {
    color: #ef5b09;
  }
  
  .pk-navbar_dropdown-row._2 {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  
  .pk-navbar_dropdown-row._2:hover {
    color: #110f35;
  }
  
  .pk-navbar_dropdown-row._2.small {
    color: #8b8b9c;
  }
  
  .pk-navbar_dropdown-row._2.small:hover {
    color: #110f35;
  }
  
  .pk-navbar_dropdown-row.small {
    width: auto;
    min-width: 190px;
  }
  
  .pk-navbar_dropdown-row.small:hover {
    background-color: rgba(232, 244, 250, 0.7);
    color: #110f35;
  }
  
  .pk-navbar_dropdown-row.small.w--current {
    color: #ef5b09;
  }
  
  .pk-navbar_dropdown-row.small.w--current:hover {
    color: #110f35;
  }
  
  .pk-navbar_dropdown-header {
    margin-top: 0px;
    margin-bottom: 5px;
    color: #140e41;
  }
  
  .pk-navbar_dropdown-img {
    width: 40px;
    margin-right: 20px;
  }
  
  .checkbox-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    margin-bottom: 0px;
    padding-right: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  
  .checkbox-field.legal {
    margin-top: 12px;
  }
  
  .checkbox-field.topalign {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .checkbox-field.team-assessment-card {
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .checkbox-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .checkbox-wrapper.home {
    margin-top: 30px;
  }
  
  .checkbox-wrapper.request {
    display: block;
    margin-top: 30px;
  }
  
  .checkbox-wrapper.blog-form {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  
  .checkbox-wrapper.modal {
    margin-top: 10px;
  }
  
  .checkbox-icon {
    width: 40px;
    height: 40px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  .checkbox-icon.align-top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  
  .contact-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .contact-col._1 {
    width: 40%;
    margin-right: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .hero_image {
    position: static;
    top: 14px;
    z-index: 10;
    width: 100%;
    height: auto;
    min-width: 550px;
    opacity: 1;
  }
  
  .hero_image.home {
    position: relative;
    left: -40px;
    top: 0px;
    height: 100%;
    min-width: 550px;
  }
  
  .hero_image.mobile {
    display: none;
  }
  
  .hero_image.getstarted {
    min-width: 570px;
  }
  
  .hero_image.our-solution {
    height: 300px;
    min-width: 510px;
  }
  
  .hero_image.bootcamp {
    position: relative;
    top: 0px;
    height: 510px;
  }
  
  .hero_image.aboutus {
    position: relative;
    left: 50px;
    top: 0px;
    height: 420px;
    max-width: 510px;
    min-width: 0px;
  }
  
  .hero_image.faqimage {
    position: relative;
    left: 0px;
    top: 0px;
    max-width: 520px;
    min-width: 0px;
  }
  
  .hero_image.congrat {
    position: static;
    width: auto;
    min-width: auto;
  }
  
  .hero_image.step {
    position: relative;
    width: 50%;
    min-width: 510px;
  }
  
  .hero_image.step.tab_content {
    margin-bottom: 35px;
  }
  
  .hero_image.tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    margin-left: 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  
  .hero_image.travel-guide_heroimg {
    position: relative;
    width: 570px;
    height: 500px;
    min-width: 400px;
  }
  
  .hero_image.showsevents-heroimg {
    position: relative;
    width: 570px;
    height: 430px;
    min-width: 400px;
  }
  
  .hero_image.showsevents-heroimg {
    position: relative;
    top: 0px;
    width: 570px;
    height: 482px;
    min-width: 400px;
  }
  
  .heroimg_home2-element-1 {
    position: absolute;
    left: 147px;
    top: 335px;
    z-index: 20;
    width: 23px;
  }
  
  .heroimg_home2-element-2 {
    position: absolute;
    left: 36px;
    top: 35px;
    z-index: 10;
    width: 35px;
  }
  
  .heroimg_home2-element-3 {
    position: absolute;
    left: 59px;
    top: 54px;
    z-index: 0;
    width: 249px;
  }
  
  .heroimg_home2-element-4 {
    position: absolute;
    left: 27px;
    top: 103px;
    z-index: 10;
    width: 144px;
  }
  
  .heroimg_home2-element-5 {
    position: absolute;
    left: 179px;
    top: 176px;
    z-index: 10;
    width: 206px;
  }
  
  .heroimg_home2-element-6 {
    position: absolute;
    left: 187px;
    top: 222px;
    z-index: 15;
    width: 169px;
  }
  
  .heroimg_home2-element-7 {
    position: absolute;
    top: 56px;
    right: 39px;
    width: 20px;
  }
  
  .heroimg_home2-element-8 {
    position: absolute;
    top: 28px;
    right: 119px;
    z-index: 5;
    width: 125px;
  }
  
  .heroimg_home2-element-9 {
    position: absolute;
    top: 88px;
    right: 38px;
    z-index: 15;
    width: 160px;
  }
  
  .heroimg_home2-element-10 {
    position: absolute;
    left: 372px;
    top: 178px;
    right: 170px;
    z-index: 20;
    width: 32px;
  }
  
  .color-block {
    height: 70px;
    margin-top: 0px;
    border-radius: 4px;
    background-color: #ebebeb;
  }
  
  .color-block.cc-brand-color-1 {
    background-color: #ef5b09;
  }
  
  .color-block.cc-brand-color-2 {
    background-color: #212173;
  }
  
  .color-block.cc-shade-2 {
    background-color: #f9bf9f;
  }
  
  .color-block.cc-secondary-4 {
    background-color: #bad6e4;
  }
  
  .color-block.cc-secondary-5 {
    background-color: #cde1eb;
  }
  
  .color-block.cc-shade-4 {
    background-color: #dcdcea;
  }
  
  .color-block.cc-secondary-2 {
    background-color: #1f165a;
  }
  
  .color-block.cc-shade-3 {
    background-color: #fcdece;
  }
  
  .color-block.cc-shade-1 {
    background-color: #e8f4fa;
  }
  
  .color-block.cc-shade-6 {
    background-color: #cfe8de;
  }
  
  .color-block.cc-shade-5 {
    background-color: #d2d2da;
  }
  
  .color-block.cc-secondary-1 {
    background-color: #110f35;
  }
  
  .color-block.cc-secondary-3 {
    background-color: #47478b;
  }
  
  .color-block.cc-brand-color-3 {
    background-color: #93bcd2;
  }
  
  .color-block.cc-secondary-6 {
    background-color: #8b8b9c;
  }
  
  .style-guide-section {
    position: relative;
    margin: 0px 0px 20px;
    padding: 0px 30px 0px 0px;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .style-guide_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .style-guide-content {
    padding-top: 40px;
  }
  
  .style-guide-content.image-tiny {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .style-guide-label {
    margin-top: 10px;
    margin-bottom: 40px;
    color: #aaa;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .style-guide_cell {
    margin-right: 20px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .style-guide_cell.style-guide_button {
    margin-bottom: 10px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .style-guide-title {
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 0px;
    color: #8b8b9c;
    font-size: 16px;
    line-height: 44px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .style-guide-title.sub {
    opacity: 0.5;
    font-size: 15px;
    letter-spacing: 0px;
    text-transform: none;
  }
  
  .container-6 {
    position: relative;
    width: 100%;
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .style-guide-divider {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: #f3f3f5;
  }
  
  .style-guide_main {
    display: block;
  }
  
  .components {
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #d2d2da;
  }
  
  .whitetext {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
  }
  
  .whitetext.orangelabel {
    margin-bottom: 10px;
  }
  
  .whitetext.done {
    color: #ef5b09;
  }
  
  .whitetext.next {
    color: #8b8b9c;
  }
  
  .icon_social {
    width: 20px;
    height: 20px;
  }
  
  .section_hero {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 600px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #110f35;
  }
  
  .section_hero.getstarted {
    height: 690px;
  }
  
  .section_hero.buyslides {
    display: block;
  }
  
  .section_hero.congrat {
    height: 100%;
  }
  
  .section_hero.privacy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 350px;
    padding-top: 0px;
  }
  
  .section_hero.steps {
    height: 100%;
    padding-bottom: 80px;
  }
  
  .section_hero.steps._1 {
    background-color: #bad6e4;
  }
  
  .section_hero.steps._2 {
    background-color: #cfe8de;
  }
  
  .section_hero.steps._3 {
    background-color: #fcdece;
  }
  
  .section_hero.steps._4 {
    background-color: #dcdcea;
  }
  
  .section_hero.steps._5 {
    background-color: #bad6e4;
  }
  
  .section_hero.steps._6 {
    background-color: #f9bf9f;
  }
  
  .section_hero.steps._7 {
    background-color: #d2d2da;
  }
  
  .pk_container {
    position: relative;
    width: 90%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .pk_container.article {
    position: relative;
    max-width: 690px;
  }
  
  .pk_container.navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 90px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .content_wrapper {
    width: 100%;
  }
  
  .content_wrapper.box {
    margin-right: auto;
    margin-left: auto;
    padding: 38px 50px 45px;
    border: 1px solid #dcdcea;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.03);
  }
  
  .content_wrapper.box.getstarted {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .content_wrapper.box.getstarted.middle {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
  
  .content_wrapper.box.getstarted._3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .content_wrapper.box.followus {
    background-color: #fafafa;
  }
  
  .content_wrapper.box.followus.blog {
    padding: 0px;
    border-style: none;
    background-color: transparent;
    box-shadow: none;
  }
  
  .content_wrapper.box.blog-form {
    height: 400px;
    margin-right: 0px;
    margin-left: 0px;
    padding: 30px;
  }
  
  .content_wrapper._2col_block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .content_wrapper._2col_block.conference_skills {
    margin-top: 60px;
  }
  
  .content_wrapper.divider {
    margin-top: 45px;
  }
  
  .content_wrapper.divider.small {
    margin-top: 20px;
  }
  
  .content_wrapper.faq_subscribe {
    width: 100%;
    margin-top: 30px;
  }
  
  .content_wrapper.portfolio {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .content_wrapper.box {
    margin-right: auto;
    margin-left: auto;
    padding: 38px 50px 45px;
    border: 1px solid #dcdcea;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.03);
  }
  
  .content_wrapper.box.followus {
    background-color: #fafafa;
  }
  
  .content_wrapper.box.followus.request {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .content_wrapper.qoute {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  
  .content_wrapper.ebooks {
    position: relative;
    overflow: visible;
  }
  
  .content_wrapper.grid {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 39px;
    grid-row-gap: 16px;
    grid-template-areas: ".";
    -ms-grid-columns: 1fr 39px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: minmax(auto, 0.25fr) 16px auto 16px auto;
    grid-template-rows: minmax(auto, 0.25fr) auto auto;
  }
  
  .follow-us_wrapper {
    width: 100%;
  }
  
  .follow-us_wrapper.blog-form-wrapper {
    width: auto;
  }
  
  .signup-1row_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .signup-1row_wrapper.smaller {
    width: 100%;
  }
  
  .signup-1row_wrapper.large {
    width: 740px;
  }
  
  .signup-1row_wrapper.large.blog-form-row {
    width: 100%;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .footer_col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #d2d2da;
  }
  
  .footer_navigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .footer_social-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .footer_social-wrapper.followus {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  
  .footer_logo {
    width: 230px;
  }
  
  .footer_logo.slidesfox {
    width: 150px;
  }
  
  .navbar_sticky {
    position: fixed;
    top: 0px;
    z-index: 9999;
    width: 100%;
    background-color: #110f35;
  }
  
  .getstarted_text-col {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .getstarted_text-col._1 {
    margin-right: 30px;
  }
  
  .getstarted_text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .landing-title {
    padding-right: 100px;
  }
  
  .landing-title.our-solution {
    padding-right: 0px;
  }
  
  .landing-title.blog {
    padding-right: 30px;
  }
  
  .landing-title.faqtitle {
    padding-right: 0px;
  }
  
  .landing-title.bootcamp {
    padding-right: 0px;
  }
  
  .landing-title.aboutus {
    padding-right: 0px;
  }
  
  .landing-title.workshop {
    padding-right: 20px;
  }
  
  .title_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .title_row.followus {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .title_row.request {
    margin-bottom: 30px;
    color: #110f35;
  }
  
  .content_col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .content_col.text_aboutus {
    padding-right: 0px;
  }
  
  .content_col.faq_col {
    padding-right: 15px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  
  .content_col.order-block {
    width: 46%;
    padding-right: 60px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .content_col.marginleft {
    margin-left: 20px;
  }
  
  .content_col.right {
    margin-left: 5%;
  }
  
  .content_col.right.request {
    padding-right: 0px;
  }
  
  .content_col.request {
    width: 45%;
    padding-right: 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .content_col.hero_image {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .content_col.hero_image.congrat {
    width: auto;
  }
  
  .content_col.imagearticle {
    width: 40%;
    margin-right: 30px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .content_col.first {
    width: 40%;
    min-width: 410px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .content_col.blog {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .content_col.blog.left {
    width: 60%;
  }
  
  .content_col.orangebox {
    width: 860px;
    padding-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .content_col.nosize {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .content_col.nosize.horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .content_col.image-block_article {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .footer-label {
    margin-bottom: 10px;
    color: #d2d2da;
  }
  
  .button-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .faq_top-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: transparent;
  }
  
  .faq_top-icon {
    width: 24px;
    height: 24px;
    margin-top: 4px;
    margin-left: 90px;
  }
  
  .faq_block-content {
    overflow: hidden;
  }
  
  .image_orange {
    width: 40px;
    height: 40px;
    margin-right: 29px;
  }
  
  ._10marginbottom {
    margin-bottom: 10px;
  }
  
  .order-label {
    margin-bottom: 10px;
    color: #93bcd2;
  }
  
  .tab_link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 23px;
    margin-left: 0%;
    padding: 20px 29px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 2px solid #fcdece;
    border-radius: 2px;
    background-color: transparent;
    -webkit-transition: background-color 200ms ease, color 200ms ease;
    transition: background-color 200ms ease, color 200ms ease;
    color: #ef5b09;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1.75px;
    text-transform: uppercase;
  }
  
  .tab_link:hover {
    color: #f07734;
  }
  
  .tab_link.w--current {
    border-style: none;
    background-color: #ef5b09;
    color: #fff;
  }
  
  .tab_link.w--current:hover {
    background-color: #f07734;
  }
  
  .tabs_menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 72%;
    margin: 10px auto 30px 0px;
  }
  
  .tab_text {
    letter-spacing: 2px;
  }
  
  .portfolio-description-2 {
    margin-top: 28px;
    font-family: sofia-pro, sans-serif;
    color: #cecee0;
    font-size: 20px;
    line-height: 1.5;
  }
  
  .sv_button-2 {
    display: block;
    height: 55px;
    padding-top: 17px;
    padding-right: 40px;
    padding-left: 40px;
    border: 2px solid #302d7f;
    border-radius: 2px;
    background-color: transparent;
    -webkit-transition: background-color 300ms ease, color 300ms ease;
    transition: background-color 300ms ease, color 300ms ease;
    font-family: sofia-pro, sans-serif;
    color: #302d7f;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  .sv_button-2:hover {
    background-color: #302d7f;
    color: #fff;
  }
  
  .sv_button-2.small {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    padding: 0px 25px 2px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-family: sofia-pro, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
  }
  
  .sv_button-2.small:hover {
    color: #fff;
  }
  
  .sv_button-2.small.portfoliobtn {
    display: none;
    width: 55%;
    margin-top: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .body-6 {
    color: #110f35;
  }
  
  .body-6.highlight {
    color: #212173;
    font-size: 18px;
    line-height: 24px;
  }
  
  .body-6.highlight.section_subtitle {
    margin-top: 15px;
    color: #8b8b9c;
  }
  
  .body-6.feature-subtext {
    color: #8b8b9c;
  }
  
  .body-6.highlight {
    color: #110f35;
    font-size: 18px;
    line-height: 25px;
  }
  
  .body-6.highlight.section_subtitle {
    margin-top: 15px;
    color: #8b8b9c;
  }
  
  .body-6.feature-subtext {
    color: #8b8b9c;
    line-height: 23px;
  }
  
  .body-6.feature-subtext.left-align {
    padding-right: 0px;
    padding-left: 0px;
    text-align: left;
  }
  
  .blog-label {
    margin-bottom: 8px;
    color: hsla(0, 0%, 100%, 0.75);
    font-size: 13px;
    font-weight: 500;
  }
  
  .blog-label.subpage {
    margin-bottom: 20px;
    color: #8b8b9c;
  }
  
  .blog-label.list {
    color: #8b8b9c;
  }
  
  .blog-label.highlighted {
    margin-bottom: 15px;
    color: #212173;
  }
  
  .blog_subage-heroimage {
    width: auto;
    height: auto;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .bootcamp {
    margin-bottom: 15px;
  }
  
  .nav-link_wrapper {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .nav-link_wrapper.dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .nav_menu_desktop2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .nav-bar_bg {
    position: fixed;
    display: none;
    width: 100%;
    height: 125px;
    background-color: #bacbd6;
  }
  
  .nav-bar {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    margin-top: -1px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: transparent;
  }
  
  .navbar-mobile-bg {
    display: none;
  }
  
  .logo {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 150px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 24px;
    font-weight: 700;
  }
  
  .logo.w--current {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
  .menu-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    height: 160px;
    margin-right: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .menu-wrapper.links {
    margin-right: 45px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .structure-menu-items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .structure-project-menu {
    position: relative;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: transparent;
  }
  
  ._3-col {
    display: inline-block;
    width: 220px;
    margin-right: 20px;
  }
  
  .wrap {
    display: block;
    width: 940px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .wrap._w-custom.structure-menu-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 35px;
    padding-bottom: 35px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .wrap._w-custom.structure-menu-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 35px;
    padding-bottom: 35px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .nav_inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .nav_position {
    max-width: 1100px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  /* .nav-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-width: 110px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .nav-item.nav-logo {
    padding-left: 0px;
  } */
  
  .nav-dropdown_toggle {
    padding-right: 20px;
    padding-left: 20px;
  }
  
  .header {
    background-color: #110f35;
  }
  
  .dropdown-list {
    opacity: 0;
  }
  
  .dropdown-list.w--open {
    left: -50%;
    right: -50%;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
  
  .buy-cta_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: left;
  }
  
  .buy-cta_content.pricing {
    margin-bottom: 35px;
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .price_wrapper {
    margin-top: 35px;
    text-align: left;
  }
  
  .author_block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 80%;
    margin-top: 45px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .author_block.workshop {
    display: -ms-grid;
    display: grid;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 20%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 16px;
    -ms-grid-columns: 0.25fr 1fr;
    grid-template-columns: 0.25fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  
  .column.author {
    margin-right: 32px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  
  .image_travel-guide_preview {
    display: none;
  }
  
  .button-4 {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 55px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    background-color: #ef5b09;
    box-shadow: 0 6px 7px 0 rgba(34, 16, 16, 0.08);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-size: 16px;
    line-height: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
  }
  
  .button-4:hover {
    background-color: #f77933;
    box-shadow: 0 8px 10px 0 rgba(34, 16, 16, 0.09);
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
  }
  
  .button-4.step {
    width: 240px;
    text-align: center;
  }
  
  .button-link-2 {
    display: inline-block;
    width: auto;
    color: #ef5b09;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    text-decoration: none;
  }
  
  .darktext {
    color: #110f35;
  }
  
  .title_wrapper-row-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    height: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-style: none;
    border-width: 2px;
    border-color: #93bcd2;
  }
  
  .blog-post_img-wrapper {
    overflow: hidden;
    width: 100%;
    height: 230px;
    margin-bottom: 20px;
  }
  
  .highlight-shape {
    display: inline-block;
    margin-bottom: 15px;
    padding: 10px 15px 15px 18px;
    border-radius: 5px;
    background-color: #fff;
    color: #110f35;
  }
  
  .orange-box_img-mobile {
    display: none;
  }
  
  .address_block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 25px;
  }
  
  .address_block.last {
    margin-bottom: 0px;
  }
  
  .smartvizual-logo {
    width: 200px;
    margin-bottom: 10px;
  }
  
  .address_block-icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
  
  .box_header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .box_header.secondary {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  
  .box_image {
    width: auto;
    max-width: 50px;
    margin-right: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  
  .box_header-text {
    text-align: left;
  }
  
  .box_content-wrapper {
    text-align: left;
  }
  
  .skill_label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 7px;
    margin-bottom: 7px;
    padding: 10px 12px 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 5px;
    background-color: #dcdcea;
    text-align: center;
  }
  
  .skill_label._2 {
    background-color: #fcdece;
  }
  
  .skill_label._3 {
    background-color: #d2d2da;
  }
  
  .skill_label._4 {
    background-color: #cfe8de;
  }
  
  .skill_label._5 {
    background-color: #bad6e4;
  }
  
  .skill_label._7 {
    background-color: #cde1eb;
  }
  
  .skill_label._8 {
    background-color: #d2d2da;
  }
  
  .skill_label._9 {
    background-color: rgba(71, 71, 139, 0.43);
  }
  
  .skill_label._10 {
    background-color: rgba(147, 188, 210, 0.84);
  }
  
  .skill_label._11 {
    background-color: rgba(239, 91, 9, 0.6);
  }
  
  .skill-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .conference_slider {
    width: 100%;
    height: auto;
    margin-top: 70px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
  }
  
  .conf_slider_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding: 0px 100px 0px 85px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .conf_slider_content.workshop {
    width: 100%;
    padding-right: 100px;
    padding-left: 100px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .conf_slider_text {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .conf_slider_text.workshop {
    width: 80%;
  }
  
  .conf_slider_img {
    width: 620px;
    height: auto;
    margin-right: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 5px;
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.05);
  }
  
  .conf_slider_img.hide {
    display: none;
  }
  
  .conf_slider_img.videobg {
    height: 326px;
  }
  
  .conf_slider_img.image {
    width: 100%;
    height: auto;
    margin-right: 0px;
    margin-bottom: 40px;
    border-radius: 0px;
    box-shadow: none;
    opacity: 1;
  }
  
  .conf_slider_img.image.hover {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
  }
  
  .sllide-nav {
    display: none;
  }
  
  .slider-icon {
    position: absolute;
    width: 25px;
    height: 25px;
  }
  
  .slider-icon.left {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .slider-icon.white {
    z-index: 10;
  }
  
  .slider-icon_bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 2px;
    padding-left: 2px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0.7;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
  }
  
  .slider-icon_bar:hover {
    opacity: 1;
  }
  
  .slider-icon_bar.right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  
  .payment-logos_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .payment-logo {
    width: 45px;
    margin-top: 25px;
    margin-right: 17px;
    opacity: 0.51;
  }
  
  .payment-logo.paypal {
    width: 120px;
    margin-top: 28px;
  }
  
  .html-embed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .image-81 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  
  .pk-list_sign-img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
  
  .list_sign-headline {
    margin-bottom: 12px;
    padding-top: 5px;
    font-size: 21px;
  }
  
  .phase_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-right: 0px;
    margin-bottom: 60px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .phase_row._0marginbottom {
    margin-bottom: 0px;
  }
  
  .phase-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #d2d2da;
  }
  
  .hero_image-tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-right: 10px;
    padding: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(20, 14, 65, 0.2);
    border-radius: 3px;
    background-color: transparent;
    color: #110f35;
  }
  
  .hero_image-tab.w--current {
    border-color: #ef5b09;
    background-color: transparent;
    color: #ef5b09;
  }
  
  .hero_image-tab.second {
    margin-right: 0px;
    margin-left: 10px;
  }
  
  .hero_image-tab.note {
    margin-top: 30px;
    margin-right: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-style: none;
  }
  
  .hero_image_tab-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .hero_image-tab_caption {
    margin-bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
    color: rgba(20, 14, 65, 0.5);
    text-align: center;
  }
  
  .hero_image-tab_img {
    width: 35px;
    height: 35px;
    margin-right: 15px;
  }
  
  .box_wrapper-2 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    padding: 35px 30px 45px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid #dcdcea;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.04);
    text-align: center;
  }
  
  .box_wrapper-2.middle {
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .signup-1-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  
  .blog_backtolist-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  
  .backtolist_img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .link_seealltips {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .pk-steps-right-blue-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 391px;
    height: auto;
    padding: 19px 19px 19px 25px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 3px;
    background-color: #dcdcea;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
    color: #8b8b9c;
  }
  
  .section-2 {
    position: relative;
    padding-top: 80px;
    padding-bottom: 90px;
    background-color: #fff;
  }
  
  .section-2.bordergrey {
    border-top: 1px solid #dcdcea;
    border-bottom: 1px solid #dcdcea;
  }
  
  .section-2.footer {
    padding-top: 60px;
    background-color: #110f35;
  }
  
  .box_wrapper-3 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    padding: 35px 30px 45px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid #dcdcea;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.04);
    text-align: center;
  }
  
  .box_wrapper-3.list {
    margin-right: 0px;
    margin-bottom: 15px;
    padding: 25px 60px 28px 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .row._2column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .congrat_nextstepimage {
    max-width: 250px;
    margin-right: 20px;
  }
  
  .congrat_nextstep-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  
  .orangebox_img-compact {
    width: auto;
    max-width: 280px;
  }
  
  .article_highlighted-image {
    width: auto;
    height: auto;
    max-width: 100%;
  }
  
  .price-counterimg_wrapper {
    position: relative;
    left: 220px;
    top: -90px;
  }
  
  .price_tag {
    position: relative;
    left: 109px;
    top: 82px;
    z-index: 10;
    max-width: 170px;
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
  }
  
  .travel-guide_mobile {
    display: none;
  }
  
  .hero_btn-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
  }
  
  .btn_icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  
  ._20maringbottom {
    margin-bottom: 20px;
  }
  
  .checkbox-grid {
    display: -ms-grid;
    display: grid;
    width: 100%;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  
  .new_label {
    display: inline-block;
    margin-bottom: 10px;
    padding: 7px 16px;
    border-radius: 3px;
    background-color: #ef5b09;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
  }
  
  .pricing_checkmark-icon {
    margin-right: 15px;
  }
  
  .pricing_highlight-icon {
    width: 80px;
    height: auto;
    margin-right: 20px;
    padding-top: 3px;
  }
  
  .info-icon {
    width: 25px;
    height: 25px;
  }
  
  .workshop-lottie-1 {
    position: relative;
    width: 530px;
    height: 300px;
  }
  
  .workshop-lottie-2 {
    position: relative;
    width: 460px;
    height: 260px;
  }
  
  .cta-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  
  .cta-row.center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .modal-window-close-bg {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 9000;
  }
  
  .heroimg_home2-elementfox {
    position: absolute;
    left: 375px;
    top: 370px;
    z-index: 17;
    width: 120px;
  }
  
  .slider-hover-wrapper {
    width: 100%;
    height: 100%;
  }
  
  .slider_content-wrapper {
    position: relative;
  }
  
  .aboutus-img {
    position: absolute;
    z-index: 1;
  }
  
  .aboutus-img._1 {
    left: 114px;
    top: 90px;
    width: 25px;
    height: 25px;
  }
  
  .aboutus-img._2 {
    left: 216px;
    top: 283px;
    z-index: 6;
    width: 33px;
  }
  
  .aboutus-img._3 {
    left: 170px;
    top: 60px;
    width: 205px;
  }
  
  .aboutus-img._4 {
    left: 140px;
    top: 16px;
    z-index: 5;
    width: 399px;
  }
  
  .aboutus-img._5 {
    left: 109px;
    top: 239px;
    z-index: 0;
    width: 340px;
  }
  
  .aboutus-img._6 {
    left: 262px;
    top: 40px;
    width: 10px;
    height: 12px;
  }
  
  .aboutus-img._7 {
    left: 268px;
    top: 94px;
    width: 152px;
    height: 177px;
  }
  
  .aboutus-img._8 {
    left: 323px;
    top: 50px;
    width: 17px;
    height: 17px;
  }
  
  .aboutus-img._9 {
    left: 0%;
    top: auto;
    right: auto;
    bottom: 10%;
    z-index: 3;
    width: 460px;
    height: 265px;
  }
  
  .aboutus-img._10 {
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    z-index: 0;
    width: 294px;
    height: 435px;
  }
  
  .travelguide-img {
    position: absolute;
    z-index: 1;
  }
  
  .travelguide-img._1 {
    left: 13px;
    top: 116px;
    width: 76px;
  }
  
  .travelguide-img._2 {
    left: 442px;
    top: 17px;
    z-index: 6;
    width: 42px;
    height: 44px;
  }
  
  .travelguide-img._3 {
    left: 353px;
    top: 72px;
    width: 26px;
    height: 26px;
  }
  
  .travelguide-img._4 {
    left: 436px;
    top: 85px;
    z-index: 1;
    width: 113px;
    height: 213px;
  }
  
  .travelguide-img._5 {
    left: 185px;
    top: auto;
    right: auto;
    bottom: 8.55%;
    z-index: 2;
    width: 385px;
    height: 362px;
  }
  
  .travelguide-img._6 {
    left: 6%;
    top: auto;
    right: auto;
    bottom: 9%;
    z-index: 4;
    width: 391px;
    height: 254px;
  }
  
  .travelguide-img._8 {
    left: 89px;
    top: 45px;
    width: 47px;
    height: 47px;
  }
  
  .travelguide-img._9 {
    left: 300px;
    top: auto;
    right: auto;
    bottom: 38%;
    z-index: 6;
    width: 110px;
  }
  
  .showsevents_img {
    position: absolute;
    z-index: 1;
  }
  
  .showsevents_img._1 {
    left: 13px;
    top: 266px;
    z-index: 3;
    width: 515px;
    height: 148px;
    max-width: 515px;
  }
  
  .showsevents_img._2 {
    left: 430px;
    top: 264px;
    z-index: 6;
    width: 42px;
    height: 44px;
  }
  
  .showsevents_img._3 {
    left: 169px;
    top: 219px;
    z-index: 2;
    width: 45px;
    height: 66px;
  }
  
  .showsevents_img._4 {
    left: 3px;
    top: 124px;
    z-index: 1;
    width: 50px;
    height: 37px;
  }
  
  .showsevents_img._5 {
    left: auto;
    top: 5px;
    right: 62px;
    bottom: auto;
    z-index: 2;
    width: 18px;
    height: 18px;
  }
  
  .showsevents_img._7 {
    left: 5px;
    top: 38px;
    z-index: 0;
    width: 542px;
    height: 230px;
    max-width: 542px;
  }
  
  .workshop_img {
    position: absolute;
    z-index: 1;
  }
  
  .workshop_img._1 {
    left: auto;
    top: 50px;
    right: 42px;
    bottom: auto;
    z-index: 1;
    width: 177px;
    height: 412px;
    max-width: 515px;
  }
  
  .workshop_img._2 {
    left: 44px;
    top: 97px;
    right: auto;
    bottom: auto;
    z-index: 2;
    width: 120px;
    height: 162px;
  }
  
  .workshop_img._3 {
    left: 9px;
    top: 121px;
    z-index: 2;
    width: 442px;
    height: 389px;
  }
  
  .workshop_img._4 {
    left: 232px;
    top: 14px;
    z-index: 6;
    width: 125px;
    height: 119px;
  }
  
  .content_block.hide {
    display: none;
  }
  
  .table-row {
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }
  
  .table-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .table-cell.table-header {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .table-cell.text {
    padding-right: 40px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  
  .table-cell.text-area {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .table-header-row {
    display: -ms-grid;
    display: grid;
    margin-bottom: 20px;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 3fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  
  .table-row_form {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 3fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  
  .table-row_form.text {
    -ms-grid-columns: 0.55fr 0.75fr;
    grid-template-columns: 0.55fr 0.75fr;
  }
  
  .table-row_form.cards-row {
    margin-top: 18px;
  }
  
  .table_wrapper {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid hsla(0, 0%, 82.4%, 0.5);
  }
  
  .table_wrapper.text {
    margin-bottom: 60px;
  }
  
  .table_wrapper.end {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom-style: none;
  }
  
  .textarea {
    min-height: 150px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 20px;
    font-size: 16px;
  }
  
  .textarea::-webkit-input-placeholder {
    color: rgba(139, 139, 156, 0.6);
  }
  
  .textarea:-ms-input-placeholder {
    color: rgba(139, 139, 156, 0.6);
  }
  
  .textarea::-ms-input-placeholder {
    color: rgba(139, 139, 156, 0.6);
  }
  
  .textarea::placeholder {
    color: rgba(139, 139, 156, 0.6);
  }
  
  .mobile_header-title {
    display: none;
  }
  
  .bootcamp-package-pricetag {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .bootcamp-package-pricetag.pricebefore {
    opacity: 0.4;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    text-decoration: line-through;
  }
  
  .bootcamp-package-price {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: -18px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .bootcamp-package-price.package {
    margin-left: 0px;
  }
  
  .package-support-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
  }
  
  .button-5 {
    position: relative;
    z-index: 10;
    display: inline-block;
    height: 55px;
    padding-right: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    background-color: #ef5b09;
    box-shadow: 0 6px 7px 0 rgba(34, 16, 16, 0.08);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #fff;
    font-size: 16px;
    line-height: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
  }
  
  .button-5:hover {
    background-color: #f77933;
    box-shadow: 0 8px 10px 0 rgba(34, 16, 16, 0.09);
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
  }
  
  .button-5.outline {
    border: 1px solid #ef5b09;
    background-color: transparent;
    color: #ef5b09;
  }
  
  .button-5.outline.navbar:hover {
    background-color: #ef5b09;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
  }
  
  .button-5.outline.navbar.w--current {
    color: #fff;
  }
  
  .button-5.outline.navbar-sf {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
  }
  
  .button-5.outline.navbar-sf:hover {
    background-color: #ef5b09;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
  }
  
  .button-5.outline.navbar-sf.w--current {
    color: #fff;
  }
  
  .button-5.navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 45px;
    min-width: 200px;
    margin-left: 20px;
    padding: 0px 15px 1px;
    font-size: 14px;
  }
  
  .button-5.hero_secondary {
    margin-right: 15px;
  }
  
  .button-5.white {
    width: 280px;
    padding-bottom: 11px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #fff;
    box-shadow: 2px 0 7px 0 rgba(34, 16, 16, 0.08);
    color: #212173;
    text-align: center;
  }
  
  .button-5.white:hover {
    box-shadow: 0 8px 14px 0 rgba(34, 16, 16, 0.09);
  }
  
  .button-5.navbar-sf {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    margin-left: 8px;
    padding: 0px 15px 1px;
    font-size: 14px;
  }
  
  .button-5.button-link.slidesfox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    padding-right: 16px;
    padding-left: 16px;
    background-color: transparent;
    color: #ef5b09;
    font-size: 14px;
  }
  
  .button-5.button-link.slidesfox:hover {
    color: #fff;
  }
  
  .label-2 {
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    float: none;
    clear: none;
    color: #8b8b9c;
    font-size: 14px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
  
  .structure-project-menu-2 {
    position: relative;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: transparent;
  }
  
  .nav-link-2 {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    color: #8b8b9c;
    font-size: 16px;
    font-weight: 400;
  }
  
  .nav-link-2:hover {
    color: #fff;
  }
  
  .nav-link-2:focus {
    color: #ef5b09;
  }
  
  .nav-link-2.w--current {
    color: #ef5b09;
  }
  
  .nav-link-2.dropdown {
    padding: 15px;
  }
  
  .nav-link-2.dropdown:hover {
    color: #1f165a;
  }
  
  .pk-navbar-2 {
    z-index: 90;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 90px;
    padding-right: 5%;
    padding-left: 5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #110f35;
  }
  
  .navbar_sticky-2 {
    position: fixed;
    top: 0px;
    z-index: 9999;
    width: 100%;
    background-color: #110f35;
  }
  
  .wrapper_hero-text-2 {
    width: 50%;
    padding-right: 0%;
    float: left;
    color: #fff;
  }
  
  .wrapper_hero-text-2._80 {
    width: 80%;
    padding-right: 0%;
  }
  
  .wrapper_hero-text-2.slidesfox {
    width: 70%;
  }
  
  .subtitle-2 {
    color: #d2d2da;
    font-size: 22px;
    line-height: 34px;
    font-weight: 400;
  }
  
  .subtitle-2.hero-subtitle {
    margin-top: 30px;
  }
  
  .subtitle-2.hero-subtitle.landing {
    padding-right: 60px;
  }
  
  .subtitle-2.hero-subtitle.slidesfox {
    width: 70%;
  }
  
  .section_hero-2 {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 600px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #110f35;
  }
  
  .box_wrapper-4 {
    position: relative;
    z-index: 200;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 20px;
    padding: 35px 30px 45px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 1px solid #dcdcea;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.04);
    text-align: center;
  }
  
  .box_wrapper-4.step {
    margin-right: 10px;
    padding-right: 20px;
    padding-left: 20px;
    border-style: none;
    background-color: transparent;
    box-shadow: none;
  }
  
  .box_wrapper-4.step.col5 {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .tab_link-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 23px;
    margin-left: 0%;
    padding: 20px 29px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 2px solid #fcdece;
    border-radius: 2px;
    background-color: transparent;
    -webkit-transition: background-color 200ms ease, color 200ms ease;
    transition: background-color 200ms ease, color 200ms ease;
    color: #ef5b09;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1.75px;
    text-transform: uppercase;
  }
  
  .tab_link-2:hover {
    color: #f07734;
  }
  
  .tab_link-2.w--current {
    border-style: none;
    background-color: #ef5b09;
    color: #fff;
  }
  
  .tab_link-2.w--current:hover {
    background-color: #f07734;
  }
  
  .body-7 {
    padding-right: 0px;
    padding-left: 0px;
    color: #110f35;
  }
  
  .body-7.highlight {
    color: #110f35;
    font-size: 18px;
    line-height: 25px;
  }
  
  .body-7.highlight.section_subtitle {
    margin-top: 15px;
    color: #8b8b9c;
  }
  
  .body-7.highlight.section_subtitle.bottom {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  
  .body-7.feature-subtext {
    padding-right: 0px;
    padding-left: 0px;
    color: #8b8b9c;
    line-height: 23px;
  }
  
  .body-7.footer-copyright {
    margin-right: 0px;
    color: #47478b;
  }
  
  .orange-box_wrapper-2 {
    position: relative;
    z-index: 0;
    display: block;
    width: 80%;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 5px 50px 50px;
    border-radius: 5px;
    background-color: #ef5b09;
    color: #fff;
  }
  
  .pk-s4-orange-icon1-2 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 250px;
    height: 95px;
    margin-right: 10px;
    padding: 20px 26px 20px 28px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 3px;
    background-color: #e05000;
    color: #fff;
  }
  
  .whitetext-2 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
  }
  
  .whitetext-2.orangelabel {
    margin-bottom: 10px;
  }
  
  .footer_col-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #d2d2da;
  }
  
  .footer-label-2 {
    margin-bottom: 10px;
    color: #d2d2da;
  }
  
  .pk-footer-social-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    background-color: #1f165a;
    opacity: 0.6;
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
  }
  
  .pk-footer-social-2:hover {
    opacity: 1;
  }
  
  .pk-footer-social-2.end {
    margin-right: 0px;
  }
  
  .pk-footer-social-2.hide {
    display: none;
  }
  
  .footer-link-2 {
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-transition: color 200ms ease;
    transition: color 200ms ease;
    color: #8b8b9c;
    font-size: 16px;
  }
  
  .footer-link-2:hover {
    color: #e6f1f6;
  }
  
  .nav-item-sf {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .nav-item-sf.nav-logo {
    padding-left: 0px;
  }
  
  .menu-btn-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 8px;
  }
  
  .pricing-placeholder {

  }
  
  .pricing-placeholder_block {
    height: 570px;
    border: 1px solid #d2d2da;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.05);
  }
  
  html.w-mod-js *[data-ix="scale-image-on-scroll"] {
    opacity: 0;
    -webkit-transform: scale(0.01, 0.01);
    -ms-transform: scale(0.01, 0.01);
    transform: scale(0.01, 0.01);
  }
  
  html.w-mod-js *[data-ix="fade"] {
    opacity: 0.04;
  }
  
  html.w-mod-js *[data-ix="fade-5"] {
    opacity: 0.04;
  }
  
  html.w-mod-js *[data-ix="move1"] {
    opacity: 0;
    -webkit-transform: translate(0px, 100px);
    -ms-transform: translate(0px, 100px);
    transform: translate(0px, 100px);
  }
  
  html.w-mod-js *[data-ix="move2"] {
    opacity: 0;
    -webkit-transform: translate(0px, 100px);
    -ms-transform: translate(0px, 100px);
    transform: translate(0px, 100px);
  }
  
  html.w-mod-js *[data-ix="rotate-182"] {
    opacity: 0;
  }
  
  html.w-mod-js *[data-ix="rotate-183"] {
    opacity: 0;
  }
  
  html.w-mod-js *[data-ix="rotate-184"] {
    opacity: 0;
  }
  
  html.w-mod-js *[data-ix="move3"] {
    opacity: 0;
    -webkit-transform: translate(0px, 100px);
    -ms-transform: translate(0px, 100px);
    transform: translate(0px, 100px);
  }
  
  html.w-mod-js *[data-ix="move4"] {
    opacity: 0;
    -webkit-transform: translate(0px, 100px);
    -ms-transform: translate(0px, 100px);
    transform: translate(0px, 100px);
  }
  
  html.w-mod-js *[data-ix="move5"] {
    opacity: 0;
    -webkit-transform: translate(0px, 100px);
    -ms-transform: translate(0px, 100px);
    transform: translate(0px, 100px);
  }
  
  html.w-mod-js *[data-ix="popup"] {
    display: none;
    opacity: 0;
    -webkit-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  
  html.w-mod-js *[data-ix="tilt"] {
    opacity: 0;
    -webkit-transform: rotate(-35deg) translate(0px, 50%);
    -ms-transform: rotate(-35deg) translate(0px, 50%);
    transform: rotate(-35deg) translate(0px, 50%);
  }
  
  html.w-mod-js *[data-ix="underline-an"] {
    width: 0px;
    height: 4px;
  }
  
  html.w-mod-js *[data-ix="stamp"] {
    opacity: 0;
    -webkit-transform: scale(5, 5);
    -ms-transform: scale(5, 5);
    transform: scale(5, 5);
  }
  
  html.w-mod-js *[data-ix="week-underline"] {
    width: 0px;
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
  
  html.w-mod-js *[data-ix="tilt-2"] {
    opacity: 0;
    -webkit-transform: rotate(-35deg) translate(0px, 50%);
    -ms-transform: rotate(-35deg) translate(0px, 50%);
    transform: rotate(-35deg) translate(0px, 50%);
  }
  
  html.w-mod-js *[data-ix="pk-wrapper-pop-up"] {
    display: none;
    opacity: 0;
    -webkit-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }
  
  @media screen and (max-width: 991px) {
    h1 {
      font-size: 55px;
      line-height: 58px;
      letter-spacing: -2px;
    }
    .label.mobile-navbar {
      position: static;
      height: auto;
      margin-top: 30px;
      margin-bottom: 13px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
    }
    .label.mobile-navbar.first {
      margin-top: 0px;
    }
    .body {
      padding-top: 0px;
    }
    .body.prisma-about {
      margin-top: -50px;
      padding-top: 0px;
    }
    .body.highlight.section_subtitle {
      padding-top: 0px;
    }
    .body.highlight.pricing {
      text-align: center;
    }
    .body.feature-subtext {
      padding-top: 0px;
    }
    .body.pricing-info {
      text-align: center;
    }
    .prisma_div {
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      display: none;
    }
    .section-clients {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .section-contactform {
      padding: 0px 40px;
    }
    .section-footer {
      padding-right: 40px;
      padding-left: 40px;
    }
    .sv-copy.contact {
      display: block;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
    .sv-copy.prisma {
      margin-bottom: -70px;
    }
    .portfolio-wrapper {
      width: 50%;
    }
    .portfolio-wrapper.presentation {
      width: 49%;
      padding-left: 40px;
    }
    .portfolio-wrapper.secondary {
      width: 100%;
      height: auto;
      padding-top: 50px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .portfolio-wrapper.secondary.portfolio {
      width: 49%;
      height: 650px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .clients-wrapper {
      height: auto;
      margin-right: 15px;
      margin-left: 15px;
    }
    .clients-item {
      width: auto;
    }
    .clients-logo {
      max-width: 90%;
    }
    .contact-container.contact2 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
    .contactform-wrapper {
      width: 50%;
      padding-right: 0px;
    }
    .contactform-illustration {
      display: none;
    }
    .contact-title {
      text-align: center;
    }
    .form-box::-webkit-input-placeholder {
      font-size: 16px;
    }
    .form-box:-ms-input-placeholder {
      font-size: 16px;
    }
    .form-box::-ms-input-placeholder {
      font-size: 16px;
    }
    .form-box::placeholder {
      font-size: 16px;
    }
    .form-box.message {
      font-size: 16px;
    }
    .form-sendbutton {
      display: block;
      margin-right: auto;
      margin-left: auto;
      font-size: 14px;
    }
    .section-about {
      padding-left: 40px;
    }
    .container {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .container.sv-portfolio-container {
      height: 1060px;
    }
    .container.features-container {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .container.prisma {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .container.contact {
      padding-right: 0px;
      padding-left: 0px;
    }
    .sv-navlink.dropdown {
      color: #9aa8cf;
    }
    .portfolio-header {
      padding-right: 0%;
    }
    .portfolio-description {
      margin-bottom: 6px;
    }
    .sv_button.small.portfoliobtn {
      width: 95%;
    }
    .sv-features_wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .features-copy {
      width: 100%;
      margin-right: 0px;
    }
    .features-icons_block.contact {
      margin-bottom: 30px;
    }
    .sv-h4.features {
      font-size: 40px;
      text-align: center;
      letter-spacing: -0.89px;
    }
    .sv-features_description {
      text-align: center;
    }
    .subtitle.hero-subtitle.landing {
      padding-right: 0px;
    }
    .featured_image {
      display: block;
      width: 298px;
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
    }
    .nav-menu {
      background-color: #090f1f;
    }
    .portfolio_illustration_wrapper {
      left: -830px;
      margin-left: 358px;
    }
    .portfolio_illustration_wrapper.buyslides_hero {
      left: 0px;
    }
    .portfolio_illustration_wrapper.secondary {
      left: 0px;
      display: none;
      margin-left: 0px;
    }
    .portfolio_illustration_wrapper.secondary.portfolio {
      display: block;
    }
    .portfolio_illustration_wrapper.secondary.workshop {
      display: block;
      height: 100%;
    }
    .portfolio-illustration_img._2 {
      left: -30%;
      top: 80%;
    }
    .portfolio-illustration_img._2.workshop-5 {
      left: -19%;
      top: 79%;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(71, 71, 139, 0.07)), to(rgba(71, 71, 139, 0.07))), url('/images/landing/quote_bg-1.jpg');
      background-image: linear-gradient(180deg, rgba(71, 71, 139, 0.07), rgba(71, 71, 139, 0.07)), url('/images/landing/quote_bg-1.jpg');
      background-position: 0px 0px, 0px 0px;
      background-size: auto, cover;
    }
    .portfolio-illustration_img.empty {
      display: none;
    }
    .portfolio-illustration_img._3 {
      left: 11%;
      top: -8%;
    }
    .portfolio-illustration_img._3.workshop-2 {
      left: 4%;
      top: auto;
      bottom: 28%;
      overflow: hidden;
      height: 250px;
    }
    .portfolio-illustration_img._5 {
      left: 56%;
    }
    .portfolio-illustration_img._5._2 {
      left: 60%;
    }
    .portfolio-illustration_img._5.workshop-1 {
      left: 39%;
      top: 28%;
    }
    .portfolio-illustration_img._6 {
      left: 53%;
    }
    .portfolio-illustration_img._6.workshop-6 {
      left: 65%;
      top: 73%;
    }
    .portfolio-illustration_img._7.workshop-4 {
      left: 30%;
      top: 62%;
    }
    .image-block {
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 30px;
      padding-right: 40px;
      padding-bottom: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .article-block_img {
      width: auto;
      max-width: 180px;
      margin-right: auto;
      margin-left: auto;
    }
    .paragraph.note {
      margin-top: 5px;
      margin-bottom: 0px;
      color: #111b35;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.3px;
    }
    .description.note {
      margin-top: 10px;
    }
    .prisma-img_mobile {
      width: 100%;
      height: 430px;
      background-image: url('/images/landing/prisma.jpg');
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .pk-hero-right {
      position: relative;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      display: block;
      overflow: visible;
      margin-top: 30px;
      margin-right: auto;
      margin-left: auto;
      -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8);
    }
    .pk-navbar {
      z-index: 50;
      padding-left: 0%;
    }
    .nav-link {
      display: block;
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      text-align: center;
    }
    .nav-link.white:hover {
      color: #fff;
    }
    .nav-link.rightside {
      margin-right: auto;
      margin-bottom: 10px;
      margin-left: 10px;
      text-align: left;
    }
    .nav-link.mobile-service {
      margin-top: 15px;
      margin-bottom: 0px;
      padding-left: 10px;
      text-align: left;
    }
    .nav-link.mobile-service:hover {
      color: #110f35;
    }
    .nav-link.header {
      padding-top: 12px;
      padding-bottom: 8px;
      color: #47478b;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
    .button-small {
      display: inline-block;
      margin-left: 0px;
    }
    .box_wrapper {
      margin-right: 0px;
      margin-bottom: 20px;
      margin-left: 0px;
      padding-right: 99px;
      padding-bottom: 55px;
      padding-left: 99px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .box_wrapper.middle {
      margin: 20px 0px;
    }
    .box_wrapper.bootcamp-feature {
      margin-bottom: 30px;
    }
    .box_wrapper.dark.end {
      margin-top: 20px;
      margin-left: 0px;
    }
    .box_wrapper.dark.congrat_progress-bar {
      padding: 20px 65px;
    }
    .box_wrapper.list.nobox.end {
      margin-bottom: 0px;
    }
    .box_wrapper.end {
      margin-bottom: 0px;
    }
    .box_wrapper.end.endrow-tablet {
      margin-bottom: 20px;
    }
    .box_wrapper.faq_block {
      padding: 42px;
    }
    .box_wrapper.orange {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .box_wrapper.step.congrat-step {
      margin-right: 0px;
      margin-bottom: 50px;
    }
    .box_wrapper.step.congrat-step.end {
      margin-bottom: 0px;
    }
    .box_wrapper.step.col5 {
      margin-bottom: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .box_wrapper.step.col4 {
      margin-bottom: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .box_wrapper.congrat {
      margin-bottom: 0px;
      padding-right: 40px;
      padding-bottom: 35px;
      padding-left: 40px;
    }
    .box_wrapper.book-col {
      max-width: 500px;
      margin-right: auto;
      margin-left: auto;
    }
    .box_wrapper.steps_columns {
      margin-bottom: 0px;
      padding-right: 50px;
      padding-left: 50px;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .box_wrapper.address {
      margin-right: 0px;
    }
    .box_wrapper.skills {
      padding-right: 40px;
      padding-left: 40px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .box_wrapper.highlighted.article {
      margin-bottom: 0px;
      padding-bottom: 35px;
    }
    .box_wrapper.article_lowprior {
      width: 100%;
      height: auto;
      min-height: auto;
      min-width: auto;
      margin-bottom: 0px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .box_wrapper.noframe {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .box_wrapper.pricing {
      width: 100%;
      max-height: none;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .box_wrapper.pricing.highlighted-pricing {
      height: auto;
    }
    .box_wrapper.title-block {
      padding-right: 0px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .box_text {
      margin-top: 15px;
    }
    .box_text.title-box {
      margin-top: 0px;
    }
    .features_wrapper {
      margin-right: auto;
      margin-left: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
    .features_wrapper.list {
      min-width: auto;
      margin-top: 20px;
    }
    .features_wrapper._20bottommargin {
      margin-bottom: 0px;
    }
    .features_wrapper.steps_grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto;
      grid-template-rows: auto auto auto;
    }
    .features_wrapper.features_grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto;
      grid-template-rows: auto auto auto;
    }
    .features_wrapper.pricing {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
    .features_wrapper.timeline {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
    .features_wrapper.timeline._4col {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
    }
    .features_wrapper.travelguide {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
    .features_wrapper._2col {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
    ._2col-block_text {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-left: 16px;
      padding: 20px 25px 0px 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .orange-box_wrapper {
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 30px;
      padding-right: 35px;
      padding-left: 35px;
    }
    .orange-box_wrapper.article {
      padding-right: 35px;
      padding-bottom: 40px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .orange-box_content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-s4-orange-icon1 {
      width: 100%;
      height: auto;
      margin-right: 0px;
      padding: 26px 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
      text-align: center;
    }
    .pk-s4-orange-icon1.gutter {
      margin-right: 15px;
    }
    .orangebox-headphone_image {
      left: 78px;
      top: -98px;
      right: 34px;
      bottom: 48px;
      display: none;
      max-width: 90%;
    }
    .footer {
      margin-left: 0px;
    }
    .footer_wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .pk-hero-left-wrapper {
      width: 100%;
      max-width: 500px;
      margin-top: 40px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-hero-left-wrapper.home {
      margin-top: 20px;
      margin-right: auto;
      margin-left: auto;
    }
    .feature-subtext {
      padding-right: 0px;
      padding-left: 0px;
    }
    .menu-button-2 {
      padding-right: 0px;
    }
    .pk_logo {
      display: none;
      margin-left: 30px;
    }
    .pk_logo.w--current {
      display: none;
    }
    .section-image {
      display: block;
      width: auto;
      height: auto;
      max-height: 240px;
      max-width: 100%;
      margin-right: 0px;
      margin-bottom: 15px;
    }
    .section-image.buyslides {
      max-width: 100%;
    }
    .section-image.travelguide.price {
      left: 100px;
    }
    .contact_text {
      display: block;
      margin-right: auto;
      margin-left: auto;
      padding-right: 0px;
    }
    .contact_text.form {
      margin-top: 0px;
    }
    .wrapper_hero-text {
      position: relative;
      z-index: 10;
      display: block;
      width: 550px;
      max-width: none;
      padding-right: 0px;
      padding-left: 0px;
      color: #fff;
      text-align: center;
    }
    .wrapper_hero-text.buyslides {
      padding-right: 0px;
      padding-left: 0px;
    }
    .wrapper_hero-text.blog {
      max-width: 100%;
      padding-right: 0px;
      padding-left: 0px;
    }
    .wrapper_hero-text.bootcamp {
      width: 90%;
      padding-right: 0%;
    }
    .wrapper_hero-text.congrat {
      width: 70%;
    }
    .wrapper_hero-text.steps {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      max-width: 100%;
      padding-right: 0%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .wrapper_hero-text._100 {
      margin-right: 0px;
    }
    .wrapper_hero-text.audience {
      padding-right: 0%;
    }
    .pkf-s1-image {
      display: block;
      width: auto;
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
      float: none;
    }
    .pkf-s1-image.home {
      position: relative;
      top: -30px;
      height: 80%;
    }
    .image-39 {
      left: 0px;
      max-width: none;
    }
    .pkf-s2-text-container {
      width: 100%;
      padding-right: 105px;
      padding-left: 105px;
      text-align: center;
    }
    .pkf-s2-text-container.steps {
      width: 110%;
      padding-left: 0px;
      text-align: left;
    }
    .pkf-s2-text-container.steps._4 {
      width: 130%;
    }
    .about_features {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .arrow {
      left: 0px;
      top: 100%;
      right: 0px;
      width: 80px;
      margin-right: auto;
      margin-left: auto;
      -webkit-transform: perspective(914px) rotate(90deg);
      transform: perspective(914px) rotate(90deg);
    }
    .arrow._2 {
      left: 0px;
      top: -17px;
    }
    .arrow.steps-small {
      left: auto;
      top: 94%;
    }
    .arrow.steps-small.congrat {
      top: 128%;
      width: 33px;
    }
    .arrow.steps-small.blog {
      left: auto;
      top: 150%;
      width: 33px;
    }
    .arrow.long {
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .arrow.long.col4 {
      display: none;
    }
    .arrow.long.col5 {
      display: none;
    }
    .pkf-s4-wrapper-textblock {
      margin-top: -11px;
    }
    .pkf-s4-wrapper-textblock.bootcamp {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .pkf-s4-wrapper-textblock.conference {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .pkb-submit-button.bkps {
      border-left-width: 2px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    .icon-2 {
      color: #f6f9fc;
    }
    .icon-2.dark {
      color: #212173;
    }
    .pk-navbar-white-blog {
      padding-right: 20px;
      padding-left: 20px;
    }
    .pk-navbar-white-blog._1 {
      padding-right: 20px;
      padding-left: 20px;
    }
    .pkbs-form2 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pkbs-text-field.menu {
      margin-bottom: 9px;
    }
    .pkbs-nav-menz {
      padding-right: 10px;
      padding-left: 10px;
      background-color: #110f35;
    }
    .pk-steps-s1 {
      height: 620px;
    }
    .pk-steps-s1-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 20px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-steps-image {
      width: 100%;
      max-width: 310px;
      margin-top: 0px;
      margin-left: -17px;
    }
    .pk-s1 {
      width: 90%;
      max-width: none;
    }
    .pk-steps-text {
      position: relative;
    }
    .title_wrapper-row {
      margin-bottom: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-steps-s2-left {
      width: auto;
    }
    .pk-steps-right-blue {
      width: 100%;
      height: auto;
      margin-left: 0px;
      padding-right: 19px;
      padding-bottom: 24px;
    }
    .div-block-84 {
      padding-right: 25px;
      padding-left: 25px;
    }
    .text-block-29 {
      margin-left: 0px;
    }
    .pk-steps-s2-wrapper-middle {
      margin-right: 16px;
      margin-left: 16px;
      padding-right: 25px;
      padding-left: 25px;
    }
    .steps_bottom_row {
      margin-top: 50px;
    }
    .pk-modal-wrapper {
      display: none;
    }
    .pk-structure-wrapper.contact {
      overflow: hidden;
      width: 90%;
      max-width: 700px;
    }
    .pk-modal-header {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .pk_structure {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .pk-cong-s1-orange-left {
      margin-bottom: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .article_wrapper.end {
      margin-bottom: 0px;
    }
    .button.white {
      width: 50%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .button.outline-circle {
      -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
      transform: scale(0.75);
    }
    .button.outline-circle:hover {
      -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
      transform: scale(0.75);
    }
    .button.hero_secondary {
      margin-left: 15px;
    }
    .button.btn-icon.secondary {
      padding-right: 15px;
      padding-left: 15px;
    }
    .button.navbar.mobile {
      height: 55px;
      margin-top: 20px;
    }
    .input_form::-webkit-input-placeholder {
      font-size: 16px;
    }
    .input_form:-ms-input-placeholder {
      font-size: 16px;
    }
    .input_form::-ms-input-placeholder {
      font-size: 16px;
    }
    .input_form::placeholder {
      font-size: 16px;
    }
    .input_form.message {
      margin-bottom: 20px;
      font-size: 16px;
    }
    .input_form.message.blog {
      height: 170px;
    }
    .input_form.contact.message-form {
      height: 170px;
    }
    .input_form._1row {
      margin-right: 0px;
    }
    .input_form._1row.ondark.tips {
      margin-right: 0px;
      padding-right: 0px;
    }
    .input_form._1row.followus {
      margin-right: 0px;
    }
    .input_form.textarea {
      min-height: 190px;
    }
    .getstarted_content-right {
      width: 100%;
    }
    .title_wrapper {
      margin-right: auto;
      margin-left: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .title_wrapper._50 {
      width: 80%;
    }
    .title_wrapper._50._10marginbottom {
      max-width: 70%;
      margin-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      text-align: left;
    }
    .title_wrapper._70 {
      width: 100%;
      margin-bottom: 40px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .title_wrapper._70.congrat {
      width: 100%;
      padding-right: 0px;
    }
    .title_wrapper._45 {
      width: 65%;
    }
    .title_wrapper.faq {
      margin-left: 0px;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      text-align: left;
    }
    .title_wrapper.small {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      text-align: left;
    }
    .title_wrapper.congrat {
      padding-right: 0px;
    }
    .title_wrapper.phase {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .title_wrapper.phase.orangebox.article {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .title_wrapper.blog-form_title {
      padding-right: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .title_wrapper.blog-steps {
      padding-right: 0%;
      text-align: center;
    }
    .title_wrapper.workshop {
      width: 50%;
    }
    .title_wrapper.modal {
      margin-left: 0px;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .pk-intro-s2-left-text {
      width: 61%;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .instruction-wrapper {
      margin-top: 15px;
      margin-bottom: 25px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
    }
    .instruction-wrapper._3 {
      margin-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .tip_wrapper {
      width: 100%;
      margin-left: 0px;
    }
    .pk-steps-audioplayer-intro.steps {
      margin-top: 0px;
    }
    .getstarted_animated-image {
      width: auto;
      max-width: 100%;
      margin-left: 0px;
    }
    .getstarted-image-3 {
      width: 330px;
      margin-right: auto;
      margin-bottom: 30px;
      margin-left: auto;
    }
    .getstarted-img_wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 0px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .pk-cta-bottom {
      height: 80px;
    }
    .nav-menu-3 {
      z-index: 50;
      padding-top: 10px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      background-color: #1d1b4a;
    }
    .hero_content {
      height: 750px;
      padding-top: 20px;
      padding-bottom: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .hero_content.faq {
      height: auto;
    }
    .hero_content.privacy {
      height: auto;
      padding-top: 0px;
    }
    .hero_content.bootcamp {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .hero_content.home2 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .hero_content.congrat {
      margin-top: 0px;
      margin-bottom: 40px;
      padding-bottom: 0px;
    }
    .hero_content.blog {
      height: 100%;
      margin-bottom: 50px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .hero_content.getstarted {
      height: 770px;
    }
    .hero_content.buyslides {
      height: 550px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .hero_content.travelguide {
      height: 100%;
    }
    .hero_content.landing {
      height: 900px;
    }
    .hero_content.slidesfox {
      height: auto;
    }
    .clients_wrapper {
      height: auto;
      margin-right: 15px;
      margin-left: 15px;
    }
    .list-item {
      letter-spacing: -0.3px;
    }
    .label-wrapper.getstarted {
      text-align: left;
    }
    .pk-step-anim {
      left: 54px;
      top: 18px;
      width: 100%;
      max-width: 370px;
    }
    .pk-steps-menu-button {
      padding-right: 0px;
    }
    .pk-steps-icon {
      color: #131387;
    }
    .pkbs-nav-menz-steps {
      background-color: #110f35;
    }
    .pk_text-block.pk_highlighted {
      padding-right: 100px;
      padding-left: 100px;
    }
    .pk_text-block.pk_highlighted.end {
      padding-right: 30px;
      padding-left: 30px;
    }
    .pk-text-block_2col {
      padding-right: 30px;
      padding-left: 30px;
    }
    .pk-right-col {
      margin-right: 35px;
    }
    .pk-blog-subpage-icon {
      color: #131387;
    }
    .pk-blog-subpage-menu {
      padding-right: 0px;
    }
    .form-contact {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-clients-item {
      width: auto;
    }
    .large-number_wrapper {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .feature_image {
      max-width: 200px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .feature_image.large {
      max-width: 200px;
      margin-bottom: 20px;
      padding-bottom: 0px;
    }
    .feature_image.large.workshop {
      max-width: 200px;
      margin-bottom: 0px;
    }
    .feature_image.tiny {
      margin-bottom: 5px;
    }
    .feature_image.medium.steps-under._2 {
      margin-bottom: 20px;
    }
    .feature_image.ebook {
      margin-bottom: 0px;
    }
    .feature_image.phase {
      max-width: 130px;
    }
    .pk-steps-s2 {
      height: auto;
      padding-bottom: 80px;
    }
    .image-79 {
      left: -120px;
    }
    .image-80 {
      left: -230px;
    }
    .section {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .section.darkbg.portfolio {
      height: 650px;
    }
    .section.darkbg.portfolio.travelguide {
      height: 100%;
    }
    .section.darkbg.portfolio.conference {
      height: auto;
    }
    .section.darkbg.testimonial {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    .section.share-block {
      padding-bottom: 194px;
    }
    .pk-footer-social.followus._2 {
      margin-right: 0px;
    }
    .pk-steps-instructions-titlerow {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pk-steps-iconmenu {
      width: auto;
      height: auto;
      border-style: none;
      background-color: transparent;
      background-image: none;
      box-shadow: none;
      color: #131387;
    }
    .getstarted_icon {
      width: 80px;
    }
    .navbar-logo_wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      text-align: left;
    }
    .checkbox.team-assesment-card {
      margin-bottom: 0px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .checkbox-label.team-assessment-card {
      margin-left: 10px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .checkbox_content {
      margin-right: 0px;
    }
    .checkbox_content.team-checkbox.team-assessment {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .interests.workshop {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .package_img {
      width: 320px;
    }
    .package_img._1 {
      left: 0px;
      width: 330px;
      margin-right: -60px;
    }
    .package_img._3 {
      right: 0px;
      margin-left: -62px;
    }
    .package_img.order {
      width: auto;
      max-width: 280px;
    }
    .subscribe-form_wrapper {
      width: 100%;
    }
    .cong_buyslides-promo-img {
      width: auto;
      max-width: 290px;
      margin: -20px auto 20px;
    }
    .about_feature-wrapper {
      padding: 20px 217px;
    }
    .about_feature-wrapper._2 {
      margin-bottom: 50px;
    }
    .about_feature-wrapper.bootcamp {
      position: static;
      width: 100%;
      margin-top: 20px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .about_feature-wrapper.bootcamp._2 {
      position: static;
      margin-top: 50px;
      margin-right: 0px;
      margin-left: 0px;
    }
    .about_feature-wrapper.conference {
      position: static;
      width: 100%;
      margin-top: 20px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .about_feature-wrapper.conference._2 {
      position: static;
      margin-top: 50px;
      margin-right: 0px;
      margin-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
    .about_feature-img.bootcamp {
      margin-bottom: 30px;
    }
    .about_feature-img.conference {
      margin-right: 0px;
      margin-bottom: 30px;
    }
    .orange-box_blocks-wrapper {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 30px;
    }
    .orange-box_img {
      margin-right: 0px;
      margin-bottom: 10px;
    }
    .note-copy_wrapper {
      padding-right: 15%;
    }
    .blog_post.highlighted {
      padding: 29px 29px 20px;
    }
    .blog_title.highlighted {
      width: 80%;
      padding-right: 0%;
    }
    .feature_top-wrapper {
      height: auto;
      padding-top: 25px;
    }
    .bootcamp-feature_img {
      max-width: 200px;
      margin-top: 0px;
    }
    .bootcamp_callout {
      margin-bottom: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .bootcamp_about-img {
      width: 250px;
      height: 250px;
      margin-top: 20px;
    }
    .bootcamp_about-img.conference.margintop.c2 {
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .bootcamp_testimonial-wrapper {
      width: 80%;
    }
    .bootcamp_packages-wrapper {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
    }
    .bootcamp_image {
      display: none;
      max-width: 960px;
      margin-right: auto;
      margin-left: auto;
    }
    .bootcamp_box_wrapper {
      margin-right: 0px;
      margin-bottom: 10px;
      text-align: left;
    }
    .radio-button-field {
      margin-right: 0px;
      text-align: left;
    }
    .bootcamp_row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .bootcamp_row.grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto;
      text-align: left;
    }
    .bootcamp_packages-callout-wrapper {
      height: 250px;
      max-width: 600px;
      margin-right: auto;
      margin-bottom: -100px;
      margin-left: auto;
    }
    .bootcamp_callout-package {
      left: -20px;
      min-width: auto;
      padding-right: 0px;
      padding-left: 0px;
    }
    .bootcamp_callout-package._2 {
      left: 10px;
    }
    .bootcamp_callout-package._3 {
      left: 24px;
      top: 20px;
    }
    .form-bootcamp {
      margin-bottom: 50px;
      text-align: center;
    }
    .bootcamp_heroimg_1 {
      left: 60px;
      top: 0px;
      width: 290px;
    }
    .bootcamp_heroimg_2 {
      left: -40px;
      top: 250px;
      width: 260px;
    }
    .bootcamp_heroimg_3 {
      left: 310px;
      top: 290px;
      width: 110px;
    }
    .bootcamp_heroimg_4 {
      top: 150px;
      width: 32px;
    }
    .bootcamp_heroimg_5 {
      left: 210px;
      top: 270px;
      width: 30px;
    }
    .bootcamp_heroimg_6 {
      left: 320px;
      top: 40px;
      width: 18px;
    }
    .pk-navlink_dropdown {
      display: none;
    }
    .pk-navlink_icon-arrow {
      display: none;
    }
    .navbar_dropdown-wrapper.mobile {
      position: static;
      display: block;
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      background-color: transparent;
    }
    .pk-navbar_dropdown-row._2.mobile {
      width: 95%;
      margin-right: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .pk-navbar_dropdown-row._2.small {
      color: #fff;
    }
    .pk-navbar_dropdown-row.mobile.w--current {
      width: 95%;
      color: #ef5b09;
    }
    .pk-navbar_dropdown-row.small {
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      padding: 8px 0px;
      color: #fff;
    }
    .pk-navbar_dropdown-row.small:hover {
      background-color: transparent;
      color: #fff;
    }
    .checkbox-field.team-assessment-card {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .checkbox-wrapper {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .checkbox-wrapper.home {
      width: auto;
    }
    .checkbox-wrapper.blog-form {
      margin-bottom: 0px;
    }
    .contact-col._1 {
      width: 100%;
    }
    .hero_image {
      width: 450px;
      min-width: 0px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .hero_image.home {
      left: 0px;
      min-width: 550px;
      margin-top: 10px;
    }
    .hero_image.mobile {
      display: none;
    }
    .hero_image.getstarted {
      position: relative;
      top: 0px;
    }
    .hero_image.our-solution {
      width: 540px;
      margin-top: 50px;
    }
    .hero_image.bootcamp {
      top: -20px;
      width: 380px;
      height: 440px;
      min-width: 400px;
    }
    .hero_image.aboutus {
      left: 0px;
      z-index: 0;
    }
    .hero_image.faqimage {
      left: 0px;
      margin-top: 40px;
    }
    .hero_image.congrat {
      max-width: 480px;
      margin: 10px auto;
    }
    .hero_image.step {
      min-width: 440px;
    }
    .hero_image.step.tab_content {
      margin-right: auto;
      margin-left: auto;
    }
    .hero_image.tabs {
      width: 100%;
      margin-left: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .hero_image.travel-guide_heroimg {
      width: 100%;
      max-width: 580px;
      min-width: 0px;
    }
    .hero_image.showsevents-heroimg {
      width: 100%;
      max-width: 540px;
      min-width: 0px;
    }
    .hero_image.showsevents-heroimg {
      width: 100%;
      max-width: 540px;
      min-width: 0px;
      -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
      transform: scale(0.75);
    }
    .hero_image.slidesfox {
      margin-bottom: 48px;
    }
    .heroimg_home2-element-1 {
      width: 19px;
    }
    .heroimg_home2-element-2 {
      left: 40px;
      width: 27px;
    }
    .heroimg_home2-element-3 {
      left: 100px;
      width: 210px;
    }
    .heroimg_home2-element-4 {
      left: 50px;
      width: 110px;
    }
    .heroimg_home2-element-5 {
      width: 170px;
    }
    .heroimg_home2-element-6 {
      width: 130px;
    }
    .heroimg_home2-element-7 {
      right: 80px;
      width: 17px;
    }
    .heroimg_home2-element-8 {
      right: 170px;
      width: 90px;
    }
    .heroimg_home2-element-9 {
      right: 90px;
      width: 120px;
    }
    .heroimg_home2-element-10 {
      left: 360px;
      width: 20px;
    }
    .container-6 {
      padding: 60px 30px;
    }
    .section_hero {
      overflow: hidden;
      height: auto;
      padding-top: 0px;
    }
    .section_hero.getstarted {
      height: 100%;
      padding-bottom: 30px;
    }
    .section_hero.blog {
      height: 100%;
    }
    .section_hero.buyslides {
      height: 600px;
    }
    .section_hero.privacy {
      height: 270px;
    }
    .section_hero.steps {
      padding-bottom: 50px;
    }
    .pk_container {
      width: 90%;
      max-width: 1020px;
    }
    .pk_container.navbar {
      width: 100%;
    }
    .pk_container.buyslides {
      width: 100%;
      max-width: none;
    }
    .pk_container.travelguide-preview {
      width: 100%;
    }
    .content_wrapper.box.getstarted {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .content_wrapper.box.blog-form {
      height: 450px;
    }
    .content_wrapper.about_text {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .content_wrapper._2col_block {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .content_wrapper.divider.small {
      margin-top: 20px;
    }
    .content_wrapper.portfolio.workshop {
      height: 750px;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .content_wrapper.box.followus.request {
      padding-bottom: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .signup-1row_wrapper {
      width: 100%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .signup-1row_wrapper.large {
      width: 100%;
    }
    .signup-1row_wrapper.large.blog-form-row {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .footer_col.social_col {
      width: auto;
      margin-top: 60px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .footer_social-wrapper.followus {
      margin-top: -20px;
      margin-bottom: 40px;
    }
    .navbar_sticky {
      position: absolute;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: auto;
      display: none;
    }
    .landing-title {
      padding-right: 0px;
    }
    .landing-title.blog {
      padding-right: 0px;
    }
    .landing-title.workshop {
      width: 67%;
      margin-right: auto;
      margin-left: auto;
      padding-right: 0px;
    }
    .title_row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .title_row.followus {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .content_col {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .content_col.text_aboutus {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-right: 0px;
      margin-bottom: 20px;
      padding-right: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .content_col.order-block {
      width: auto;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
      padding-right: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .content_col.marginleft {
      margin-left: 0px;
    }
    .content_col.right {
      margin-left: 0%;
      text-align: center;
    }
    .content_col.right.request {
      margin-top: 30px;
    }
    .content_col.request {
      width: 100%;
      padding-right: 0px;
      text-align: center;
    }
    .content_col.imagearticle {
      width: 100%;
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
    }
    .content_col.first {
      width: 100%;
      min-width: 0%;
    }
    .content_col.blog.left {
      width: 90%;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
    }
    .content_col.image-block_article {
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .faq_top-row {
      text-align: left;
    }
    .faq_top-icon {
      margin-left: 30px;
    }
    .image_orange {
      margin-right: 0px;
      margin-bottom: 10px;
    }
    .tabs_menu {
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      padding-left: 20px;
    }
    .portfolio-description-2 {
      margin-bottom: 6px;
    }
    .sv_button-2.small.portfoliobtn {
      width: 95%;
    }
    .body-6 {
      padding-top: 0px;
    }
    .body-6.highlight.section_subtitle {
      padding-top: 0px;
    }
    .body-6.feature-subtext {
      padding-top: 0px;
    }
    .body-6.highlight.section_subtitle {
      padding-top: 0px;
    }
    .body-6.feature-subtext {
      padding-top: 0px;
    }
    .nav-link_wrapper {
      padding-right: 25px;
      padding-left: 25px;
    }
    .nav-link_wrapper.dropdown.mobile {
      margin-bottom: 10px;
      padding-bottom: 15px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      border-top: 1px solid #110f35;
      border-bottom: 1px solid #110f35;
      background-color: rgba(20, 14, 65, 0.41);
    }
    .nav_menu_desktop2 {
      display: none;
    }
    .nav-bar_bg {
      z-index: 80;
      background-color: #1b2b27;
    }
    .mobile-logo-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-left: 0%;
    }
    .nav-bar {
      z-index: 90;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      height: 85px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .navbar-mobile-bg {
      position: fixed;
      left: 0px;
      top: 0px;
      right: 0px;
      display: none;
      width: 100%;
      height: 85px;
      background-color: #110f35;
    }
    .sidebar-bg {
      position: fixed;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 80;
      display: none;
      background-color: rgba(0, 0, 0, 0.67);
    }
    .navicon-wrap {
      margin-right: 10px;
    }
    .logo {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .sidebar {
      position: fixed;
      top: 0px;
      right: -270px;
      bottom: 0px;
      z-index: 90;
      display: none;
      width: 270px;
      height: 100%;
      padding: 0px;
      background-color: #1f165a;
    }
    .logo-mobile {
      width: 100%;
      margin-left: 20px;
    }
    .navicon {
      width: 30px;
      height: 30px;
    }
    .mobile-navbar {
      position: absolute;
      top: 0px;
      z-index: 90;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 85px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .menu-wrapper {
      width: 100%;
      height: 100%;
      margin-right: 0px;
      padding-top: 30px;
      padding-bottom: 15px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .menu-wrapper.links {
      margin-right: 0px;
    }
    .menu-wrapper.links.sidebar-links {
      padding-top: 20px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .structure-menu-items {
      display: none;
    }
    .structure-project-menu {
      position: absolute;
      z-index: 99999;
      overflow: scroll;
      background-color: #110f35;
    }
    ._3-col {
      width: 100%;
      margin-right: 0px;
    }
    ._3-col.end {
      margin-bottom: 30px;
    }
    .structurenav-menu {
      display: none;
    }
    .wrap {
      width: 645px;
    }
    .wrap._w-custom.structure-menu-wrap {
      width: auto;
      height: auto;
      padding: 15px 30px 35px;
    }
    .wrap._w-custom.structure-menu-wrap {
      width: 95%;
      height: auto;
      padding: 15px 30px 35px;
    }
    .nav_inner {
      padding-left: 20px;
    }
    .structure-cards2-menu-btn {
      width: 26px;
      height: 26px;
      margin-right: 0px;
      padding: 0px;
      background-image: url('/images/landing/icon_nav.svg');
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .structure-cards2-menu-btn:active {
      background-color: transparent;
    }
    .structure-cards2-menu-btn.w--open {
      margin-right: 30px;
      background-color: transparent;
      background-image: url('/images/landing/icon_nav-x.svg');
      background-size: cover;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease;
    }
    .buy-cta_content {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .price_wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .author_block.workshop {
      padding-right: 0%;
    }
    .image_travel-guide_preview {
      display: block;
      width: 100%;
      max-width: 100%;
    }
    .image_travel-guide_preview.workshop {
      display: none;
    }
    .orange-box_img-mobile {
      display: block;
      width: auto;
      max-width: 180px;
      margin-right: auto;
      margin-bottom: 12px;
      margin-left: auto;
    }
    .orange-box_top-row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .address_block {
      margin-bottom: 15px;
    }
    .box_header {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .box_header.secondary {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .box_content-wrapper {
      text-align: center;
    }
    .skill-row {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .conference_slider {
      margin-top: 0px;
    }
    .conf_slider_content {
      padding: 18px 97px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
    }
    .conf_slider_content.workshop {
      padding-right: 59px;
      padding-left: 59px;
    }
    .conf_slider_text.workshop {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
    .conf_slider_img {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 40px;
    }
    .phase-title {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .box_wrapper-2 {
      margin-right: 0px;
      margin-bottom: 20px;
      margin-left: 0px;
      padding-right: 99px;
      padding-left: 99px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .box_wrapper-2.middle {
      margin: 20px 0px;
    }
    .signup-1-row {
      margin-right: auto;
      margin-left: auto;
    }
    .hero_image-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-steps-right-blue-2 {
      width: 100%;
      height: auto;
      margin-left: 0px;
      padding-right: 19px;
      padding-bottom: 24px;
    }
    .section-2 {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .box_wrapper-3 {
      margin-right: 0px;
      margin-bottom: 20px;
      margin-left: 0px;
      padding-right: 99px;
      padding-bottom: 55px;
      padding-left: 99px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .row._2column {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .congrat_nextstepimage {
      max-width: 300px;
      margin-right: 0px;
    }
    .congrat_nextstep-wrapper {
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .orangebox_img-compact {
      display: none;
    }
    .price-counterimg_wrapper {
      left: 100px;
    }
    .hero_btn-row {
      width: 100%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .new_label {
      display: block;
      width: 80px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
    .table-cell.table-header {
      text-align: center;
    }
    .table-cell.hide {
      display: none;
    }
    .table-row_form.cards-row {
      -ms-grid-columns: 2fr 2fr;
      grid-template-columns: 2fr 2fr;
    }
    .button-5.outline.navbar-sf.mobile {
      margin-top: 8px;
      margin-left: 0px;
    }
    .button-5.navbar.mobile {
      height: 55px;
      margin-top: 20px;
    }
    .button-5.hero_secondary {
      margin-left: 15px;
    }
    .button-5.white {
      width: 50%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .button-5.navbar-sf.mobile {
      height: 55px;
      margin-top: 20px;
    }
    .label-2.mobile-navbar {
      position: static;
      height: auto;
      margin-top: 30px;
      margin-bottom: 13px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
    }
    .label-2.mobile-navbar.first {
      margin-top: 0px;
    }
    .structure-project-menu-2 {
      position: absolute;
      z-index: 99999;
      overflow: scroll;
      background-color: #110f35;
    }
    .nav-link-2 {
      display: block;
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #fff;
      text-align: center;
    }
    .pk-navbar-2 {
      z-index: 50;
      padding-left: 0%;
    }
    .navbar_sticky-2 {
      position: absolute;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: auto;
      display: none;
    }
    .wrapper_hero-text-2 {
      position: relative;
      z-index: 10;
      display: block;
      width: 550px;
      max-width: none;
      padding-right: 0px;
      padding-left: 0px;
      color: #fff;
      text-align: center;
    }
    .subtitle-2.hero-subtitle.landing {
      padding-right: 0px;
    }
    .subtitle-2.hero-subtitle.slidesfox {
      margin-right: auto;
      margin-left: auto;
    }
    .section_hero-2 {
      overflow: hidden;
      height: auto;
      padding-top: 0px;
    }
    .box_wrapper-4 {
      margin-right: 0px;
      margin-bottom: 20px;
      margin-left: 0px;
      padding-right: 99px;
      padding-bottom: 55px;
      padding-left: 99px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .box_wrapper-4.step.col5 {
      margin-bottom: 10px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .box_wrapper-4.step.col4 {
      margin-bottom: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .body-7 {
      padding-top: 0px;
    }
    .body-7.highlight.section_subtitle {
      padding-top: 0px;
    }
    .body-7.feature-subtext {
      padding-top: 0px;
    }
    .orange-box_wrapper-2 {
      width: 100%;
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 30px;
      padding-right: 35px;
      padding-left: 35px;
    }
    .pk-s4-orange-icon1-2 {
      width: 100%;
      height: auto;
      margin-right: 0px;
      padding: 26px 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
      text-align: center;
    }
    .pk-s4-orange-icon1-2.gutter {
      margin-right: 15px;
    }
    .footer_col-2.social_col {
      width: auto;
      margin-top: 60px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .menu-btn-wrapper.mobile {
      margin-top: 20px;
      margin-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .pricing-placeholder {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto;
      grid-template-rows: auto auto auto;
    }
    .pricing-placeholder_block {
      height: 300px;
    }
  }
  
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 45px;
      line-height: 45px;
      letter-spacing: -1px;
    }
    .pk-section {
      padding: 0px 15px;
    }
    .pk-section.prisma {
      padding-right: 0px;
      padding-left: 0px;
    }
    .label.mobile-navbar {
      position: static;
      height: auto;
      margin-top: 30px;
      margin-bottom: 13px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .wrapper {
      display: none;
      overflow: scroll;
    }
    .prisma_div {
      left: 0px;
      top: 0px;
      right: 0px;
    }
    .section-portfolio.presentation {
      height: 810px;
      max-height: 810px;
      background-image: none;
      background-position: 0px 0px;
    }
    .section-portfolio.presentation {
      height: 810px;
      max-height: 810px;
      background-image: none;
      background-position: 0px 0px;
    }
    .section-clients {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .section-contactform {
      padding: 0px 30px 40px;
    }
    .section-footer {
      padding-right: 30px;
      padding-left: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .sv-h2 {
      font-size: 30px;
    }
    .sv-copy {
      width: 90%;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 26px;
    }
    .sv-copy.contact {
      display: block;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
    .portfolio-wrapper {
      width: 100%;
      margin-bottom: 10px;
      padding-right: 60px;
      padding-left: 60px;
    }
    .portfolio-wrapper.presentation {
      display: block;
      width: 64%;
      margin: 300px auto auto;
      padding-right: 0px;
      padding-left: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .portfolio-wrapper.secondary {
      position: relative;
      z-index: 50;
      margin-bottom: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .portfolio-wrapper.secondary.portfolio {
      width: 100%;
    }
    .clients-wrapper {
      height: 790px;
      max-width: 100%;
      margin-right: 0px;
      margin-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
    }
    .clients-item {
      width: auto;
    }
    .contactform-wrapper {
      width: 100%;
      padding-right: 0px;
    }
    .contactform-illustration {
      display: none;
    }
    .contact-title {
      font-size: 34px;
      line-height: 34px;
      text-align: center;
    }
    .form-sendbutton {
      width: 100%;
      height: 55px;
    }
    .section-about {
      padding-left: 30px;
    }
    .footer-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .footer-links {
      width: 100%;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .footer-copyright {
      font-size: 14px;
    }
    .footer-links-item {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      font-size: 14px;
    }
    .sv-navbar {
      padding-right: 10px;
      padding-left: 10px;
    }
    .container {
      padding-top: 50px;
      padding-bottom: 60px;
    }
    .container.sv-portfolio-container {
      padding-top: 60px;
      padding-right: 0px;
      padding-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .container.sv-portfolio-container.presentation {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      padding-top: 0px;
      background-image: none;
      background-position: 0px 0px;
    }
    .container.contact {
      float: none;
    }
    .navbar-container {
      padding-left: 5px;
    }
    .portfolio-header {
      padding-right: 15%;
      padding-left: 15%;
      font-size: 30px;
      line-height: 30px;
      text-align: center;
    }
    .portfolio-description {
      text-align: center;
    }
    .sv_button.small.portfoliobtn {
      margin-right: auto;
      margin-left: auto;
    }
    .image-2 {
      margin-top: 40px;
    }
    .features-copy {
      margin-right: 0px;
    }
    .sv-h4.features {
      font-size: 30px;
      line-height: 27px;
    }
    .sv-features_description {
      display: block;
      width: 90%;
      margin-right: auto;
      margin-left: auto;
      font-size: 18px;
      line-height: 26px;
    }
    .subtitle {
      display: block;
      margin-right: auto;
      margin-left: auto;
      padding-right: 0px;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      letter-spacing: -0.4px;
    }
    .subtitle.hero-subtitle.landing {
      letter-spacing: -0.1px;
    }
    .featured_image {
      display: block;
      max-width: 60%;
      margin-right: auto;
      margin-left: auto;
    }
    .menu-button.w--open {
      background-color: transparent;
    }
    .nav-menu {
      background-color: #090f1f;
    }
    .portfolio_illustration_wrapper {
      left: -790px;
      height: 870px;
      margin-left: 412px;
      opacity: 0.77;
    }
    .portfolio_illustration_wrapper.secondary {
      opacity: 0.3;
    }
    .portfolio_illustration_wrapper.secondary.workshop {
      opacity: 1;
    }
    .portfolio-illustration_img._1.workshop-3 {
      display: none;
    }
    .portfolio-illustration_img._2 {
      left: 30%;
    }
    .portfolio-illustration_img._2.workshop-5 {
      left: 10%;
      top: 72%;
      display: block;
      border-radius: 7px;
      -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
      transform: scale(0.75);
    }
    .portfolio-illustration_img.empty {
      display: none;
    }
    .portfolio-illustration_img._3._2 {
      left: -50%;
      top: 10%;
    }
    .portfolio-illustration_img._3.workshop-2 {
      left: -6%;
      bottom: 21%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 7px;
      -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
      transform: scale(0.75);
    }
    .portfolio-illustration_img._4 {
      left: 78%;
      top: 20%;
    }
    .portfolio-illustration_img._5 {
      left: 0%;
      top: 55%;
      background-image: url('/images/landing/bumper.png');
      background-size: cover;
    }
    .portfolio-illustration_img._5.workshop-1 {
      top: 28%;
      border-radius: 7px;
      -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
      transform: scale(0.75);
    }
    .portfolio-illustration_img._6.workshop-6 {
      display: block;
    }
    .portfolio-illustration_img._7.workshop-4 {
      left: 29%;
      top: 56%;
      display: block;
      border-radius: 7px;
      -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
      transform: scale(0.75);
    }
    .portfolio-illustration_img._4._2 {
      top: 4%;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(17, 27, 53, 0.4)), to(transparent)), url('/images/landing/our-services.png');
      background-image: linear-gradient(180deg, rgba(17, 27, 53, 0.4), transparent), url('/images/landing/our-services.png');
      background-size: auto, cover;
    }
    .image-block {
      margin-right: 0px;
      margin-left: 0px;
      padding: 20px 30px 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
    }
    .image-block.quote-block {
      margin-right: 0px;
      margin-left: 0px;
      padding: 30px 30px 40px;
      text-align: left;
    }
    .article-block_img {
      max-width: 160px;
      margin-right: auto;
      margin-left: auto;
    }
    .description {
      margin-bottom: 16px;
    }
    .description.title {
      margin-bottom: 0px;
    }
    .pk-hero-right {
      display: block;
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
      -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8);
    }
    .pk-navbar {
      height: 70px;
    }
    .nav-link {
      text-align: center;
    }
    .box_wrapper {
      width: 100%;
      max-width: none;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .box_wrapper.bootcamp-feature.end {
      margin-bottom: 0px;
    }
    .box_wrapper.dark.congrat_progress-bar {
      padding-right: 50px;
      padding-left: 50px;
    }
    .box_wrapper.step {
      margin-right: 0px;
    }
    .box_wrapper.steps_columns {
      padding: 30px;
    }
    .box_wrapper.article_lowprior {
      margin-bottom: 0px;
    }
    .box_wrapper.article-block_emergency {
      padding-bottom: 45px;
    }
    .box_wrapper.noframe {
      padding-right: 50px;
      padding-left: 50px;
    }
    .box_wrapper.pricing {
      padding: 30px;
    }
    .box_text {
      width: 100%;
    }
    .features_wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .features_wrapper.list {
      max-width: none;
      min-width: auto;
    }
    .features_wrapper.steps_grid._2col-layout {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
    ._2col-block_text {
      margin-bottom: -31px;
      margin-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .orange-box_wrapper {
      margin-top: 0px;
      padding-top: 10px;
      padding-bottom: 42px;
      text-align: center;
    }
    .orange-box_content {
      margin-top: 0px;
      padding-top: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pk-s4-orange-icon1 {
      margin-right: 0px;
      padding-right: 45px;
      padding-left: 45px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .pk-s4-orange-icon1.gutter {
      margin-right: 15px;
    }
    .orangebox-headphone_image {
      display: none;
    }
    .protip-wrapper {
      width: 100%;
      height: auto;
      padding: 25px;
    }
    .footer-link {
      margin-right: auto;
      margin-left: auto;
    }
    .pk-hero-left-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      max-width: 500px;
      margin-right: auto;
      margin-left: auto;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .pk-hero-left-wrapper.home {
      margin-top: 30px;
    }
    .link-text.hero {
      margin-top: 14px;
    }
    .navbar_container {
      height: 70px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .menu-button-2 {
      padding-right: 0px;
    }
    .pk_logo {
      padding-left: 0px;
    }
    .section-image {
      display: block;
      width: auto;
      margin-top: 0px;
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
      clear: none;
    }
    .section-image.buyslides {
      max-height: 160px;
    }
    .section-image.travelguide {
      max-height: 190px;
      max-width: 320px;
    }
    .section-image.travelguide.price {
      left: 80px;
      top: -90px;
      max-width: 220px;
    }
    .section-image.conference.slidesfox {
      max-height: 200px;
      margin-bottom: 0px;
    }
    .contact_text {
      width: 100%;
      padding-right: 0px;
    }
    .wrapper_hero-text {
      width: 100%;
      margin-bottom: 20px;
    }
    .wrapper_hero-text.buyslides {
      width: 80%;
      max-width: 100%;
    }
    .wrapper_hero-text.blog {
      max-width: 100%;
    }
    .wrapper_hero-text.bootcamp {
      margin-bottom: 0px;
    }
    .wrapper_hero-text.congrat {
      width: 100%;
      min-width: 0px;
    }
    .wrapper_hero-text._100 {
      margin-bottom: 0px;
    }
    .pkf-s1-image {
      position: relative;
      bottom: -58px;
      width: auto;
      max-width: none;
      margin-top: 0px;
      float: none;
    }
    .pkf-s1-image.home {
      right: 0px;
      bottom: 0px;
      width: 100%;
      margin-top: 20px;
      margin-bottom: -40px;
    }
    .image-39 {
      width: 430px;
      max-width: none;
    }
    .pkf-s2-text-container {
      padding-right: 84px;
      padding-left: 84px;
    }
    .pkf-s2-text-container.steps {
      width: 100%;
      margin-bottom: 9px;
      padding-right: 0px;
    }
    .pkf-s2-text-container.steps._4 {
      width: 100%;
    }
    .arrow {
      top: 100%;
    }
    .pkbs-form-block {
      width: 90%;
      margin-top: 31px;
    }
    .text-field {
      width: 350px;
    }
    .pk-navbar-white-blog._1 {
      z-index: 14;
    }
    .pk-steps-s1 {
      height: 710px;
    }
    .pk-steps-s1-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .pk-steps-image {
      display: block;
      max-width: 65%;
      margin-top: 2px;
      margin-right: auto;
      margin-left: auto;
      float: none;
    }
    .pk-s1 {
      width: 100%;
      max-width: 300px;
    }
    .pk-steps-text.left {
      z-index: 5;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: auto;
      max-width: 100%;
      margin-bottom: 50px;
      padding-right: 15px;
      padding-left: 15px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-steps-progress-bar {
      margin-right: 15px;
    }
    .audioplayer_wrapper {
      width: 100%;
      max-width: 90%;
      margin-top: 0px;
    }
    .audioplayer_wrapper.steps {
      margin-top: 25px;
    }
    .div-block-82.intro {
      position: absolute;
      width: 65px;
    }
    .div-block-83 {
      width: auto;
    }
    .div-block-83._2 {
      margin-right: 10px;
      margin-left: 79px;
    }
    .text-block-25.intro {
      display: block;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .title_wrapper-row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .pk-steps-s2-wrapper {
      margin-top: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pk-steps-s2-left {
      width: auto;
      margin-top: 0px;
    }
    .pk-steps-s2-right {
      margin-top: 25px;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
    }
    .pk-steps-right-blue {
      width: 100%;
      height: auto;
      margin-left: 0px;
      padding-bottom: 15px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .pk-steps-right-blue.large {
      margin-bottom: 20px;
    }
    .div-block-84 {
      margin-top: 20px;
    }
    .text-block-29 {
      margin-left: 15px;
    }
    .unordered-list.getstarted {
      margin-top: 10px;
    }
    .pk-steps-s2-wrapper-middle {
      margin-top: 20px;
      margin-right: 0px;
      margin-left: 0px;
    }
    .div-block-86 {
      width: 460px;
    }
    .pk-modal-wrapper {
      display: none;
      padding-right: 20px;
      padding-left: 20px;
    }
    .pk-modal-wrapper.contact {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-right: 0px;
      padding-left: 0px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-structure-wrapper {
      overflow: scroll;
      width: 100%;
    }
    .pk-structure-wrapper.blog {
      overflow: hidden;
    }
    .pk_structure {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pk_structures-copy {
      margin-top: 15px;
      margin-left: 0px;
    }
    .pk-steps-s1-left-flex {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .div-block-100 {
      padding-right: 70px;
    }
    .pk-cong-s1-orange-right {
      margin-top: 25px;
    }
    .article_wrapper {
      text-align: left;
    }
    .button.intro {
      display: block;
      width: auto;
      padding-right: 30px;
      padding-left: 30px;
      text-align: center;
    }
    .button.white {
      width: 100%;
    }
    .button.sticky {
      height: 50px;
      padding-right: 30px;
      padding-left: 30px;
    }
    .button.hero_secondary {
      margin-right: 0px;
      margin-bottom: 15px;
      margin-left: 0px;
    }
    .playlist_thumbnail {
      max-width: none;
    }
    .input_form.message {
      margin-bottom: 20px;
    }
    .input_form._1row {
      margin-top: 0px;
    }
    .input_form._1row.followus {
      margin-bottom: 20px;
    }
    .input_form.textarea {
      min-height: 270px;
    }
    .getstarted_content-left {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .title_wrapper {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .title_wrapper._50 {
      width: 100%;
    }
    .title_wrapper._50._10marginbottom {
      max-width: 100%;
      margin-right: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .title_wrapper._70 {
      width: 94%;
    }
    .title_wrapper.numbered {
      margin-bottom: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .title_wrapper.numbered.steps {
      text-align: center;
    }
    .title_wrapper._45 {
      width: 100%;
      margin-bottom: 20px;
    }
    .title_wrapper.small {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .title_wrapper.phase {
      margin-right: 0px;
      margin-left: 0px;
    }
    .title_wrapper.workshop {
      width: 60%;
    }
    .pk-intro-s2-left-text {
      width: 100%;
      margin-left: 0px;
    }
    .instruction-wrapper {
      margin-left: 0px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .tip_wrapper {
      width: 100%;
    }
    .pk-steps-audioplayer-intro {
      position: relative;
      width: 100%;
    }
    .getstarted_animated-image {
      display: block;
      margin-top: -20px;
      margin-right: auto;
      margin-left: auto;
    }
    .getstarted-image-3 {
      margin-right: auto;
      margin-left: auto;
    }
    .pk-cta-bottom {
      z-index: 90;
      height: 60px;
      padding-right: 50px;
      padding-left: 40px;
    }
    .hero_content {
      height: 100%;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .hero_content.privacy {
      padding-top: 0px;
    }
    .hero_content.getstarted {
      height: 100%;
    }
    .hero_content.buyslides {
      height: 450px;
    }
    .hero_content.landing {
      height: auto;
    }
    .clients_wrapper {
      height: 790px;
      max-width: 100%;
      margin-right: 0px;
      margin-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
    }
    .pk-step-anim {
      left: 47px;
      top: 18px;
      max-width: 290px;
    }
    .pk-step-anim.bg {
      top: 16px;
    }
    .steps_instructions-block {
      -webkit-flex-wrap: wrap-reverse;
      -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
    }
    .pk-steps-menu-button {
      padding-right: 0px;
    }
    .pk_text-block {
      padding-right: 0px;
      padding-left: 0px;
    }
    .pk_text-block.pk_highlighted {
      padding-right: 50px;
      padding-left: 50px;
    }
    .pk_text-block.end {
      padding-right: 0px;
      padding-left: 0px;
    }
    .pk-ordered-list {
      display: block;
    }
    .pk-ordered-list.end {
      padding-left: 0px;
    }
    .pk-list-row.sign-wrapper {
      margin-left: 0px;
    }
    .pk-text-block_2col {
      margin-bottom: 60px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .pk-blog-subpage-menu {
      padding-right: 0px;
    }
    .form-contact {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      text-align: left;
    }
    .pk-clients-item {
      width: auto;
    }
    .large-number_wrapper.marginbottom10 {
      margin-bottom: 10px;
    }
    .feature_image {
      max-width: 130px;
    }
    .feature_image.large {
      max-width: 130px;
      padding-bottom: 0px;
    }
    .feature_image.large.workshop {
      max-width: 170px;
    }
    .feature_image.tiny {
      max-width: 45px;
      margin-top: 10px;
    }
    .feature_image.medium {
      width: auto;
      max-width: 100px;
    }
    .feature_image.small {
      width: 60px;
    }
    .feature_image.small.list {
      max-width: 100px;
      padding-right: 0px;
    }
    .feature_image.ebook {
      max-width: 300px;
    }
    .feature_image.phase {
      margin-right: 0px;
    }
    .feature_image.highligted {
      max-width: 150px;
    }
    .pk-steps-s3 {
      height: auto;
      padding-bottom: 70px;
    }
    .pk-steps-s4 {
      height: auto;
      padding-bottom: 70px;
    }
    .pk-steps-s5 {
      height: auto;
      padding-bottom: 80px;
    }
    .image-75 {
      left: 340px;
      top: -208px;
    }
    .image-76 {
      left: 230px;
      top: -280px;
    }
    .image-77 {
      left: 147px;
      top: -283px;
    }
    .image-78 {
      left: 28px;
      top: -271px;
    }
    .image-79 {
      top: -268px;
    }
    .image-80 {
      top: -184px;
    }
    .faq_info-wrapper {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .pk-steps-instructions-titlerow {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .pk-cong-social {
      margin-right: 20px;
      margin-left: 20px;
    }
    .checkbox {
      margin-left: 0px;
    }
    .interests {
      margin-bottom: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .package_img {
      width: 250px;
      margin-left: 10px;
    }
    .package_img._1 {
      width: 260px;
    }
    .hero_buyslides-bgimg {
      max-height: 510px;
    }
    .subscribe-form_wrapper {
      margin-top: 20px;
    }
    .cong_buyslides-promo-img {
      height: auto;
      max-width: 100%;
      min-width: auto;
    }
    .about_feature-wrapper {
      padding-right: 121px;
      padding-left: 121px;
    }
    .about_feature-wrapper.bootcamp {
      padding-top: 0px;
    }
    .about_feature-wrapper.conference {
      padding-top: 0px;
    }
    .about_feature-img {
      min-height: 100px;
    }
    .about_feature-img.bootcamp {
      margin-bottom: 0px;
    }
    .about_feature-img.conference {
      display: none;
      margin-bottom: 0px;
    }
    .orange-box_blocks-wrapper {
      margin-right: 0px;
      margin-bottom: 20px;
    }
    .buyslides_gradient {
      height: 100%;
    }
    .note-copy_wrapper {
      padding-right: 10%;
    }
    .form-header {
      margin-bottom: 0px;
      padding-right: 0px;
    }
    .blog_title.highlighted {
      width: 100%;
      margin-top: 0px;
    }
    .bootcamp-feature_img {
      max-width: 130px;
      margin-bottom: 10px;
    }
    .bootcamp_callout {
      margin-bottom: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .bootcamp_about-img {
      width: 200px;
      height: 200px;
    }
    .bootcamp_testimonial-wrapper {
      width: 90%;
    }
    .bootcamp_image {
      display: none;
      height: 410px;
    }
    .bootcamp_row.grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto;
    }
    .bootcamp_packages-callout-wrapper {
      position: static;
      margin-top: 0px;
      margin-bottom: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .bootcamp_callout-package {
      position: static;
      width: 100%;
      margin-bottom: 28px;
    }
    .callout-line {
      display: none;
    }
    .bootcamp_heroimg_1 {
      left: 110px;
      top: 20px;
      width: 270px;
    }
    .bootcamp_heroimg_2 {
      left: 30px;
      top: 280px;
      width: 220px;
    }
    .bootcamp_heroimg_3 {
      left: 337px;
      top: 300px;
      width: 100px;
    }
    .bootcamp_heroimg_4 {
      left: 31px;
      top: 210px;
    }
    .bootcamp_heroimg_5 {
      left: 220px;
      top: 255px;
    }
    .bootcamp_heroimg_6 {
      top: 45px;
    }
    .checkbox-field {
      margin-left: 0px;
      padding-left: 0px;
    }
    .checkbox-wrapper {
      padding-right: 10px;
      text-align: left;
    }
    .hero_image {
      width: 310px;
      height: auto;
      min-width: 310px;
      margin-bottom: 30px;
    }
    .hero_image.home {
      display: none;
    }
    .hero_image.mobile {
      display: block;
      width: auto;
      height: auto;
      max-width: 400px;
      min-width: auto;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .hero_image.mobile.about-us {
      margin-bottom: 40px;
    }
    .hero_image.mobile.travelguide {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .hero_image.mobile.slidesfox {
      display: block;
      margin-bottom: 40px;
    }
    .hero_image.getstarted {
      display: none;
    }
    .hero_image.our-solution {
      display: none;
      width: 430px;
      min-width: 0px;
      margin-top: 130px;
    }
    .hero_image.bootcamp {
      display: none;
    }
    .hero_image.aboutus {
      display: none;
    }
    .hero_image.faqimage {
      margin-top: 20px;
    }
    .hero_image.congrat {
      max-width: 430px;
    }
    .hero_image.step {
      min-width: 350px;
    }
    .hero_image.tabs {
      margin-bottom: 0px;
    }
    .hero_image.travel-guide_heroimg {
      display: none;
      width: 310px;
      min-width: 310px;
    }
    .hero_image.showsevents-heroimg {
      display: none;
      width: 310px;
      min-width: 310px;
    }
    .hero_image.showsevents-heroimg {
      display: none;
      width: 310px;
      min-width: 310px;
    }
    .hero_image.slidesfox {
      display: none;
    }
    .heroimg_home2-element-1 {
      left: 107px;
      top: 275px;
    }
    .heroimg_home2-element-2 {
      left: 31px;
      top: 29px;
      width: 28px;
    }
    .heroimg_home2-element-3 {
      left: 47px;
      top: 44px;
      width: 209px;
    }
    .heroimg_home2-element-4 {
      left: 22px;
      top: 83px;
      width: 120px;
    }
    .heroimg_home2-element-5 {
      left: 146px;
      top: 148px;
      width: 171px;
    }
    .heroimg_home2-element-6 {
      left: 140px;
      top: 164px;
      width: 158px;
    }
    .heroimg_home2-element-7 {
      top: 46px;
      right: 29px;
      width: 17px;
    }
    .heroimg_home2-element-8 {
      top: 20px;
      right: 96px;
      width: 105px;
    }
    .heroimg_home2-element-9 {
      top: 73px;
      right: 32px;
      width: 130px;
    }
    .heroimg_home2-element-10 {
      left: 306px;
      top: 146px;
      width: 26px;
    }
    .container-6 {
      padding-top: 50px;
      padding-bottom: 60px;
    }
    .section_hero {
      height: auto;
    }
    .section_hero.oursolution {
      height: auto;
    }
    .section_hero.blog {
      height: 100%;
    }
    .section_hero.buyslides {
      height: 100%;
    }
    .section_hero.travelguide {
      height: 100%;
    }
    .pk_container.ebooks {
      width: 100%;
    }
    .content_wrapper.box.getstarted {
      padding: 30px;
    }
    .content_wrapper.box.blog-form {
      height: auto;
      margin-bottom: 20px;
    }
    .content_wrapper.portfolio.workshop {
      height: 610px;
    }
    .content_wrapper.box.followus.request {
      padding-bottom: 0px;
    }
    .content_wrapper.grid {
      grid-column-gap: 39px;
      grid-template-areas: ".";
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto;
    }
    .signup-1row_wrapper {
      height: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .footer_col {
      width: auto;
      margin-bottom: 20px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      text-align: center;
    }
    .footer_col.social_col {
      width: 100%;
      margin-top: 30px;
    }
    .footer_navigation {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .navbar_sticky {
      position: fixed;
      top: 0px;
      background-color: transparent;
    }
    .getstarted_text-col._1 {
      margin-right: 0px;
    }
    .getstarted_text-wrapper {
      margin-right: auto;
      margin-left: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .landing-title.workshop {
      width: 75%;
    }
    .content_col.blog.left {
      width: 100%;
      margin-right: 0px;
      margin-left: 0px;
    }
    .content_col.nosize.horizontal {
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;
    }
    .faq_top-icon {
      margin-left: 20px;
    }
    .tab_link {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-right: 10px;
    }
    .tabs_menu {
      width: 100%;
      margin-left: 0px;
      padding-left: 10px;
    }
    .portfolio-description-2 {
      text-align: center;
    }
    .sv_button-2.small.portfoliobtn {
      margin-right: auto;
      margin-left: auto;
    }
    .nav-bar {
      width: 100%;
    }
    .navicon-wrap {
      margin-right: 0px;
    }
    .sidebar {
      display: block;
    }
    .mobile-navbar {
      width: 100%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .structure-menu-items {
      display: none;
      padding-top: 30px;
      padding-bottom: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-top: 1px solid hsla(0, 0%, 100%, 0.06);
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.06);
      background-color: #1a1a1a;
      text-align: center;
    }
    .structure-project-menu {
      z-index: 9;
      overflow: scroll;
      text-align: left;
    }
    ._3-col {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .structurenav-menu {
      width: 26px;
    }
    .wrap {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
    .wrap._w-custom.structure-menu-wrap {
      width: auto;
      padding: 15px 0px 35px;
      background-color: #110f35;
      text-align: center;
    }
    .wrap._w-custom.structure-menu-wrap {
      width: 95%;
      padding: 15px 0px 35px;
      background-color: #110f35;
      text-align: center;
    }
    .nav_inner {
      height: 60px;
    }
    .structure-cards2-menu-btn.w--open {
      margin-top: -4px;
    }
    .nav-dropdown {
      display: none;
    }
    .header {
      position: relative;
    }
    .buy-cta_content {
      padding-right: 0px;
      padding-left: 0px;
    }
    .author_block {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .author_block.workshop {
      justify-items: center;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
    .image_travel-guide_preview {
      margin-top: 0px;
    }
    .title_wrapper-row-image {
      width: 45px;
      height: 45px;
      margin-bottom: 5px;
    }
    .orange-box_img-mobile {
      width: 150px;
      height: auto;
      max-width: 100%;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    .orange-box_top-row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .conf_slider_text.workshop {
      padding-right: 0px;
      padding-left: 0px;
    }
    .conf_slider_img.hide {
      display: block;
    }
    .conf_slider_img.videobg {
      display: none;
    }
    .phase_row {
      margin-bottom: 30px;
    }
    .phase-title {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .box_wrapper-2 {
      width: 100%;
      max-width: none;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .pk-steps-right-blue-2 {
      width: 100%;
      height: auto;
      margin-left: 0px;
      padding-bottom: 15px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .box_wrapper-3 {
      width: 100%;
      max-width: none;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .congrat_nextstepimage {
      width: auto;
      max-width: 250px;
    }
    .price_tag {
      left: 96px;
      top: 69px;
      max-width: 130px;
      font-size: 25px;
    }
    .travel-guide_mobile {
      display: none;
    }
    .hero_btn-row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .button-5.hero_secondary {
      margin-right: 0px;
      margin-bottom: 15px;
      margin-left: 0px;
    }
    .button-5.white {
      width: 100%;
    }
    .label-2.mobile-navbar {
      position: static;
      height: auto;
      margin-top: 30px;
      margin-bottom: 13px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .structure-project-menu-2 {
      z-index: 9;
      overflow: scroll;
      text-align: left;
    }
    .nav-link-2 {
      text-align: center;
    }
    .pk-navbar-2 {
      height: 70px;
    }
    .navbar_sticky-2 {
      position: fixed;
      top: 0px;
      background-color: transparent;
    }
    .wrapper_hero-text-2 {
      width: 100%;
      margin-bottom: 20px;
    }
    .subtitle-2 {
      display: block;
      margin-right: auto;
      margin-left: auto;
      padding-right: 0px;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      letter-spacing: -0.4px;
    }
    .subtitle-2.hero-subtitle.landing {
      letter-spacing: -0.1px;
    }
    .section_hero-2 {
      height: auto;
    }
    .section_hero-2.oursolution {
      height: auto;
    }
    .box_wrapper-4 {
      width: 100%;
      max-width: none;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .box_wrapper-4.step {
      margin-right: 0px;
    }
    .tab_link-2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-right: 10px;
    }
    .orange-box_wrapper-2 {
      margin-top: 0px;
      padding-top: 10px;
      padding-bottom: 42px;
      text-align: center;
    }
    .pk-s4-orange-icon1-2 {
      margin-right: 0px;
      padding-right: 45px;
      padding-left: 45px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .pk-s4-orange-icon1-2.gutter {
      margin-right: 15px;
    }
    .footer_col-2 {
      width: auto;
      margin-bottom: 20px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      text-align: center;
    }
    .footer_col-2.social_col {
      width: 100%;
      margin-top: 30px;
    }
    .footer-link-2 {
      margin-right: auto;
      margin-left: auto;
    }
  }
  
  @media screen and (max-width: 479px) {
    h1 {
      font-size: 35px;
      line-height: 35px;
    }
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    .pk-section {
      padding-right: 11px;
    }
    .body.prisma-about {
      width: 80%;
    }
    .body.large.price.workshop {
      font-size: 20px;
    }
    .wrapper {
      position: fixed;
      z-index: 9999;
      display: none;
      overflow: visible;
    }
    ._3d_column1 {
      padding: 5px 2px;
    }
    ._3d_column2 {
      padding: 5px 2px;
    }
    ._3d_column3 {
      padding: 5px 2px;
    }
    ._3d_column4 {
      padding: 5px 2px;
    }
    .prisma_div {
      left: 0px;
      top: 0px;
      right: 0px;
      display: none;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .section-portfolio.presentation {
      height: 1000px;
      background-image: none;
      background-position: 0px 0px;
    }
    .section-portfolio.presentation {
      height: 1000px;
      background-image: none;
      background-position: 0px 0px;
    }
    .section-contactform {
      padding-top: 10px;
      padding-right: 15px;
      padding-left: 15px;
    }
    .sv-h2 {
      margin-bottom: 15px;
      font-size: 26px;
      line-height: 32px;
    }
    .sv-copy {
      width: 90%;
      margin-bottom: 20px;
      font-size: 17px;
    }
    .sv-copy.prisma {
      width: 80%;
    }
    .portfolio-wrapper {
      padding-right: 30px;
      padding-left: 30px;
    }
    .portfolio-wrapper.presentation {
      position: relative;
      display: block;
      width: 74%;
      margin-top: 50px;
    }
    .portfolio-wrapper.secondary {
      width: 100%;
      margin-bottom: 0px;
    }
    .clients-wrapper {
      height: auto;
    }
    .clients-item {
      margin-bottom: 10px;
    }
    .clients-logo {
      max-width: 100%;
    }
    .contactform-wrapper {
      padding-right: 15px;
      padding-left: 15px;
    }
    .contact-title {
      margin-bottom: 15px;
      line-height: 40px;
    }
    .form-box {
      height: 50px;
    }
    .form-box::-webkit-input-placeholder {
      font-size: 18px;
    }
    .form-box:-ms-input-placeholder {
      font-size: 18px;
    }
    .form-box::-ms-input-placeholder {
      font-size: 18px;
    }
    .form-box::placeholder {
      font-size: 18px;
    }
    .section-about {
      padding-right: 30px;
      padding-left: 30px;
    }
    .about-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .footer-links {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .footer-copyright {
      margin-top: 20px;
      margin-right: 0px;
      margin-bottom: 0px;
    }
    .footer-links-item {
      margin-right: 0px;
      margin-bottom: 20px;
    }
    .about-description {
      width: 100%;
    }
    .about-followpanel {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .sv-label {
      text-align: center;
    }
    .about-copy {
      width: 100%;
      text-align: center;
    }
    .image.linkedin.centered {
      margin-right: 0px;
    }
    .container {
      padding: 40px 15px 50px;
    }
    .container.sv-portfolio-container {
      padding-bottom: 30px;
    }
    .container.sv-portfolio-container.presentation {
      display: block;
    }
    .container.contact {
      padding-right: 0px;
      padding-left: 0px;
    }
    .portfolio-header {
      margin-bottom: 0px;
      padding-right: 0%;
      padding-left: 0%;
      font-size: 26px;
      line-height: 32px;
    }
    .portfolio-description {
      font-size: 17px;
      line-height: 26px;
    }
    .features-icons_block {
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
    .features-icons_block.presentation {
      height: auto;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .sv-h4.features {
      font-size: 26px;
      line-height: 32px;
    }
    .sv-features_description {
      font-size: 17px;
    }
    .features-row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .sv-h5 {
      text-align: center;
    }
    .sv-small-text {
      margin-bottom: 20px;
      text-align: center;
    }
    .features_icon {
      margin-right: 0px;
      margin-bottom: 12px;
    }
    .subtitle.hero-subtitle {
      margin-top: 20px;
    }
    .subtitle.hero-subtitle.landing {
      margin-top: 20px;
    }
    .featured_image {
      margin-bottom: 20px;
    }
    .dropdown-list {
      background-color: transparent;
    }
    .dropdown-list:hover {
      background-color: transparent;
    }
    .dropdown-toggle {
      background-color: transparent;
    }
    .portfolio_illustration_wrapper {
      position: relative;
      display: block;
      margin-top: 31px;
      margin-left: -89px;
    }
    .portfolio_illustration_wrapper.buyslides_hero {
      margin-top: 0px;
    }
    .portfolio_illustration_wrapper.secondary {
      position: absolute;
      height: 650px;
      max-width: 210px;
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
    }
    .portfolio-illustration_img._2.workshop-5 {
      left: -17%;
      -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
      transform: scale(0.5);
    }
    .portfolio-illustration_img.empty {
      left: 38%;
      top: 14%;
      width: 400px;
      height: 230px;
    }
    .portfolio-illustration_img.empty.empty-2 {
      left: 24%;
    }
    .portfolio-illustration_img._3 {
      left: -50%;
      top: 70px;
      width: 320px;
      height: 200px;
    }
    .portfolio-illustration_img._3._2 {
      left: -105%;
      top: 32%;
    }
    .portfolio-illustration_img._3.workshop-2 {
      left: -100%;
      bottom: 12%;
      width: 530px;
      height: 280px;
      -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
      transform: scale(0.5);
    }
    .portfolio-illustration_img._4 {
      width: 300px;
      height: 198px;
    }
    .portfolio-illustration_img._5 {
      left: 60%;
      top: 340px;
      width: 470px;
      height: 260px;
    }
    .portfolio-illustration_img._5.workshop-1 {
      left: -40%;
      top: 33%;
      -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
      transform: scale(0.5);
    }
    .portfolio-illustration_img._7.workshop-4 {
      left: -53%;
      width: 530px;
      height: 280px;
      -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
      transform: scale(0.5);
    }
    .portfolio-illustration_img._4 {
      width: 300px;
      height: 198px;
    }
    .portfolio-illustration_img._4._2 {
      left: 30%;
      top: 7%;
    }
    .content_section {
      position: static;
      z-index: 1;
    }
    .image-block {
      margin-right: 0px;
      margin-left: 0px;
      padding: 15px 15px 30px;
    }
    .article-block_img {
      height: auto;
      max-width: 150px;
      margin-right: auto;
      margin-left: auto;
    }
    .pk-hero-right {
      position: relative;
      left: 0px;
      top: -140px;
      right: 0px;
      display: block;
      width: 280px;
      -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
      transform: scale(0.5);
    }
    .pk-navbar {
      z-index: 9999;
      height: 70px;
      padding-right: 0%;
    }
    .nav-link.mobile-service {
      margin-top: 19px;
      font-size: 14px;
      font-weight: 600;
    }
    .box_wrapper {
      width: auto;
      margin-bottom: 20px;
      padding-top: 30px;
      padding-bottom: 30px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .box_wrapper.middle {
      margin-right: 0px;
      margin-left: 0px;
    }
    .box_wrapper.bootcamp-feature {
      padding-bottom: 25px;
    }
    .box_wrapper.bootcamp-feature.end {
      margin-bottom: 0px;
    }
    .box_wrapper.dark.congrat_progress-bar {
      padding-right: 20px;
      padding-left: 20px;
    }
    .box_wrapper.list {
      padding-right: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .box_wrapper.list.nobox {
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .box_wrapper.end.endrow-tablet {
      margin-bottom: 0px;
    }
    .box_wrapper.faq_block {
      padding: 20px;
    }
    .box_wrapper.orange {
      padding-right: 25px;
      padding-left: 25px;
    }
    .box_wrapper.step {
      padding-right: 0px;
      padding-left: 0px;
    }
    .box_wrapper.step.col5 {
      margin-bottom: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .box_wrapper.congrat {
      padding-right: 25px;
      padding-left: 25px;
    }
    .box_wrapper.steps_columns {
      padding-top: 25px;
      padding-right: 15px;
      padding-left: 15px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .box_wrapper.address {
      margin-right: 0px;
      padding: 20px 0px 0px;
      border-style: none;
      box-shadow: none;
    }
    .box_wrapper.skills {
      padding-right: 30px;
      padding-left: 30px;
    }
    .box_wrapper.article_lowprior {
      padding-bottom: 30px;
    }
    .box_wrapper.article-block_emergency {
      margin-bottom: 0px;
      padding-top: 30px;
    }
    .box_wrapper.noframe {
      padding: 10px;
    }
    .box_wrapper.pricing {
      padding: 25px;
    }
    .box_wrapper.title-block {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .box_text.left-align.list {
      margin-left: 0px;
      text-align: center;
    }
    .box_text.title-box {
      width: 100%;
      text-align: center;
    }
    .features_wrapper {
      width: 100%;
      padding-right: 0px;
      padding-left: 0px;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .features_wrapper._20bottommargin {
      margin-bottom: 20px;
    }
    ._2col-block_text {
      display: block;
      width: auto;
      max-width: 304px;
      padding-top: 20px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .orange-box_wrapper {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 20px;
      padding-left: 20px;
      border-radius: 0px;
      text-align: center;
    }
    .orange-box_wrapper.article {
      padding-right: 20px;
    }
    .orange-box_content {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .pk-s4-orange-icon1 {
      width: 100%;
      margin-top: 5px;
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 21px;
      padding-bottom: 20px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-s4-orange-icon1.gutter {
      margin-right: 0px;
      margin-left: 0px;
    }
    .orangebox-headphone_image {
      display: none;
    }
    .protip-wrapper {
      width: 100%;
      height: auto;
      padding-right: 8px;
      padding-left: 8px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
    }
    .pro-tip_textwrapper {
      margin-top: 31px;
      padding-right: 16px;
      padding-left: 16px;
    }
    .footer {
      height: 600px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .footer_wrapper {
      margin-bottom: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .footer-link {
      text-align: center;
    }
    .pk-hero-left-wrapper {
      position: relative;
      top: 0px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: auto;
      max-width: 100%;
      min-width: auto;
      margin-top: 0px;
      padding: 24px 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .pk-hero-play-image-block {
      margin-right: 0px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .pk-hero-left-text-block {
      max-width: 100%;
      padding-right: 23px;
      padding-left: 23px;
      text-align: center;
    }
    .image-18 {
      width: 105px;
      height: 105px;
    }
    .faq {
      background-color: #fbfbfb;
    }
    .pkp-p4.hero-video {
      text-align: center;
    }
    .pkp-video {
      line-height: 20px;
    }
    .pkp-video.hero {
      margin-bottom: 10px;
      margin-left: 0px;
    }
    .link-text.hero {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .navbar_container {
      padding-right: 0px;
      padding-left: 0px;
    }
    .menu-button-2 {
      padding-right: 0px;
    }
    .pk_logo {
      display: none;
      padding-left: 0px;
    }
    .section-image {
      margin-top: 0px;
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .section-image.buyslides {
      margin-bottom: 0px;
    }
    .section-image.travelguide {
      display: none;
      width: auto;
      max-height: none;
      max-width: 100%;
      min-width: 200px;
    }
    .section-image.travelguide.price {
      left: 40px;
      top: -70px;
      width: 66%;
      max-width: 100%;
    }
    .section-image.travelguide.price.mobile {
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0%;
      width: 100%;
      min-width: 0px;
    }
    .section-image.travelguide.mobile {
      display: block;
    }
    .section-image.conference.slidesfox {
      max-height: 150px;
    }
    .contact_text {
      padding-right: 5px;
      padding-left: 5px;
    }
    .contact_text.form.modal {
      margin-top: 25px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .wrapper_hero-text {
      position: relative;
      z-index: 10;
      width: 100%;
      padding-right: 0px;
      padding-left: 0px;
      text-align: center;
    }
    .wrapper_hero-text.buyslides {
      width: 90%;
      margin-bottom: 0px;
    }
    .wrapper_hero-text.blog {
      max-width: 90%;
    }
    .wrapper_hero-text.bootcamp {
      width: 95%;
    }
    .wrapper_hero-text._100 {
      margin-bottom: 0px;
    }
    .wrapper_hero-text.faq {
      background-color: transparent;
    }
    .pkf-s1-image {
      top: 26px;
      bottom: 0px;
      width: 100%;
      margin-top: 0px;
    }
    .pkf-s1-image.home {
      display: none;
    }
    .image-39 {
      position: relative;
      left: -13px;
      width: 320px;
      max-width: none;
    }
    .pkf-s2-text-container {
      max-width: 350px;
      margin-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .pkf-s2-text-container.steps {
      width: 100%;
      margin-bottom: 0px;
    }
    .h4.darktext.leftalign {
      text-align: center;
    }
    .arrow {
      top: 100%;
    }
    .pkf-s4-wrapper-textblock.bootcamp {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .pkf-s4-wrapper-textblock.conference {
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .pkbs-form-block {
      display: block;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 0px;
    }
    .text-field {
      width: 100%;
    }
    .pkb-submit-button {
      display: inline;
      border-left-width: 2px;
      border-radius: 3px;
    }
    .pkb-submit-button.bkps {
      height: 60px;
    }
    .pkb-submit-button.bkps:hover {
      color: #f6f9fc;
    }
    .form-2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .icon-2 {
      color: #fff;
    }
    .pk-navbar-white-blog {
      z-index: 20;
    }
    .pk-navbar-white-blog._1 {
      z-index: 25;
    }
    .pkbs-form2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pkbs-text-field {
      width: 100%;
    }
    .pk-steps-s1 {
      position: relative;
      height: 780px;
    }
    .pk-steps-s1-container {
      margin-top: 17px;
    }
    .pk-steps-s1-container._5 {
      margin-top: 13px;
    }
    .pk-steps-image {
      display: block;
      width: 80%;
      max-width: none;
      margin: 0px auto 30px;
    }
    .pk-s1 {
      width: 100%;
      max-width: none;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: -26px;
      padding-top: 0px;
    }
    .pk-steps-text.left {
      height: auto;
      padding-right: 0px;
      padding-left: 0px;
    }
    .pk-steps-progress-bar {
      margin-right: 20px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .text-block-22 {
      position: relative;
      padding-left: 32px;
    }
    .steps_description {
      width: auto;
      font-size: 16px;
      line-height: 24px;
    }
    .audioplayer_wrapper {
      display: block;
      width: 100%;
      height: auto;
    }
    .div-block-82 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      margin-top: 0px;
      padding-top: 0px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      border-radius: 0px 3px 3px 2px;
      background-color: #fff;
      font-weight: 400;
    }
    .div-block-82.intro {
      position: absolute;
      display: block;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .div-block-83 {
      position: static;
      top: 0px;
      right: 0px;
      bottom: 0px;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 8px;
    }
    .div-block-83._2 {
      position: static;
      margin-right: 0px;
      margin-left: 78px;
      padding-right: 0px;
    }
    .text-block-25 {
      margin-right: 41px;
      font-weight: 400;
    }
    .pk-progress-time {
      margin-left: 0px;
      font-weight: 400;
    }
    .title_wrapper-row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    .pk-steps-s2-wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pk-steps-s2-right {
      position: relative;
    }
    .pk-steps-right-blue {
      position: relative;
      width: auto;
      height: auto;
      margin-left: 0px;
    }
    .pk-steps-right-blue.large {
      padding: 20px;
    }
    .div-block-84 {
      box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.03);
    }
    .pk-steps-s2-wrapper-middle {
      margin-right: 0px;
      margin-left: 0px;
    }
    .steps_bottom_row {
      margin-top: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .div-block-86 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: auto;
      height: auto;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .image-52 {
      margin-right: 0px;
      margin-bottom: 5px;
      margin-left: 0px;
    }
    .div-block-87 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-modal-wrapper {
      display: none;
      overflow: scroll;
      padding-bottom: 20px;
    }
    .pk-structure-wrapper {
      overflow: auto;
      width: 100%;
      height: auto;
    }
    .pk-structure-wrapper.blog {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
    }
    .pk-structure-wrapper.contact {
      padding-right: 20px;
      padding-bottom: 15px;
      padding-left: 20px;
    }
    .pk-modal-header {
      margin-top: 20px;
    }
    .pk_structure {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .pk_structures-copy {
      margin-top: 14px;
    }
    .pk-steps-s1-left-flex {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .div-block-100 {
      padding-right: 0px;
    }
    .div-block-101 {
      margin-top: 9px;
    }
    .pk-cong-s1-orange-left {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
    }
    .pk-cong-s1-orange-right {
      margin-top: 10px;
    }
    .article_wrapper {
      margin-bottom: 40px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
    }
    .article_text {
      margin-top: 15px;
      margin-left: 0px;
    }
    .button {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: auto;
      min-height: 55px;
      margin-top: 10px;
      padding: 16px 45px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      line-height: 22px;
      text-align: center;
    }
    .button.intro {
      width: auto;
      height: auto;
      margin-top: 8px;
      padding: 15px 10px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      line-height: 22px;
    }
    .button.white {
      display: block;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .button.step {
      width: 100%;
      margin-bottom: 40px;
    }
    .button.sticky {
      height: 47px;
      min-height: 0px;
      margin-top: 0px;
      margin-left: 0px;
      padding-right: 0px;
      padding-left: 0px;
      font-size: 15px;
      letter-spacing: 1.5px;
    }
    .button.outline-circle {
      width: 30px;
      height: 30px;
      min-height: 0px;
      padding-top: 0px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .button.hero_secondary {
      margin-bottom: 0px;
    }
    .button.btn-icon.secondary {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: left;
    }
    .button.navbar.mobile {
      margin-left: 0px;
    }
    .input_form {
      height: 50px;
    }
    .input_form::-webkit-input-placeholder {
      font-size: 18px;
    }
    .input_form:-ms-input-placeholder {
      font-size: 18px;
    }
    .input_form::-ms-input-placeholder {
      font-size: 18px;
    }
    .input_form::placeholder {
      font-size: 18px;
    }
    .input_form.message {
      margin-bottom: 0px;
    }
    .input_form._1row {
      margin-bottom: 15px;
    }
    .input_form.textarea {
      min-height: 320px;
    }
    .getstarted_content-left {
      display: block;
      margin-bottom: 29px;
    }
    .title_wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .title_wrapper._50 {
      width: 100%;
      margin-bottom: 30px;
    }
    .title_wrapper._50._10marginbottom {
      margin-bottom: 0px;
    }
    .title_wrapper._70 {
      margin-bottom: 30px;
    }
    .title_wrapper.numbered {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .title_wrapper.faq {
      width: auto;
    }
    .title_wrapper.phase {
      text-align: left;
    }
    .title_wrapper.blog-steps {
      margin-bottom: 20px;
    }
    .title_wrapper.workshop {
      width: 85%;
    }
    .pk-intro-s2-left-text {
      width: 100%;
      margin-left: 0px;
    }
    .instruction-wrapper {
      margin-bottom: 0px;
      margin-left: 0px;
    }
    .tip_wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 0px;
      margin-right: 0px;
      margin-left: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .tip_content {
      margin-bottom: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
    .pk-steps-audioplayer-intro {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: hidden;
      width: 100%;
      height: 65px;
      margin-left: 0px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .getstarted_animated-image {
      width: 250px;
      height: 100%;
      max-width: 210px;
      margin-right: auto;
      margin-left: auto;
      padding-top: 0px;
    }
    .getstarted-image-3 {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
    .getstarted-img_wrapper {
      overflow: visible;
      margin-left: 0px;
    }
    .pk-cta-bottom {
      padding-right: 15px;
      padding-left: 15px;
    }
    .image-63 {
      width: 60px;
    }
    .image-64 {
      left: 57px;
      right: -7px;
      bottom: 69px;
    }
    .image-65 {
      bottom: 85px;
    }
    .div-block-146 {
      right: 34px;
      width: 190px;
    }
    .image-67 {
      width: 70px;
    }
    .image-68 {
      width: 35px;
    }
    .image-69 {
      width: 45px;
    }
    .image-70 {
      width: 100px;
    }
    .nav-menu-3 {
      position: fixed;
      left: 0%;
      top: 0%;
      right: 0%;
      bottom: 0%;
      z-index: 99999999;
      height: 100%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .hero_content {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .hero_content.faq {
      background-color: transparent;
    }
    .hero_content.privacy {
      height: 130px;
      padding-top: 0px;
    }
    .hero_content.blog {
      margin-bottom: 40px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .hero_content.buyslides {
      height: 430px;
      padding-top: 0px;
    }
    .hero_content.landing {
      height: auto;
      padding-top: 20px;
      padding-bottom: 50px;
    }
    .clients_wrapper {
      height: auto;
      padding-right: 5px;
      padding-left: 5px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-content: space-around;
      -ms-flex-line-pack: distribute;
      align-content: space-around;
    }
    .label-wrapper {
      margin-bottom: 11px;
    }
    .label-wrapper.tip {
      margin-left: 0px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .label-wrapper.getstarted {
      margin-bottom: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
    }
    .pk-step-anim {
      left: 22px;
      top: 14px;
      width: 100%;
      max-width: none;
      margin-left: -10px;
      border-radius: 5px;
    }
    .pk-step-anim.bg {
      top: 10px;
    }
    .pk-steps-menu-button {
      z-index: 8;
      padding-right: 0px;
    }
    .pk-steps-icon {
      color: #131387;
    }
    .pk_text-block {
      padding-right: 0px;
      padding-left: 0px;
    }
    .pk_text-block.pk_highlighted {
      padding-right: 30px;
      padding-left: 30px;
    }
    .pk_text-block.pk_highlighted.end {
      padding-right: 0px;
      padding-left: 0px;
    }
    .pk_quote-text.rightalign {
      margin-bottom: 30px;
      text-align: left;
    }
    .pk-list-row.checklist {
      padding-left: 5px;
    }
    .pk-list-row.sign-wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pk-text-block_2col {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pk-right-col {
      margin-right: 0px;
    }
    .pk-blog-subpage-icon {
      color: #fff;
    }
    .pk-blog-subpage-menu {
      padding-right: 0px;
    }
    .form-contact {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .time-icon {
      margin-bottom: 10px;
    }
    .pk-intro-cta-wrapper {
      padding: 30px 20px;
    }
    .pk-clients-item {
      width: 110px;
      margin-bottom: 10px;
      padding-right: 15px;
      padding-left: 15px;
    }
    .large-number_wrapper.phase {
      width: 45px;
      height: 45px;
      margin-right: 15px;
    }
    .large-number_wrapper.congrat {
      width: 45px;
      height: 45px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
    }
    .feature_image.large {
      padding-bottom: 0px;
    }
    .feature_image.large.workshop {
      max-width: 130px;
    }
    .feature_image.tiny {
      max-width: 35px;
    }
    .feature_image.medium.steps-under._2 {
      margin-bottom: 0px;
    }
    .feature_image.small.list {
      margin-bottom: 5px;
      padding-right: 0px;
    }
    .feature_image.ebook {
      max-width: 100%;
      border-radius: 5px;
    }
    .feature_image.phase {
      max-width: 100px;
      margin-right: auto;
      margin-bottom: 5px;
      margin-left: auto;
    }
    .feature_image.tinyx {
      margin-right: auto;
      margin-left: auto;
    }
    .feature_image.highligted {
      max-width: 130px;
    }
    .feature_image.title_block {
      max-width: 115px;
      margin-right: 0px;
      margin-bottom: 10px;
    }
    .pk-video-wrapper {
      margin-top: 5px;
      margin-bottom: 25px;
    }
    .pk-steps-s2 {
      position: relative;
      height: auto;
      padding-bottom: 80px;
    }
    .pk-steps-s3 {
      position: relative;
      height: auto;
      padding-bottom: 40px;
    }
    .pk-steps-s4 {
      position: relative;
      padding-bottom: 40px;
    }
    .pk-steps-s5 {
      position: relative;
      padding-bottom: 40px;
    }
    .pk_structures-grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto auto auto;
    }
    .image-75 {
      left: 256px;
      top: -134px;
      width: 60px;
    }
    .image-76 {
      left: 189px;
      top: -178px;
      width: 20px;
    }
    .image-77 {
      left: 96px;
      top: -190px;
      bottom: -40px;
      width: 25px;
    }
    .image-78 {
      left: 16px;
      top: -179px;
      width: 22px;
    }
    .image-79 {
      left: -93px;
      top: -171px;
      width: 35px;
    }
    .image-80 {
      left: -150px;
      top: -121px;
      width: 30px;
    }
    .navbar-sticky {
      z-index: 45;
      display: none;
    }
    .section {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .section.footer {
      height: auto;
    }
    .section.darkbg.portfolio.conference {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .section.share-block {
      padding-bottom: 240px;
    }
    .faq_info-wrapper {
      width: 100%;
      margin-top: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .faq_info {
      margin-bottom: 20px;
    }
    .pk-footer-social {
      position: static;
    }
    .pk-steps-iconmenu {
      width: auto;
      height: auto;
      border-style: none;
      background-color: transparent;
      background-image: none;
      box-shadow: none;
      color: #131387;
    }
    .pk-cong-social {
      margin-right: 8px;
      margin-left: 8px;
    }
    .checkbox {
      width: 25px;
      height: 25px;
      margin-left: 0px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
    .checkbox-label {
      text-align: left;
    }
    .checkbox_content {
      margin-bottom: 10px;
      padding-right: 15px;
      padding-left: 15px;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      text-align: left;
    }
    .package_img {
      top: 10px;
      width: 200px;
    }
    .package_img._1 {
      left: 30px;
      top: 23px;
      width: 180px;
    }
    .package_img._1.workshop {
      top: 23px;
    }
    .package_img._3 {
      top: 24px;
      right: 27px;
    }
    .package_img.order {
      max-width: 200px;
    }
    .hero_buyslides-bgimg {
      height: 100%;
    }
    .subscribe-form_wrapper {
      margin-top: 40px;
    }
    .subscribe-form_wrapper.blog {
      margin-top: 20px;
    }
    .cong_buyslides-promo-button {
      width: 100%;
    }
    .cong_buyslides-promo-img {
      max-width: 100%;
      min-width: auto;
      margin-bottom: 0px;
    }
    .about_feature-wrapper {
      padding-right: 20px;
      padding-left: 20px;
    }
    .about_feature-wrapper.bootcamp {
      margin-top: 10px;
    }
    .about_feature-wrapper.bootcamp._2 {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .about_feature-wrapper.conference {
      margin-top: 10px;
    }
    .about_feature-wrapper.conference._2 {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .about_feature-img.bootcamp {
      min-height: 71px;
    }
    .about_feature-img.conference {
      min-height: 71px;
    }
    .h2 {
      font-size: 24px;
      line-height: 30px;
    }
    .orange-box_blocks-wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .buyslides_gradient {
      top: 0px;
    }
    .note-copy_wrapper {
      padding-right: 0px;
    }
    .text-block-53 {
      text-align: center;
    }
    .blog_post.highlighted {
      height: auto;
      margin-bottom: 30px;
      padding: 20px;
    }
    .blog_title {
      margin-bottom: 15px;
      padding-right: 0%;
    }
    .blog_title.highlighted {
      margin-bottom: 15px;
      padding-right: 0%;
    }
    .feature_top-wrapper {
      height: auto;
      padding-top: 20px;
    }
    .feature_top-wrapper.feature1 {
      margin-bottom: 20px;
    }
    .bootcamp-feature_img {
      margin-top: 0px;
    }
    .bootcamp_callout {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
    }
    .bootcamp_about-img {
      width: 130px;
      height: 130px;
      margin-top: 25px;
    }
    .bootcamp_about-img.conference {
      width: 130px;
      height: 130px;
    }
    .bootcamp_testimonial-author {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .bootcamp-profile-img {
      margin-right: 0px;
      margin-bottom: 5px;
    }
    .testimonial-author-name {
      text-align: center;
    }
    .radiobutton.table {
      margin-right: 0px;
      margin-left: 0px;
    }
    .radio-button-field {
      margin-bottom: 10px;
    }
    .radio-button-field.table {
      width: 100%;
      padding-top: 0px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .bootcamp_row.grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto;
    }
    .bootcamp_packages-callout-wrapper {
      height: auto;
      margin-top: 20px;
    }
    .form-bootcamp {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }
    .bootcamp_heroimg_1 {
      left: 17px;
      top: 10px;
      width: 195px;
    }
    .bootcamp_heroimg_2 {
      left: 0px;
      top: 242px;
      width: 145px;
    }
    .bootcamp_heroimg_3 {
      left: 157px;
      top: 267px;
      width: 60px;
    }
    .bootcamp_heroimg_4 {
      left: 2px;
      top: 110px;
      display: block;
      width: 25px;
    }
    .bootcamp_heroimg_5 {
      left: 90px;
      top: 172px;
      width: 20px;
    }
    .bootcamp_heroimg_6 {
      left: 170px;
      top: 57px;
      width: 16px;
    }
    .navbar_dropdown-wrapper.mobile {
      position: static;
      width: 240px;
      margin-top: 0px;
      margin-right: 10px;
      margin-left: 10px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .pk-navbar_dropdown-row._2.mobile {
      width: auto;
      margin-right: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .pk-navbar_dropdown-row.mobile {
      width: auto;
    }
    .pk-navbar_dropdown-row.mobile.w--current {
      width: auto;
      max-width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .pk-navbar_dropdown-row.small {
      padding-left: 30px;
    }
    .pk-navbar_dropdown-img {
      display: none;
      width: 35px;
      margin-right: 0px;
      padding-bottom: 10px;
    }
    .checkbox-field {
      width: 100%;
      height: 100%;
      padding-bottom: 7px;
      padding-left: 0px;
    }
    .checkbox-icon {
      width: 20px;
      height: 25px;
      margin-left: 0px;
    }
    .hero_image {
      width: 100%;
      max-width: 290px;
      min-width: auto;
    }
    .hero_image.mobile {
      height: auto;
      max-width: 250px;
      margin-top: 0px;
    }
    .hero_image.mobile.travelguide {
      margin-bottom: 20px;
    }
    .hero_image.mobile.slidesfox {
      max-width: 100%;
    }
    .hero_image.our-solution {
      display: none;
    }
    .hero_image.aboutus {
      max-width: 280px;
      margin-top: 0px;
    }
    .hero_image.faqimage {
      max-width: 300px;
      margin-top: 20px;
    }
    .hero_image.congrat {
      max-width: 100%;
      margin-top: 0px;
    }
    .hero_image.step {
      max-width: 179px;
      min-width: 200px;
    }
    .hero_image.step.tab_content {
      margin-bottom: 40px;
    }
    .hero_image.travel-guide_heroimg {
      width: 100%;
      max-width: 290px;
      min-width: auto;
    }
    .hero_image.showsevents-heroimg {
      width: 100%;
      max-width: 290px;
      min-width: auto;
    }
    .hero_image.showsevents-heroimg {
      width: 100%;
      max-width: 290px;
      min-width: auto;
    }
    .heroimg_home2-element-1 {
      left: 57px;
      top: 140px;
      width: 15px;
    }
    .heroimg_home2-element-2 {
      left: 13px;
      top: 14px;
      width: 18px;
    }
    .heroimg_home2-element-3 {
      left: 26px;
      top: 31px;
      width: 104px;
    }
    .heroimg_home2-element-4 {
      left: 13px;
      top: 38px;
      width: 70px;
    }
    .heroimg_home2-element-5 {
      left: 76px;
      top: 78px;
      width: 85px;
    }
    .heroimg_home2-element-6 {
      left: 74px;
      top: 88px;
      width: 78px;
    }
    .heroimg_home2-element-7 {
      top: 18px;
      right: 9px;
    }
    .heroimg_home2-element-8 {
      top: 12px;
      right: 40px;
      width: 61px;
    }
    .heroimg_home2-element-9 {
      top: 38px;
      right: 8px;
      width: 80px;
    }
    .heroimg_home2-element-10 {
      left: 156px;
      top: 89px;
      width: 13px;
    }
    .container-6 {
      padding: 40px 15px 50px;
    }
    .section_hero {
      overflow: hidden;
      width: 100%;
    }
    .section_hero.blog {
      height: 100%;
    }
    .section_hero.aboutus {
      height: 100%;
    }
    .section_hero.buyslides {
      padding-top: 0px;
    }
    .section_hero.privacy {
      height: 220px;
    }
    .pk_container.ebooks {
      width: 100%;
    }
    .content_wrapper.box {
      padding-right: 20px;
      padding-left: 20px;
    }
    .content_wrapper.box.blog-form {
      height: auto;
    }
    .content_wrapper.box {
      padding-right: 20px;
      padding-left: 20px;
    }
    .content_wrapper.box.followus.request {
      padding-bottom: 35px;
    }
    .content_wrapper.ebooks {
      overflow: hidden;
      padding: 10px 15px;
    }
    .footer_col {
      width: auto;
    }
    .footer_social-wrapper.followus {
      margin-top: 0px;
    }
    .navbar_sticky {
      position: absolute;
    }
    .getstarted_text-col._1 {
      margin-right: 0px;
      margin-bottom: 10px;
    }
    .landing-title.workshop {
      width: 100%;
    }
    .content_col.faq_col {
      padding-right: 0px;
    }
    .content_col.right.request {
      margin-top: 20px;
    }
    .content_col.request {
      width: 100%;
      margin-bottom: 0px;
      text-align: center;
    }
    .content_col.imagearticle {
      width: 100%;
      margin-bottom: 5px;
    }
    .tab_link {
      margin-bottom: 10px;
    }
    .tabs_menu {
      margin-top: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .portfolio-description-2 {
      font-size: 17px;
      line-height: 26px;
    }
    .paragraph-2 {
      display: none;
    }
    .nav-link_wrapper {
      padding-left: 20px;
    }
    .nav-link_wrapper.dropdown.mobile {
      width: 100%;
    }
    .nav-bar_bg {
      z-index: 80;
    }
    .mobile-logo-wrapper {
      margin-left: 0%;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .nav-bar {
      width: 100%;
    }
    .navbar-mobile-bg {
      position: fixed;
      left: 0px;
      top: 0px;
      right: 0px;
      z-index: 1;
      display: none;
      height: 75px;
    }
    .logo {
      margin-left: 10px;
    }
    .logo.w--current {
      padding-left: 0px;
    }
    .logo-mobile {
      width: 80%;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }
    .mobile-navbar {
      position: absolute;
      top: 0px;
      z-index: 90;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 75px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
    .menu-wrapper.links.sidebar-links {
      padding-top: 15px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .structurenav-menu {
      display: none;
      height: auto;
    }
    .wrap._w-custom.structure-menu-wrap {
      z-index: 99999;
    }
    .wrap._w-custom.structure-menu-wrap {
      z-index: 99999;
      width: 90%;
    }
    .structure-cards2-menu-btn {
      width: 26px;
      height: 26px;
      margin-right: 15px;
      background-image: url('/images/landing/icon_nav.svg');
      background-position: 50% 50%;
      background-size: auto;
      background-repeat: no-repeat;
    }
    .structure-cards2-menu-btn.w--open {
      background-image: url('/images/landing/icon_nav-x.svg');
      opacity: 1;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      -webkit-transition: all 200ms ease;
      transition: all 200ms ease;
    }
    .nav-item.nav-logo {
      max-width: 240px;
      margin-right: 20px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .header {
      background-color: #110f35;
    }
    .buy-cta_content.pricing {
      margin-bottom: 20px;
    }
    .price_wrapper {
      text-align: center;
    }
    .author_block.workshop {
      margin-bottom: 30px;
    }
    .column.author {
      margin-right: 0px;
    }
    .button-4 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: auto;
      min-height: 55px;
      margin-top: 10px;
      padding: 16px 45px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      line-height: 22px;
      text-align: center;
    }
    .button-4.step {
      width: 100%;
      margin-bottom: 40px;
    }
    .title_wrapper-row-image {
      margin-bottom: 10px;
    }
    .tip_image {
      margin-bottom: 10px;
    }
    .orange-box_img-mobile {
      width: 130px;
      margin-top: 0px;
    }
    .address_block {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .address_block-icon {
      margin-bottom: 10px;
    }
    .box_header {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      color: #212173;
      text-align: center;
    }
    .box_header-text {
      text-align: center;
    }
    .box_content-wrapper {
      width: 100%;
    }
    .skill_label {
      margin-right: 0px;
    }
    .skill-row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .conf_slider_content {
      padding-right: 37px;
      padding-left: 37px;
    }
    .conf_slider_content.workshop {
      padding-right: 39px;
      padding-left: 39px;
    }
    .conf_slider_img {
      width: 100%;
      margin-bottom: 20px;
    }
    .slider-icon {
      width: 18px;
    }
    .slider-icon_bar {
      width: 50px;
    }
    .payment-logos_wrapper {
      display: -ms-grid;
      display: grid;
      margin-top: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      grid-auto-columns: 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
    .payment-logo {
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
    }
    .payment-logo.paypal {
      margin-top: 0px;
      margin-right: auto;
      margin-left: auto;
    }
    .pk-list_sign-img {
      margin-right: 0px;
      margin-bottom: 0px;
    }
    .hero_image-tab {
      margin-right: 0px;
    }
    .hero_image-tab.second {
      margin-top: 10px;
      margin-left: 0px;
    }
    .hero_image_tab-menu {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .box_wrapper-2 {
      width: auto;
      margin-bottom: 20px;
      padding-top: 20px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .box_wrapper-2.middle {
      margin-right: 0px;
      margin-left: 0px;
    }
    .signup-1-row {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .pk-steps-right-blue-2 {
      position: relative;
      width: auto;
      height: auto;
      margin-left: 0px;
    }
    .section-2 {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .section-2.footer {
      height: auto;
    }
    .box_wrapper-3 {
      width: auto;
      margin-bottom: 20px;
      padding-top: 20px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .box_wrapper-3.list {
      padding-right: 20px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .congrat_nextstepimage {
      width: auto;
      max-height: 200px;
      max-width: 100%;
    }
    .congrat_nextstep-wrapper {
      margin-bottom: 30px;
    }
    .article_highlighted-image {
      max-height: 500px;
    }
    .price-counterimg_wrapper {
      display: none;
    }
    .price-counterimg_wrapper.mobile {
      left: auto;
      top: -90px;
      display: block;
      width: 120px;
      margin-right: 20px;
    }
    .price_tag {
      position: absolute;
      left: 0%;
      top: 45px;
      right: 0%;
      z-index: 10;
      width: 100%;
      max-width: none;
      font-size: 22px;
    }
    .travel-guide_mobile {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 30px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
    }
    .hero_btn-row {
      margin-top: 10px;
    }
    .checkbox-grid {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto;
      grid-template-rows: auto auto auto;
    }
    .table-cell {
      width: 100%;
      height: 100%;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .table-cell.text {
      margin-bottom: 15px;
      padding-right: 0px;
      text-align: center;
    }
    .table-cell.mobile-hide {
      display: none;
    }
    .table-header-row {
      display: none;
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto;
    }
    .table-row_form {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto;
    }
    .table-row_form.text {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
    .table-row_form.cards-row {
      grid-row-gap: 16px;
      -ms-grid-columns: 2fr;
      grid-template-columns: 2fr;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
    }
    .mobile_header-title {
      display: block;
      margin-left: 15px;
    }
    .button-5 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: auto;
      min-height: 55px;
      margin-top: 10px;
      padding: 16px 45px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      line-height: 22px;
      text-align: center;
    }
    .button-5.navbar.mobile {
      margin-left: 0px;
    }
    .button-5.hero_secondary {
      margin-bottom: 0px;
    }
    .button-5.white {
      display: block;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
    .button-5.navbar-sf.mobile {
      margin-left: 0px;
    }
    .button-5.button-link.slidesfox {
      margin-top: 0px;
    }
    .pk-navbar-2 {
      z-index: 9999;
      height: 70px;
      padding-right: 0%;
    }
    .navbar_sticky-2 {
      position: absolute;
    }
    .wrapper_hero-text-2 {
      position: relative;
      z-index: 10;
      width: 100%;
      padding-right: 0px;
      padding-left: 0px;
      text-align: center;
    }
    .wrapper_hero-text-2.slidesfox {
      width: 95%;
    }
    .subtitle-2.hero-subtitle {
      margin-top: 20px;
    }
    .subtitle-2.hero-subtitle.landing {
      margin-top: 20px;
    }
    .subtitle-2.hero-subtitle.slidesfox {
      width: 100%;
    }
    .section_hero-2 {
      overflow: hidden;
      width: 100%;
    }
    .box_wrapper-4 {
      width: auto;
      margin-bottom: 20px;
      padding-top: 30px;
      padding-bottom: 30px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .box_wrapper-4.step {
      padding-right: 0px;
      padding-left: 0px;
    }
    .box_wrapper-4.step.col5 {
      margin-bottom: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .tab_link-2 {
      margin-bottom: 10px;
    }
    .orange-box_wrapper-2 {
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 20px;
      padding-left: 20px;
      border-radius: 0px;
      text-align: center;
    }
    .pk-s4-orange-icon1-2 {
      width: 100%;
      margin-top: 5px;
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 21px;
      padding-bottom: 20px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .pk-s4-orange-icon1-2.gutter {
      margin-right: 0px;
      margin-left: 0px;
    }
    .footer_col-2 {
      width: auto;
    }
    .pk-footer-social-2 {
      position: static;
    }
    .footer-link-2 {
      text-align: center;
    }
    .nav-item-sf.nav-logo {
      max-width: 240px;
      margin-right: 20px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .menu-btn-wrapper.mobile {
      margin-top: 16px;
    }
  }
  
  #w-node-c871c53934fa-23b401fc {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  
  #w-node-c871c53934f2-23b401fc {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  
  #w-node-c871c5393502-23b401fc {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  
  #w-node-c871c5393522-23b401fc {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }
  
  #w-node-c871c539352a-23b401fc {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }
  
  #w-node-c871c539351a-23b401fc {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  
  #w-node-c871c5393512-23b401fc {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  
  #w-node-c871c539350a-23b401fc {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  
  #w-node-2d6d4f0edce5-20b3ca17 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }
  
  #w-node-43e2b51331e8-20b3ca17 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-dfe0ed8a9309-20b3ca17 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-44d85e27b2d1-20b3ca17 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-8ce2f4bd81af-20b3ca17 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-b750ee105fa3-20b3ca17 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-dce0ad7dc622-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-dce0ad7dc629-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-31830b3500b7-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-31830b3500b8-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-fc4baff67e69-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-fc4baff67e71-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-fc4baff67e7b-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-fc4baff67e83-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-ea8a0c3783e4-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-ea8a0c3783ec-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-ea8a0c3783f6-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-ea8a0c3783fe-e8b3ca27 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  
  #w-node-c7989ab948a1-4db3d873 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  
  #w-node-c7989ab948a8-4db3d873 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  
  #w-node-c7989ab948af-4db3d873 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  
  #w-node-c7989ab948b6-4db3d873 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }
  
  #w-node-c7989ab948bd-4db3d873 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 4;
    grid-row-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
  }
  
  #w-node-c7989ab948c4-4db3d873 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  
  #w-node-c7989ab948cb-4db3d873 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
  }
  
  #w-node-c7989ab948d2-4db3d873 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  
  #w-node-7717986bb53d-4db3d873 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  
  #w-node-7717986bb544-4db3d873 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
  }
  
  #w-node-7717986bb54b-4db3d873 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  
  #w-node-7717986bb56e-4db3d873 {
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }
  
  #w-node-3529b50f80f1-c4b3ca23 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: auto;
    justify-self: auto;
  }
  
  @media screen and (max-width: 991px) {
    #w-node-ea8a0c3783e1-e8b3ca27 {
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
    }
  }
  
  @media screen and (max-width: 767px) {
    #w-node-2d6d4f0edce5-20b3ca17 {
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
    }
    #w-node-43e2b51331e8-20b3ca17 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }
    #w-node-334d1ec876ef-20b3ca17 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }
    #w-node-dfe0ed8a9309-20b3ca17 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }
    #w-node-44d85e27b2d1-20b3ca17 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }
    #w-node-8ce2f4bd81af-20b3ca17 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }
    #w-node-b750ee105fa3-20b3ca17 {
      -ms-grid-column: span 1;
      grid-column-start: span 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    }
    #w-node-dce0ad7dc61f-e8b3ca27 {
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
    }
    #w-node-fc4baff67e66-e8b3ca27 {
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
    }
  }
  
  @media screen and (max-width: 479px) {
    #w-node-c871c53934fa-23b401fc {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 2;
      grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3;
    }
    #w-node-c871c5393502-23b401fc {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 3;
      grid-row-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 4;
    }
    #w-node-c871c5393522-23b401fc {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 8;
      grid-row-start: 8;
      -ms-grid-row-span: 1;
      grid-row-end: 9;
    }
    #w-node-c871c539352a-23b401fc {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 7;
      grid-row-start: 7;
      -ms-grid-row-span: 1;
      grid-row-end: 8;
    }
    #w-node-c871c539351a-23b401fc {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 6;
      grid-row-start: 6;
      -ms-grid-row-span: 1;
      grid-row-end: 7;
    }
    #w-node-c871c5393512-23b401fc {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 5;
      grid-row-start: 5;
      -ms-grid-row-span: 1;
      grid-row-end: 6;
    }
    #w-node-c871c539350a-23b401fc {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 4;
      grid-row-start: 4;
      -ms-grid-row-span: 1;
      grid-row-end: 5;
    }
    #w-node-dce0ad7dc61f-e8b3ca27 {
      -ms-grid-row: span 1;
      grid-row-start: span 1;
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
      -ms-grid-column: span 2;
      grid-column-start: span 2;
      -ms-grid-column-span: 2;
      grid-column-end: span 2;
    }
    #w-node-c7989ab948a1-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 2;
      grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3;
    }
    #w-node-c7989ab948af-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 3;
      grid-row-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 4;
    }
    #w-node-c7989ab948b6-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 8;
      grid-row-start: 8;
      -ms-grid-row-span: 1;
      grid-row-end: 9;
    }
    #w-node-c7989ab948bd-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 7;
      grid-row-start: 7;
      -ms-grid-row-span: 1;
      grid-row-end: 8;
    }
    #w-node-c7989ab948c4-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 6;
      grid-row-start: 6;
      -ms-grid-row-span: 1;
      grid-row-end: 7;
    }
    #w-node-c7989ab948cb-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 5;
      grid-row-start: 5;
      -ms-grid-row-span: 1;
      grid-row-end: 6;
    }
    #w-node-c7989ab948d2-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 4;
      grid-row-start: 4;
      -ms-grid-row-span: 1;
      grid-row-end: 5;
    }
    #w-node-7717986bb53d-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 2;
      grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3;
    }
    #w-node-7717986bb54b-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 3;
      grid-row-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 4;
    }
    #w-node-7717986bb56e-4db3d873 {
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 4;
      grid-row-start: 4;
      -ms-grid-row-span: 1;
      grid-row-end: 5;
    }
  }
  