.logo-s1 {
    height: 2.5rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

hr {
    color: #EAEAEA;
    border: 1px solid;
    width: 100%;
}

.signup-form {
    min-width: 24rem;
    display: flex;
    flex-direction: column;
}

.signup-form>* {
    margin-bottom: 0.65rem;
}

.checkbox-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    justify-content: flex-start;
    align-items: center;
}

.checkbox-wrapper > :nth-child(2) {
    margin-left: 0.75rem;
}