.deckdetails-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    margin-top: 1.5rem;
}

.deckdetails-title-wrapper>* {
    margin-bottom: 1rem;
}

.deckdetails-navlink {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.deckdetails-navlink>img {
    margin-right: 0.75rem;
}

.deckdetails-title-contacts-wrapper {
    display: flex;
    flex-direction: row;
    margin-top: 0.25rem;
}

.deckdetails-title-contacts-wrapper> * {
    margin-right: 0.5rem;
}

.status-block {
    width: 250px;
    margin-right: 3rem;
}

.status-block>* {
    margin-bottom: 1rem;
}

.status-block-row {
    display: flex;
    flex-direction: row;
}

.status-block-row > h5 {
    margin-right: 0.5rem;
}

.deck-status-light-green {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #51D37E;
    border-radius: 50%;
    display: inline-block;
}

.deck-status-light-yellow {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #FAB14E;
    border-radius: 50%;
    display: inline-block;
}

.deck-status-light-red {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #FC4343;
    border-radius: 50%;
    display: inline-block;
}

.deck-status-light-grey {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #8B8B9C;;
    border-radius: 50%;
    display: inline-block;
}

.deck-status-box-green {
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #51D37E;
    height: 32px;
    width: 10.5rem;
    background: rgba(81, 211, 126, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.deck-status-box-yellow {
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FAB14E;
    width: 10rem;
    height: 32px;
    background: rgba(250, 177, 78, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.deck-status-box-red {
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #FC4343;
    width: 10rem;
    height: 32px;
    background: rgba(252, 67, 67, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.deck-status-box-grey {
    font-weight: 600;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #8B8B9C;
    width: 11rem;
    height: 32px;
    background: #F0F0F5;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.deck-thumbnail-block-wrapper {}

.deck-thumbnail-large {
    border: 1px solid #F0F0F5;
    box-sizing: border-box;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    max-height: 16rem;
    max-width: 26rem;
}

.change-status-block {
    max-width: 20rem;
    margin-top: 1.5rem;
}

.change-status-block>* {
    margin-bottom: 1rem;
}

.qr-block-wrapper {
    display: flex;
    flex-direction: column;
}

.qr-block-qr-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.qr-wrapper {
    border: 1px solid #F0F0F5;
    box-sizing: border-box;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding:1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.qr-block-instructions {
    max-width: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top:0.75rem;
    padding-left:2rem;
}

.qr-block-instructions > img {
    max-width: 7rem;
}

.qr-block-instructions > p {
    margin-top: 1rem;
    max-width: 12rem;
}

.qr-block-download-link {
    border-top: 1px solid #F0F0F5;
    margin-top: 2.5rem;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

.qr-download-link-wrapper {
    display: flex;
    flex-direction: row;
    margin-top:1rem;
}

.qr-download-link-wrapper > input {
width: 100%;
margin-right:1rem;
}

.empty-contacts-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 17rem;
}

.empty-contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
}

.empty-contacts {
    width: 100%;
    margin: auto;
}

.empty-contacts>* {
    margin-bottom: 1rem;
}

.empty-contacts>img {
    max-width: 8rem;
}

.bt-standard-ghost > img {
    margin-right: 0.55rem;
    position: relative;
    top: 0.15rem;
}

.csv-btn {
    width: 10rem;
}